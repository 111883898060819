import { PostRequest } from "../../components/backend/postRequest";
import { actionOnError, errorTranslation, getBackendLink } from "../../components/backend/backend";
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import React, { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { Badge, Button, Space, Table, Tag, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { ErrorRow } from "../../components/errorBlock";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { FormatShortUUID } from "../../components/formattingValues";

export const SecuritySessionsActive = (props) => {
    const closeSession = (sid) => {
        PostRequest(getBackendLink() + '/api/v1/sessions/self/close', {
            "sid": sid,
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        NotificationError('Error', errorTranslation(result.error))
                    } else {
                        let newData = sessionsActiveData
                        newData = newData.filter((item) => item.ID !== sid);
                        setSessionsActiveData(newData)

                        NotificationSuccess('Saved!', 'Session closed')
                    }
                },
                (error) => {
                    NotificationError('Error', error.message)
                }
            )
    }

    const [loadingSessionsActiveData, setLoadingSessionsActiveData] = useState(false);
    const [sessionsActiveData, setSessionsActiveData] = useState([]);
    const [sessionsActiveErr, setSessionsActiveErr] = useState("");
    const [sessionsActiveTableParams, setSessionsActiveTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleSessionsActiveTableChange = (pagination, filters, sorter) => {
        setSessionsActiveTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== sessionsActiveTableParams.pagination?.pageSize) {
            setSessionsActiveData([]);
        }
    };

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/sessions/self/gel_list/active?page=${sessionsActiveTableParams.pagination.current}&size=${sessionsActiveTableParams.pagination.pageSize}`,
            setLoadingSessionsActiveData,
            setSessionsActiveErr,
            null
        ).then(
            (result) => {
                setSessionsActiveData(result.Records)
                setSessionsActiveTableParams({
                    ...sessionsActiveTableParams,
                    pagination: {
                        ...sessionsActiveTableParams.pagination,
                        total: result.Total,
                    },
                });
            }
        )
        // eslint-disable-next-line
    }, [sessionsActiveTableParams.pagination.pageSize, sessionsActiveTableParams.pagination.current]);

    const columnsSessionsActive = [
        {
            title: 'ID',
            dataIndex: 'SessionID',
            key: 'SessionID',
            render: (_, record) => (
                <>

                    {
                        record.SessionID === props.token.SessionID
                            ?
                            <Badge.Ribbon text=
                                {
                                    record.SessionID === props.token.SessionID ? "Current"
                                        :
                                        <></>
                                }
                                color="lime">
                                {FormatShortUUID(record.SessionID)}
                            </Badge.Ribbon> : <>{FormatShortUUID(record.SessionID)}</>
                    }
                </>
            ),
        },
        {
            title: 'Address',
            dataIndex: 'IPAddr',
            key: 'IPAddr',
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'Location',
            render: (_, record) => (
                <>{record.Country !== "" && record.Country !== undefined ? record.Country : <>unknown</>}{record.Location !== "" && record.Location !== undefined ? <>, {record.Location}</> : <>, unknown</>}</>
            ),
        },
        {
            title: 'OS',
            dataIndex: 'OS',
            key: 'OS',
            render: (_, record) => (
                <>{`${record.OS} ${record.OSVersion}`}</>
            ),
        },
        {
            title: 'Browser',
            dataIndex: 'Browser',
            key: 'Browser',
            render: (_, record) => (
                <>{`${record.BrowserName} ${record.BrowserVersion}`}</>
            ),
        },
        {
            title: 'Started',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (_, record) => (
                <>{record.CreatedAt ? <TimeFromSeconds seconds={record.CreatedAt.seconds * 1000} /> : <></>}</>
            ),
        },
        {
            title: 'Expires',
            dataIndex: 'ExpiresAt',
            key: 'ExpiresAt',
            render: (_, record) => (
                <>{record.ExpiresAt ? <TimeFromSeconds seconds={record.ExpiresAt.seconds * 1000} /> : <></>}</>
            ),
        },
        {
            title: 'Not my computer',
            dataIndex: 'IsTempSession',
            key: 'IsTempSession',
            render: (_, record) => (
                <>{record.NotMyDevice === true ? <Tag color={'volcano'}>YES</Tag> : <></>}</>
            ),
        },
        {
            title: 'Sign Method',
            dataIndex: 'SignInMethod',
            key: 'SignInMethod',
            render: (_, record) => (
                <>{record.SignInMethod === "internal" ? <Tag color={'volcano'}>Login & password</Tag> :
                    <Tag color={'lime'}>Microsoft Auth</Tag>}</>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {record.IsEnded === true
                        ?
                        <></>
                        :
                        <Tooltip placement="top" title={"Close session"}>
                            <Button className="button-primary" type="primary" onClick={function () {
                                closeSession(record.SessionID)
                            }}><LogoutOutlined /></Button>
                        </Tooltip>
                    }
                </Space>
            ),
        },
    ];

    if (sessionsActiveErr !== "") {
        return <ErrorRow prefix={"Active sessions"} err={sessionsActiveErr} />
    }

    return (
        <>
            <Table
                columns={columnsSessionsActive}
                rowKey={(record) => record.ID}
                dataSource={sessionsActiveData}
                pagination={sessionsActiveTableParams.pagination}
                loading={loadingSessionsActiveData}
                size={"small"}
                onChange={handleSessionsActiveTableChange}
            />
        </>
    )
}