import React from "react";
import { Col, Row } from "antd";
import {
    BreadcrumbsDeveloperServicesBizzkitProductFeed,
} from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import swag from "./bizzkitProductFeedSwagger.json";

export const BizzkitProductFeedService = () => {
    return (
        <>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperServicesBizzkitProductFeed />
                    </Col>
                </Row>
            </div>


            <div className="container-1700">
                <SwaggerUI spec={swag} />
            </div>
        </>
    )
}
