import React from "react";
import { GetLinkProductAskas } from "../askas";
import { GetLinkProductRedis } from "../redis";
import { GetLinkProductMoreFlo } from "../moreflo";
import { GetLinkProductSlim4 } from "../slim4";

export function GetProductPageMenuItems(sku = '') {
    const items = [
        {
            label: <a href={GetLinkProductAskas(sku)} target={"_blank"} rel="noreferrer">Askås</a>,
            icon: <></>,
        },
        {
            label: <a href={GetLinkProductRedis(sku)} target={"_blank"} rel="noreferrer">Cached</a>,
            icon: <></>,
        },
        {
            label: <a href={GetLinkProductMoreFlo(sku)} target={"_blank"} rel="noreferrer">MoreFlo</a>,
            icon: <></>,
        },
        {
            label: <a href={GetLinkProductSlim4(sku)} target={"_blank"} rel="noreferrer">Slim4</a>,
            icon: <></>,
        },
    ];

    return (
        items
    )
}