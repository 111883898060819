
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";

export function ParseUserToken() {
    const cookies = new Cookies();
    let userToken = cookies.get("userToken")
    let token = {}

    if (userToken !== "" && userToken !== undefined) {
        try {
            token = jwt_decode(userToken)
        } catch (error) {
            console.error(error);
            cookies.remove('userToken')
            return undefined
        }
    } else {
        return undefined
    }

    return token
}

export function UnparsedUserToken() {
    const cookies = new Cookies();
    let userToken = cookies.get("userToken")

    if (userToken !== "" && userToken !== undefined) {
        return userToken
    }

    return undefined
}