import { Col, Row } from "antd";
import {
    BreadcrumbsProductsPrices
} from "../../../components/breadcrumbs/breadcrumbsProducts";
import React from "react";
import { Helmet } from "react-helmet";

export const ProductsPrices = () => {
    return (
        <>
            <Helmet>
                <title>Prices || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsPrices />
                    </Col>
                </Row>
            </div>
        </>
    )
}