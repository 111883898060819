import { Card, Col, Descriptions, Row, Tag } from "antd";
import {
    BreadcrumbsDeveloperEndpointsEndpoint
} from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import { ErrorRow } from "../../../../components/errorBlock";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { GetRequest } from "../../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../../components/backend/backend";
import { NotificationError } from "../../../../components/notifications";
import { useEffect } from "react";
import { Spinner, SpinnerInline } from "../../../../components/spinners";
import { GetTagFromValue } from "../../../../components/formattingValues";
import { GetAppLink } from "../../../../components/getPageLinks";
import { Helmet } from "react-helmet";


export const DeveloperEndpointsEndpoint = () => {
    const { id } = useParams();

    const [err, setErr] = useState("");
    const [data, setData] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [crumbsName, setCrumbsName] = useState(<SpinnerInline />);

    const fetchData = () => {
        setLoadingData(true);
        GetRequest(getBackendLink() + `/api/v1/dev/endpoints/get/json?id=${id}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)

                        setCrumbsName("Unknown endpoint")
                    } else {
                        setErr("")
                        setData(result.result)

                        setCrumbsName(<Tag>{result.result.app_name} {result.result.address}</Tag>)
                    }
                    setLoadingData(false);
                },
                (error) => {
                    setLoadingData(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>{`Endpoint #${id}`} || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperEndpointsEndpoint id={crumbsName} />
                    </Col>
                </Row>
                <ErrorRow err={err} />
                {loadingData
                    ?
                    <Row>
                        <Col xs={24} xl={24}>
                            <Spinner />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col xs={24} xl={24}>
                            <Card
                                bordered={false}
                                style={{ backgroundColor: "transparent", borderRadius: 0, borderColor: "transparent" }}
                            >
                                <Descriptions>
                                    <Descriptions.Item
                                        label="ID">{data.id}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="App"><a
                                            href={GetAppLink(data.app_id)}>{data.app_name}</a></Descriptions.Item>
                                    <Descriptions.Item
                                        label="Address">{data.method === "GET" && data.app_id === 5 ?
                                            <a target={"_blank"} rel={"noreferrer"}
                                                href={getBackendLink() + data.address}>{data.address}</a> : <>{data.address}</>}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Request format">{GetTagFromValue(data.request_format)}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Response format">{GetTagFromValue(data.response_format)}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Method">{GetTagFromValue(data.method)}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Protocol">{data.proto}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Is active">{GetTagFromValue(data.is_active)}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Requires auth">{GetTagFromValue(data.requires_auth)}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Requires session">{GetTagFromValue(data.requires_session)}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Requires token">{GetTagFromValue(data.requires_token)}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Comment">{data.comment}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        </>
    );
}