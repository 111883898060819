import { Tag } from "antd";
import React from "react";
import { Typography } from "antd";

const { Paragraph } = Typography

export function FormatShortUUID(uuid = '') {
    const firstFour = uuid.slice(0, 4);
    const lastFour = uuid.slice(-4);
    return `${firstFour}...${lastFour}`;
}

export function GetTagFromValue(val = '') {
    if (val === true) {
        return <Tag color={'lime'}>true</Tag>
    }
    if (val === "true") {
        return <Tag color={'lime'}>{val}</Tag>
    }
    if (val === "OK") {
        return <Tag color={'lime'}>{val}</Tag>
    }
    if (val === false) {
        return <Tag color={'volcano'}>false</Tag>
    }
    if (val === "false") {
        return <Tag color={'volcano'}>{val}</Tag>
    }
    if (val === "info") {
        return <Tag color={'cyan'}>Info</Tag>
    }
    if (val === "warning") {
        return <Tag color={'orange'}>Warning</Tag>
    }
    if (val === "error") {
        return <Tag color={'volcano'}>Error</Tag>
    }
    if (val === "WARNING") {
        return <Tag color={'orange'}>{val}</Tag>
    }
    if (val === "ERROR") {
        return <Tag color={'volcano'}>{val}</Tag>
    }
    if (val === "DANGER") {
        return <Tag color={'red'}>{val}</Tag>
    }
    if (val === "GET") {
        return <Tag color={'lime'}>{val}</Tag>
    }
    if (val === "POST") {
        return <Tag color={'blue'}>{val}</Tag>
    }
    if (val === "PUT") {
        return <Tag color={'cyan'}>{val}</Tag>
    }
    if (val === "JSON" || val === "json") {
        return <Tag color={'cyan'}>{val}</Tag>
    }
    if (val === "CSV" || val === "csv") {
        return <Tag color={'lime'}>{val}</Tag>
    }

    return val
}

export function GetTagFromBooleanBasic(value = false) {
    return GetTagFromBoolean(value, 'lime', 'volcano', 'true', 'false')
}

export function GetTagFromBooleanYesNo(value = false) {
    return GetTagFromBoolean(value, 'lime', 'volcano', 'YES', 'NO')
}

export function GetTagFromBoolean(value = false, trueColor = 'lime', falseColor = 'volcano', trueText = 'true', falseText = 'false') {
    if (value === true) {
        return <Tag color={trueColor}>{trueText}</Tag>
    }

    return <Tag color={falseColor}>{falseText}</Tag>
}

export function ConvertMonthNumberToName(number) {
    if (number === 1 || number === "1" || number === "01") {
        return "January"
    }
    if (number === 2 || number === "2" || number === "02") {
        return "February"
    }
    if (number === 3 || number === "3" || number === "03") {
        return "March"
    }
    if (number === 4 || number === "4" || number === "05") {
        return "April"
    }
    if (number === 5 || number === "5" || number === "05") {
        return "May"
    }
    if (number === 6 || number === "6" || number === "08") {
        return "June"
    }
    if (number === 7 || number === "7" || number === "07") {
        return "July"
    }
    if (number === 8 || number === "8" || number === "08") {
        return "August"
    }
    if (number === 9 || number === "9" || number === "09") {
        return "September"
    }
    if (number === 10 || number === "10") {
        return "October"
    }
    if (number === 11 || number === "11") {
        return "November"
    }
    if (number === 12 || number === "12") {
        return "December"
    }

    return number
}

export function GetPriceCurrencyFromMarketCopyable(value = "0", market = "") {
    let currency = ""

    if (market === "SE") {
        currency = "SEK"
    }
    if (market === "NO") {
        currency = "NOK"
    }
    if (market === "FI") {
        currency = "€"
    }
    if (market === "DK") {
        currency = "DKK"
    }
    if (market === "DE") {
        currency = "€"
    }
    if (market === "NL") {
        currency = "€"
    }
    if (market === "PL") {
        currency = "zł"
    }
    if (market === "BE") {
        currency = "€"
    }

    return <Paragraph copyable={{ text: value }}>{value} {currency}</Paragraph>
}

export function ZeroIfEmpty(value) {
    if (value === "" || value === null || value === undefined) {
        return 0
    }

    return value
}

export function RoundDecimal(value, decimal = 2) {
    if (value === "" || value === null || value === undefined) {
        return 0
    }

    return parseFloat(value).toFixed(decimal);
}