import { PostRequestInternalAPI } from "../../../components/backend/postRequest";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Descriptions, Form, Row, Timeline, Tooltip, Typography } from "antd";
import { BreadcrumbsEventsEvent } from "../../../components/breadcrumbs/breadcrumbsStat";
import { useParams } from "react-router-dom";
import {
    GetApplicationRunHref,
    GetApplicationRunLink, GetEventHref,
    GetUserLinkElement,
    GetUserLinkElementAvatar
} from "../../../components/getPageLinks";
import { ErrorRow } from "../../../components/errorBlock";
import { IsCrititcal, IsDanger, IsError, IsWarning } from "../../../components/checkingValues";
import { EventLevelsMap, EventSourcesMap } from "../../../components/getEventsData";
import { TimeFromSeconds } from "../../../components/dateAndTime";
import { Input } from 'antd';
import { Helmet } from "react-helmet";
import {
    GetEventCategories,
    GetEventSubCategories,
    TranslateCategory,
    TranslateSubCategory
} from "../../../components/getEventCategories";
import { FormatShortUUID } from "../../../components/formattingValues";

const { TextArea } = Input;

export const Event = (props) => {
    const { id } = useParams();
    const [err, setErr] = useState("");
    const [data, setData] = useState({ CreatedAt: {} });
    const [trace, setTrace] = useState([]);
    const [payload, setPayload] = useState({});
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");

    const [form] = Form.useForm();
    const commentValue = Form.useWatch('comment', form);

    const getEvent = (id) => {
        PostRequestInternalAPI(
            `/api/v1/notifications/events/get`, { event_id: id },
            setLoading,
            setErr,
            null,
        ).then(
            (result = { data: {} }) => {
                if (result.data !== null) {
                    setData(result.data)
                    if (result.data.EventPayload !== undefined && result.data.EventPayload !== null && result.data.EventPayload !== "" && result.data.EventPayload !== "{}") {
                        setPayload(JSON.parse(result.data.EventPayload))
                    }
                    if (result.data.EventPayload !== undefined && result.data.EventPayload !== null && result.data.EventPayload !== "" && result.data.EventPayload !== "{}") {
                        setTrace(JSON.parse(result.data.EventPayload).trace)
                    }
                }
            }
        )
    }

    useEffect(() => {
        getEvent(id)
        // eslint-disable-next-line
    }, []);

    const resolveEvent = (id) => {
        PostRequestInternalAPI(
            '/api/v1/notifications/events/resolve',
            {
                event_id: id,
                comment: commentValue,
                category: category,
                sub_category: subCategory,
            },
            null,
            null,
            null,
            props.messageAPI,
            {
                message: "Saving",
            }
        ).then(
            (result = {}) => {
                let newData = data
                newData.IsResolved = true
                newData.EventCategory = category
                newData.EventSubcategory = subCategory
                newData.ResolvedComment = commentValue
                newData.ResolvedAt = { seconds: Math.floor(Date.now() / 1000) }
                newData.ResolvedByID = props.token.ID
                newData.ResolvedByAvatar = props.token.AvatarLink
                newData.ResolvedByName = props.token.Name
                setData(newData)

                props.recountEvents()
            }
        )
    }

    if (err !== "") {
        return (
            <>
                <Helmet>
                    <title>{`Event #${id}`} || Bangerhead Admin</title>
                </Helmet>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <BreadcrumbsEventsEvent loading={loading} id={id}
                                time={<TimeFromSeconds seconds={data.CreatedAt.seconds * 1000} />} />
                        </Col>
                    </Row>
                </div>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <ErrorRow err={err} prefix={"Event"} />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>{`Event #${id}`} || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsEventsEvent loading={loading} id={id}
                            time={<TimeFromSeconds seconds={data.CreatedAt.seconds * 1000} />} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Typography.Title
                    level={3} style={{ margin: 0 }}>
                    {data.Head}
                </Typography.Title>
                {EventSourcesMap[data.Source] ? EventSourcesMap[data.Source].tag : data.Source}
                {EventLevelsMap[data.Priority] ? EventLevelsMap[data.Priority].tag : data.Priority}
                {TranslateCategory(data.EventCategory)}
                {TranslateSubCategory(data.EventSubcategory)}
            </div>
            {
                !data.IsResolved
                    ?
                    <>
                        <div className="container-1700">
                            <Row>
                                <Col xs={24} xl={24}>
                                    <Tooltip placement="top" title={"Resolve event"}>
                                        <Button disabled={loading} className="button-primary success success-button"
                                            type="primary"
                                            onClick={function () {
                                                resolveEvent(data.EventID)
                                            }}>Resolve event</Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </div>

                        <div className="container-1700">
                            <Row>
                                <Col xs={24} xl={24}>
                                    <Form disabled={loading} form={form} layout="vertical" autoComplete="off">
                                        <Form.Item name="comment">
                                            <TextArea rows={4} />
                                        </Form.Item>
                                        <Form.Item
                                            name={`Category`}
                                            label={`Category`}
                                        >
                                            <GetEventCategories setCategory={setCategory} />
                                        </Form.Item>
                                        <Form.Item
                                            name={`Sub category`}
                                            label={`Sub category`}
                                        >
                                            <GetEventSubCategories setSubCategory={setSubCategory} />
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <>
                        <div className="container-1700">
                            <Row>
                                <Col xs={24} xl={24}>
                                    <Alert type="success" showIcon description={data.ResolvedComment}
                                        message={<strong>Event resolved</strong>}
                                        style={{ borderRadius: 0, margin: "1em 0 1em 0" }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </>
            }
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Descriptions loading={loading}>
                            <Descriptions.Item label="Event ID">
                                <Typography.Text
                                    copyable={{ text: GetEventHref(data.EventID) }}>
                                    {FormatShortUUID(data.EventID)}
                                </Typography.Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Created">
                                <GetUserLinkElementAvatar id={data.CreatedBy} avatar={data.CreatedByAvatar} />
                                <GetUserLinkElement id={data.CreatedBy} name={data.CreatedByName} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Created at">
                                <TimeFromSeconds seconds={data.CreatedAt.seconds * 1000} />
                            </Descriptions.Item>

                            <Descriptions.Item label="Run ID">
                                <Typography.Text
                                    copyable={{ text: GetApplicationRunHref(data.RunID) }}>
                                    {GetApplicationRunLink(data.RunID)}
                                </Typography.Text>
                            </Descriptions.Item>
                        </Descriptions>
                        {
                            data.IsResolved
                                ?
                                <>
                                    <Descriptions loading={loading}>
                                        <Descriptions.Item label="">

                                        </Descriptions.Item>
                                        <Descriptions.Item label="Resolved">
                                            <GetUserLinkElementAvatar id={data.ResolvedByID}
                                                avatar={data.ResolvedByAvatar} />
                                            <GetUserLinkElement id={data.ResolvedByID} name={data.ResolvedByName} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Resolved at">
                                            <TimeFromSeconds seconds={data.ResolvedAt.seconds * 1000} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                </>
                                :
                                <></>
                        }

                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <GetAlertWithContent priority={data.Priority} content={data.Content} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <PrintPayload data={payload} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <TraceList trace={trace} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

function GetAlertWithContent(props) {
    if (IsWarning(props.priority)) {
        return <Alert message={props.content} type="warning" />
    }

    if (IsError(props.priority)) {
        let stack = {}

        let errored = false

        try {
            stack = JSON.parse(props.content);
        } catch (err) {
            errored = true
            console.log(err)
        }

        if (!errored) {
            let timeline = []
            stack.Stack.forEach((routine) => {
                routine.Stack.forEach((call) => {
                    timeline.push({
                        children: [call.File, ":", call.Line],
                        label: call.Func,
                        color: 'red',
                    })
                }
                )
            }
            )

            return (
                <>
                    <Alert type="error" showIcon description={stack.InitialError} message="Message"
                        style={{ backgroundColor: "#ff00001a", borderRadius: 0, margin: "1em 0 1em 0" }}
                    />
                    <Timeline
                        mode="left"
                        items={timeline}
                    />
                </>
            )
        } else {
            return (
                <>
                    <Alert type="error" showIcon description={props.content} message="Error"
                        style={{ backgroundColor: "#ff00001a", borderRadius: 0, margin: "1em 0 1em 0" }}
                    />
                </>
            )
        }
    }

    if (IsDanger(props.priority)) {
        return <Alert message={props.content} type="error" />
    }

    if (IsCrititcal(props.priority)) {
        return <Alert message={props.content} type="error" />
    }

    return props.content
}

const PrintPayload = ({ data }) => {
    return (
        <Descriptions>
            {Object.keys(data).map((key) => (
                key !== 'trace' && (
                    <Descriptions.Item key={key} label={key}>
                        {JSON.stringify(data[key])}
                    </Descriptions.Item>
                )
            ))}
        </Descriptions>
    );
}

const TraceList = ({ trace }) => {
    let timeline = []

    trace.forEach((routine) => {
        timeline.push({
            children: [routine],
            label: getLastElementInPath(routine) + " ==>",
            color: 'red',
        })
    }
    )

    return (
        <Timeline
            mode="left"
            items={timeline}
        />
    );
};

const getLastElementInPath = (path) => {
    const parts = path.split('/');
    return parts[parts.length - 1];
};