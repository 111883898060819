import { Col, Row } from 'antd';
import { BreadcrumbsMain } from "../components/breadcrumbs/breadcrumbsMain";
import { Helmet } from "react-helmet";

export function MainPage() {
    return (
        <>
            <Helmet>
                <title>Home || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsMain />
                    </Col>
                </Row>
            </div>
        </>
    )
}