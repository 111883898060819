import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Space, Button, Form, Input } from 'antd';
import { ErrorRow } from "../../../components/errorBlock";
import { EyeOutlined } from "@ant-design/icons";
import { GetLinkProductAskas } from "../../../components/askas";
import {
    BreadcrumbsProductsList
} from "../../../components/breadcrumbs/breadcrumbsProducts";
import { PriceStringified } from "../../../components/product";
import { PostRequestInternalAPI } from "../../../components/backend/postRequest";
import { Helmet } from "react-helmet";

const columns = [
    {
        title: 'IMG',
        dataIndex: 'image',
        key: 'image',
        render: (_, record) => (
            record.Product.Image !== undefined ? <img src={`${record.Product.Image}`} style={{ maxHeight: "50px" }} alt={""} /> : <></>
        ),
    },
    {
        title: 'SKU',
        dataIndex: 'ItemSKU',
        key: 'ItemSKU',
        render: (_, record) => (
            record.Product.ItemSKU
        ),
    },
    {
        title: 'EAN',
        dataIndex: 'EAN',
        key: 'EAN',
        render: (_, record) => (
            record.Codes.EAN
        ),
    },
    {
        title: 'Title (SE)',
        dataIndex: 'TitleSE',
        key: 'TitleSE',
        render: (_, record) => (
            record.Text !== undefined && record.Text.Titles[0] !== undefined ? record.Text.Titles[0].Title : <></>
        ),
    },
    {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
        render: (_, record) => (
            PriceStringified(record.Price.Price, true)
        ),
    },
    {
        title: 'Parent',
        dataIndex: 'Parent',
        key: 'Parent',
        render: (_, record) => (
            record.Product.Parent !== undefined ? <a href={`/products/${record.Product.Parent}`}>{record.Product.Parent}</a> : <></>
        ),
    },
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <Space wrap>
                <Button type="primary" href={`/products/${record.Product.ItemSKU}`} target={"_blank"}
                    rel={"noreferrer"}>
                    <EyeOutlined />
                </Button>
                <Button type="primary" href={`/products/${record.Product.ItemSKU}/cached`} target={"_blank"}
                    rel={"noreferrer"}
                    style={{
                        backgroundColor: "#d31212",
                    }}
                >
                    Cached
                </Button>
                <Button type="primary" href={`/products/${record.Product.ItemSKU}/more_flo`} target={"_blank"}
                    rel={"noreferrer"}
                    style={{
                        backgroundColor: "#09850b",
                    }}
                >
                    MoreFlo
                </Button>
            </Space>
        ),
    },
];

export const ProductListV2 = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });

    // eslint-disable-next-line
    useEffect(() => {
        PostRequestInternalAPI(`/api/v1/products/list`,
            {
                page: tableParams.pagination.current,
                size: tableParams.pagination.pageSize,
            },
            setLoading,
            setErr,
            null,
        )
            .then(
                (result) => {
                    setData(result.Records);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: result.Total,
                        },
                    });
                }
            )
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);


    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const [form] = Form.useForm();

    const nameValue = Form.useWatch('name', form);

    return (
        <>
            <Helmet>
                <title>Product list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsList />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <ErrorRow err={err} prefix={"Products"} />
                <Row>
                    <Col xs={24} xl={24}>
                        <Form form={form} layout="vertical" autoComplete="off">
                            <Space wrap>
                                <Form.Item name="name" label="Enter Item SKU and press 'Open'">
                                    <Input />
                                </Form.Item>
                                <Button type="primary" href={`/products/${nameValue}`} target={"_blank"}
                                    rel={"noreferrer"}
                                    disabled={nameValue === undefined || nameValue.length < 1}
                                >
                                    Product page
                                </Button>
                                <Button type="primary" href={GetLinkProductAskas(nameValue)} target={"_blank"}
                                    rel={"noreferrer"}
                                    disabled={nameValue === undefined || nameValue.length < 1}
                                >
                                    Askås
                                </Button>
                                <Button type="primary" href={`/products/${nameValue}/cached`} target={"_blank"}
                                    rel={"noreferrer"}
                                    style={{
                                        backgroundColor: "#d31212",
                                    }}
                                    disabled={nameValue === undefined || nameValue.length < 1}
                                >
                                    Cached
                                </Button>
                                <Button type="primary" href={`/products/${nameValue}/slim4`} target={"_blank"}
                                    rel={"noreferrer"}
                                    disabled={nameValue === undefined || nameValue.length < 1}
                                >
                                    Slim4
                                </Button>
                                <Button type="primary" href={`/products/${nameValue}/more_flo`} target={"_blank"}
                                    rel={"noreferrer"}
                                    style={{
                                        backgroundColor: "#09850b",
                                    }}
                                    disabled={nameValue === undefined || nameValue.length < 1}
                                >
                                    MoreFlo
                                </Button>
                            </Space>
                        </Form>
                    </Col>
                </Row>

                <Row>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.product}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
