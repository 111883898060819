import { Button, Col, Form, Input, Row, Space, Tag } from "antd";
import {
    BreadcrumbsProductsPricesDynamic
} from "../../../components/breadcrumbs/breadcrumbsProducts";
import React from "react";
import { ProductsPricesDynamicTable } from "../../../subpages/products/pricesDynamic";
import { MonitorOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { CountriesSelect } from "../../../subpages/products/countries";

export const ProductsPricesDynamic = (props) => {
    const [market, setMarket] = useState("");
    const [total, setTotal] = useState(0);
    const [skus, setSKUs] = useState("");
    const [refreshData, setRefreshData] = useState(0);

    return (
        <>
            <Helmet>
                <title>Dynamic prices || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsPricesDynamic />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        Total: <Tag color={"purple"}>{total}</Tag>
                        <Button type="primary" target={"_blank"}
                            rel={"noreferrer"}
                            onClick={() => {
                                setRefreshData(refreshData + 1)
                            }}
                        >
                            Get prices <MonitorOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Form.Item
                            name={`Market`}
                            label={`Market`}
                        >
                            <CountriesSelect
                                onChange={setMarket}
                                idField={"LandISO"}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Form.Item name="skus"
                                label="SKUs (comma-separated)">
                                <Input onChange={(e) => {
                                    setSKUs(e.target.value)
                                }} />
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <ProductsPricesDynamicTable
                            setTotal={setTotal}
                            skus={skus}
                            market={market}
                            refreshData={refreshData}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}