import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { GetRequest } from "../../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../../components/backend/backend";
import { NotificationError } from "../../../../components/notifications";
import { Alert, Button, Col, DatePicker, Form, Row, Select, Table, Tabs, Tag } from "antd";
import { ErrorRow } from "../../../../components/errorBlock";
import { MonitorOutlined } from "@ant-design/icons";
import duration from "format-duration";
import {
    BreadcrumbsDeveloperProductsFeedsF2Logs
} from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import Moment from "react-moment";
import { Helmet } from "react-helmet";

const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

export const DeveloperProductFeedsV2Logs = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [feeds, setFeeds] = useState([]);
    const [feedsDistinct, setFeedsDistinct] = useState([{ Feed: "all", ID: "all" }]);
    const [feed, setFeed] = useState("all");
    const [market, setMarket] = useState("all");
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const getDefaultBefore = () => {
        const beforeParsed = new Date(Date.now());
        beforeParsed.setUTCMonth(beforeParsed.getUTCMonth() + 1)
        const beforeYear = beforeParsed.toLocaleString('default', { year: 'numeric' });
        const beforeMonth = beforeParsed.toLocaleString('default', { month: '2-digit', });
        const beforeDay = beforeParsed.toLocaleString('default', { day: '2-digit' });
        const beforeText = [beforeYear, beforeMonth, beforeDay].join('-');

        return beforeText
    }

    const getDefaultAfter = () => {
        const afterParsed = new Date(Date.now() - (86400000));
        afterParsed.setUTCMonth(afterParsed.getUTCMonth() - 1)
        const afterYear = afterParsed.toLocaleString('default', { year: 'numeric' });
        const afterMonth = afterParsed.toLocaleString('default', { month: '2-digit', });
        const afterDay = afterParsed.toLocaleString('default', { day: '2-digit' });
        const afterText = [afterYear, afterMonth, afterDay].join('-');

        return afterText
    }

    const [before, setBefore] = useState(getDefaultBefore());
    const [after, setAfter] = useState(getDefaultAfter());
    const setRange = (value) => {
        setAfter(dayjs(value[0], dateFormat))
        setBefore(dayjs(value[1], dateFormat))
    }

    const fetchData = () => {
        const beforeParsed = new Date(before);
        const beforeYear = beforeParsed.toLocaleString('default', { year: 'numeric' });
        const beforeMonth = beforeParsed.toLocaleString('default', { month: '2-digit', });
        const beforeDay = beforeParsed.toLocaleString('default', { day: '2-digit' });
        const beforeText = [beforeYear, beforeMonth, beforeDay].join('-');

        const afterParsed = new Date(after);
        const afterYear = afterParsed.toLocaleString('default', { year: 'numeric' });
        const afterMonth = afterParsed.toLocaleString('default', { month: '2-digit', });
        const afterDay = afterParsed.toLocaleString('default', { day: '2-digit' });
        const afterText = [afterYear, afterMonth, afterDay].join('-');


        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/feeds/v2/logs?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&before=${beforeText}&after=${afterText}&feed=${feed}&market=${market}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setFeeds(result.result.Feeds)

                        // Filter feeds to be distinct.
                        let distinctFeeds = []
                        distinctFeeds.unshift({ Feed: "all", ID: "all" })
                        result.result.Feeds.forEach((feed) => {
                            let yes = false
                            distinctFeeds.forEach((distinct) => {
                                if (distinct.Feed === feed.Feed) {
                                    yes = true
                                }
                            })
                            if (!yes) {
                                distinctFeeds.push(feed)
                            }
                        }
                        );
                        setFeedsDistinct(distinctFeeds);


                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    return (
        <>
            <Helmet>
                <title>Feeds V2 logs || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperProductsFeedsF2Logs />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <ErrorRow err={err} prefix={"Logs"} />
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Form.Item label="Dates" name="Dates"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Before and after',
                                },
                            ]}>
                            <RangePicker
                                onChange={(value) => {
                                    setRange(value)
                                }}
                                defaultValue={[dayjs(after, dateFormat), dayjs(before, dateFormat)]}
                                format={dateFormat}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Form.Item
                            name={`Feed`}
                            label={`Feed`}
                        >
                            <Select
                                loading={loading}
                                onChange={(value) => {
                                    setFeed(value)
                                }}
                                options={feedsDistinct}
                                fieldNames={{ label: "Feed", value: "Feed" }}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Form.Item
                            name={`Market`}
                            label={`Market`}
                        >
                            <Select
                                loading={loading}
                                onChange={(value) => {
                                    setMarket(value)
                                }}
                                options={markets}
                                fieldNames={{ label: "Name", value: "ID" }}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Button type="primary" target={"_blank"}
                            rel={"noreferrer"}
                            onClick={fetchData}
                        >
                            Get logs <MonitorOutlined />
                        </Button>
                    </Col>
                </Row>

                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Tabs
                            defaultActiveKey="Logs"
                            items={[
                                {
                                    label: 'Logs',
                                    key: 'Logs',
                                    children: <Table
                                        columns={columns}
                                        rowKey={(record) => record.id}
                                        dataSource={data}
                                        pagination={tableParams.pagination}
                                        loading={loading}
                                        size={"small"}
                                        onChange={handleTableChange}
                                    />
                                },
                                {
                                    label: 'Stats',
                                    key: 'Stats',
                                    children: <>

                                        <Row style={{ marginTop: "1em", marginBottom: 16, }}>
                                            <Col xs={24} xl={24}>
                                                <Alert type="warning" showIcon message="Important" description={
                                                    <>
                                                        <p>
                                                            Stats here are shown for dates in datepicker.
                                                        </p>
                                                    </>
                                                } />
                                            </Col>
                                        </Row>
                                        <Table
                                            columns={columnsStats}
                                            rowKey={(record) => record.id}
                                            dataSource={feeds}
                                            pagination={{
                                                current: 1,
                                                pageSize: 100,
                                                position: ["topCenter", "bottomCenter"],
                                            }}
                                            loading={loading}
                                            size={"small"}
                                        />
                                    </>
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );

}


const columns = [
    {
        title: 'Feed',
        dataIndex: 'Feed',
        key: 'Feed',
    },
    {
        title: 'Market',
        dataIndex: 'Market',
        key: 'Market',
    },
    {
        title: 'Format',
        dataIndex: 'Format',
        key: 'Format',
        render: (_, record) => (
            record.Format === "CSV" ? <Tag color={'lime'}>{record.Format}</Tag> :
                <Tag color={'cyan'}>{record.Format}</Tag>
        ),
    },
    {
        title: 'Time',
        dataIndex: 'TimeSeconds',
        key: 'TimeSeconds',
        render: (_, record) => (
            duration(record.TimeSeconds * 1000)
        ),
    },
    {
        title: 'Products',
        dataIndex: 'ProductsNumber',
        key: 'ProductsNumber',
    },
    {
        title: 'Resolved',
        dataIndex: 'TimeResolved',
        key: 'TimeResolved',
        render: (_, record) => (
            <><Moment format="YYYY.MM.DD HH:mm:ss" date={record.TimeResolved} /></>
        ),
    }
];

const columnsStats = [
    {
        title: 'Feed',
        dataIndex: 'Feed',
        key: 'Feed',
    },
    {
        title: 'Market',
        dataIndex: 'Market',
        key: 'Market',
    },
    {
        title: 'Format',
        dataIndex: 'Format',
        key: 'Format',
        render: (_, record) => (
            record.Format === "CSV" ? <Tag color={'lime'}>{record.Format}</Tag> :
                <Tag color={'cyan'}>{record.Format}</Tag>
        ),
    },
    {
        title: 'AVG time',
        dataIndex: 'Avgtime',
        key: 'Avgtime',
        render: (_, record) => (
            duration(record.Avgtime * 1000)
        ),
    },
    {
        title: 'AVG prods',
        dataIndex: 'Avgproducts',
        key: 'Avgproducts',
    },
    {
        title: 'MAX time',
        dataIndex: 'Maxtime',
        key: 'Maxtime',
        render: (_, record) => (
            duration(record.Maxtime * 1000)
        ),
    },
    {
        title: 'MAX prods',
        dataIndex: 'Maxproducts',
        key: 'Maxproducts',
    },
]


const markets = [
    { Name: "all", ID: "all" },
    { Name: "SE", ID: "SE" },
    { Name: "NO", ID: "NO" },
    { Name: "FI", ID: "FI" },
    { Name: "DK", ID: "DK" },
    { Name: "NL", ID: "NL" },
    { Name: "PL", ID: "PL" },
    { Name: "BE (nl)", ID: "BE" },
]