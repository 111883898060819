import React from 'react';
import { Button, Col, Form, Popconfirm, Space, Table, Tag, Row } from "antd";
import { GetRequest } from "../../../../components/backend/getRequest";
import { actionOnError, errorTranslation, getBackendLink } from "../../../../components/backend/backend";
import { NotificationError, NotificationSuccess } from "../../../../components/notifications";
import { useEffect, useState } from 'react';
import { GetAppLink, GetUserLink } from "../../../../components/getPageLinks";
import { ErrorBlock } from "../../../../components/errorBlock";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { PostRequest } from "../../../../components/backend/postRequest";
import { Input, Modal } from 'antd';
import { Select } from 'antd';
import { IsDeveloper } from "../../../../components/backend/backendRoles";
import {
    BreadcrumbsDeveloperIntegrationsAPIKeys,
} from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import { TimeFromSeconds } from "../../../../components/dateAndTime";

const { Option } = Select;


export function DeveloperIntegrationsAPIKeys(props) {
    const [apps, setApps] = useState([]);
    const [purposes, setPurposes] = useState([]);
    const [err, setErr] = useState("");
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/apps/api_keys_get?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setApps(result.result.AppsList);
                        setPurposes(result.result.PurposesList);
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);


    const onCreate = (values) => {
        PostRequest(getBackendLink() + `/api/v1/integrations/apps/api_keys_create`, {
            "AppID": values.AppID,
            "Comment": values.Comment,
            "APIKey": values.APIKey,
            "APIKeyPurpose": values.APIKeyPurpose,
        })
            .then((result) => {
                if (result.success !== true) {
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Created')
                    setOpen(false)
                    fetchData()
                }
                setLoading(false)
            }, (error) => {
                NotificationError('Error', error.message)
                setLoading(false)
            })
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'KeyID',
            key: 'KeyID',
        },
        {
            title: 'App',
            dataIndex: 'AppName',
            key: 'AppName',
            render: (_, record) => (
                <><a href={GetAppLink(record.AppID)}>{record.AppName}</a></>
            ),
        },
        {
            title: 'APIKeyPurpose',
            dataIndex: 'APIKeyPurposeID',
            key: 'APIKeyPurposeID',
            render: (_, record) => (
                <>{record.APIKeyPurposeID === 1 ?
                    <Tag color={'volcano'}>{record.PurposeName}</Tag> : <>{record.PurposeName}</>}</>
            ),
        },
        {
            title: 'Comment',
            dataIndex: 'KeyComment',
            key: 'KeyComment',
        },
        {
            title: 'CreatedAt',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (_, record) => (
                <>{record.CreatedAt.Valid === true ?
                    <TimeFromSeconds seconds={record.CreatedAt.Time} /> : <></>}</>
            ),
        },
        {
            title: 'UpdatedAt',
            dataIndex: 'UpdatedAt',
            key: 'UpdatedAt',
            render: (_, record) => (
                <>{record.UpdatedAt.Valid === true ?
                    <TimeFromSeconds seconds={record.UpdatedAt.Time} /> : <></>}</>
            ),
        },
        {
            title: 'CreatedBy',
            dataIndex: 'CreatedBy',
            key: 'CreatedBy',
            render: (_, record) => (
                <>{record.CreatedByName === "" ? <><a href={GetUserLink(record.CreatedBy)}>{record.CreatedBy}</a></> :
                    <a href={GetUserLink(record.CreatedBy)}>{record.CreatedByName}</a>}</>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Space wrap>
                        <Popconfirm
                            title="Delete key"
                            description="Are you sure to delete this key?"
                            onConfirm={() => handleDelete(record.KeyID)}
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                        >
                            <Button type="dashed" danger>
                                Delete
                            </Button>
                        </Popconfirm>
                    </Space>
                </>
            ),
        },
    ];

    const handleDelete = (key) => {
        PostRequest(getBackendLink() + `/api/v1/integrations/apps/api_keys_delete`, {
            "key": key,
        })
            .then((result) => {
                if (result.success !== true) {
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Deleted!')
                    const newData = data.filter((item) => item.KeyID !== key);
                    setData(newData);
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
    };


    const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                open={open}
                title="Create API key"
                okText="Create"
                cancelText="Cancel"
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Form.Item
                        name={`AppID`}
                        label={`AppID`}
                        rules={[
                            {
                                required: true,
                                message: 'Select App',
                            },
                        ]}
                    >
                        <Select>
                            {apps.map((x) => (
                                <Option value={x.AppID}>{x.AppName}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={`APIKeyPurpose`}
                        label={`APIKeyPurpose`}
                        rules={[
                            {
                                required: true,
                                message: 'Select API Key Purpose',
                            },
                        ]}
                    >
                        <Select>
                            {purposes.map((x) => (
                                <Option value={x.PurposeID}>{x.PurposeName}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="APIKey"
                        label="APIKey"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="Comment" label="Description">
                        <Input type="textarea" />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    if (IsDeveloper(props.token.Roles) !== true) {
        return (
            <ErrorBlock error={errorTranslation("access_denied")} />
        )
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    return (
        <>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={12}>
                        <BreadcrumbsDeveloperIntegrationsAPIKeys />
                    </Col>
                    <Col xs={24} xl={12}>
                        <Button
                            disabled={loading}
                            onClick={() => {
                                setOpen(true);
                            }}
                            type="primary"
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            Add API key
                        </Button>
                    </Col>
                </Row>
                {err !== "" ? <Col xs={24} xl={24}><ErrorBlock error={errorTranslation(err)} /></Col> : <></>}
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.product}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                    <CollectionCreateForm
                        open={open}
                        onCreate={onCreate}
                        onCancel={() => {
                            setOpen(false);
                        }}
                    />
                </Row>
            </div>
        </>
    )
}

