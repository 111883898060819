import { Tag, Typography } from "antd";
import { getBackendLink, MoreFloGroups } from "./backend/backend";
import React from "react";
import { ProductLinkInline } from "./product";
import Moment from "react-moment";

const { Paragraph } = Typography

export var MoreFloAPIProductModel = {
    ArticleName: "",
    AccountCodeId: "",
    ArticleInfoUpdateTime: "",
    ArticleType: "",
    Barcode: "",
    BarcodeAlternatives: "",
    GroupId: "",
    InputPrice: "",
    QuantityUpdatedTime: "",
    RecommendedPrice: "",
    RetailPrice: "",
    Unit: "",
}

export const Groups = {
    "81024206-36b4-4d9e-a6f7-fa9641743b0c": "Exclude barcode sync",
    "3f5a51f5-2509-47d6-99cf-6ce15c61ffed": "Include barcode sync",
    "b0a043a3-8c8d-4ca0-ab28-88346917be8a": "Tjänst"
}

export const AccountCodes = {
    "4a7cdfa8-175e-4200-893c-8781c5ce193d": "Standard25"
}

export const ColumnsMoreFloHistory = [
    {
        title: 'Item SKU',
        dataIndex: 'ArticleNumber',
        key: 'ArticleNumber',
        fixed: 'left',
        render: (_, record) => (
            <>
                <ProductLinkInline sku={record.ArticleNumber} />
            </>
        ),
    },
    {
        title: 'Run ID',
        dataIndex: 'BatchNumber',
        key: 'BatchNumber',
        render: (_, record) => (
            <><Paragraph copyable={{ text: record.BatchNumber }}>{record.BatchNumber}</Paragraph></>
        ),
    },
    {
        title: 'Run time',
        dataIndex: 'BatchTime',
        key: 'BatchTime',
        render: (_, record) => (
            <><Moment format="YYYY.MM.DD HH:mm:ss" date={record.BatchTime} /></>
        ),
    },
    {
        title: 'Article Name',
        dataIndex: 'ArticleName',
        key: 'ArticleName',
    },
    {
        title: 'Updated',
        dataIndex: 'Updated',
        key: 'Updated',
        render: (_, record) => (
            <>{JSON.stringify(record.UpdatedAt.Time)}</>
        ),
    },
    {
        title: 'Barcodes',
        dataIndex: 'Barcodes',
        key: 'Barcodes',
        render: (_, record) => (
            <>{record.Barcode + "," + JSON.stringify(record.BarcodeAlternatives)}</>
        ),
    },
    {
        title: 'Retail Price',
        dataIndex: 'RetailPrice',
        key: 'RetailPrice',
    },
    {
        title: 'Is Active',
        dataIndex: 'IsActive',
        key: 'IsActive',
        render: (_, record) => (
            <>{record.IsActive ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Group ID',
        dataIndex: 'GroupId',
        key: 'GroupId',
        render: (_, record) => (
            <>{MoreFloGroups[record.GroupId]}</>
        ),
    },
    {
        title: 'Quantity',
        dataIndex: 'Quantity',
        key: 'Quantity',
    },
    {
        title: 'Quantity Updated',
        dataIndex: 'QuantityUpdatedTime',
        key: 'QuantityUpdatedTime',
    },
    {
        title: 'Account Code ID',
        dataIndex: 'AccountCodeId',
        key: 'AccountCodeId',
    },
    {
        title: 'Article Info Updated',
        dataIndex: 'ArticleInfoUpdateTime',
        key: 'ArticleInfoUpdateTime',
    },
    {
        title: 'Created',
        dataIndex: 'Created',
        key: 'Created',
        render: (_, record) => (
            <>{JSON.stringify(record.CreatedAt.Time)}</>
        ),
    },
    {
        title: 'ID',
        dataIndex: 'ID',
        key: 'ID',
    },
    {
        title: 'Input Price',
        dataIndex: 'InputPrice',
        key: 'InputPrice',
    },
    {
        title: 'Recommended Price',
        dataIndex: 'RecommendedPrice',
        key: 'RecommendedPrice',
    },
    {
        title: 'Unit',
        dataIndex: 'Unit',
        key: 'Unit',
    },
    {
        title: 'Is Bookable',
        dataIndex: 'IsBookable',
        key: 'IsBookable',
        render: (_, record) => (
            <>{record.IsBookable ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Open Article',
        dataIndex: 'IsOpenArticle',
        key: 'IsOpenArticle',
        render: (_, record) => (
            <>{record.IsOpenArticle ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Quick Button',
        dataIndex: 'IsQuickButton',
        key: 'IsQuickButton',
        render: (_, record) => (
            <>{record.IsQuickButton ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Stock Item',
        dataIndex: 'IsStockItem',
        key: 'IsStockItem',
        render: (_, record) => (
            <>{record.IsStockItem ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Is Webshop Article',
        dataIndex: 'IsWebshopArticle',
        key: 'IsWebshopArticle',
        render: (_, record) => (
            <>{record.IsWebshopArticle ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
];

export function GetLinkProductMoreFloAPI(sku = '') {
    return `https://api.moreflo.com/v3/articles/article?ArticleNumber=${sku}`
}

export function GetLinkCSVMoreFloHistory(batch, sku, barcode) {
    return getBackendLink() + `/api/v1/integrations/more_flo/cache_records_get_csv?batch_id=${batch}&sku=${sku}&barcode=${barcode}`
}

export function GetLinkProductMoreFlo(sku = '') {
    return `/products/${sku}/more_flo`
}