import { Button, Result } from 'antd';
import { Col, Row } from 'antd';


export function RestorePassword() {
    return (
        <>
            <Row style={{ "marginTop": "1em" }}>
                <Col span={8} offset={8}>
                    <Result
                        status="403"
                        title="Change password"
                        subTitle="You must change password before you can proceed"
                        extra={<Button href='/' type="primary">Home</Button>}
                    />
                </Col>
            </Row>
        </>
    )
}