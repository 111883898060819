import React, { useState } from 'react';
import {
    Row,
    Col,
    Tag,
    Button,
    Space,
    Tour
} from 'antd';
import {
    BreadcrumbsDeveloperAccessTokens,
} from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
    CanCreateAccessTokens,
} from "../../../../components/backend/backendRoles";
import { useRef } from "react";
import { AccessTokensTable } from "../../../../subpages/security/accessTokensTable";
import { Helmet } from "react-helmet";

export const DeveloperAccessTokensList = (props) => {
    const [openTour, setOpenTour] = useState(false);
    const refActions = useRef(null);
    const [total, setTotal] = useState(0);

    const tourSteps = [
        {
            title: 'Token list',
            description: 'This page has table with all active (not expired or revoked) tokens in the system',
        },
        {
            title: 'Data',
            description: 'Token ID is just an unique identifier of the token. It is NOT the token itself - token string ' +
                'can not be seen anywhere in the system. All actions made with token will be treated as if token Owner ' +
                'has made them',
        },
        {
            title: 'Grants',
            description: 'Press Grants to see exact list of actions this token gives access to',
        },
        {
            title: 'Expire',
            description: 'Press Expire to expire token. Its expiration date will be set to current moment',
        },
        {
            title: 'Revoke',
            description: 'Press Revoke to revoke token. Token will be marked as revoked',
        },
    ];

    return (
        <>
            <Helmet>
                <title>Access token list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperAccessTokens />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpenTour(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                About this page <QuestionCircleOutlined />
                            </Button>
                            <Button
                                disabled={!CanCreateAccessTokens(props.grants)}
                                type="primary"
                                href={"/developer/access-tokens/add"}
                            >
                                Add token
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        Total: <Tag color={"purple"}>{total}</Tag>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24} ref={refActions}>
                        <AccessTokensTable setTotal={setTotal} grants={props.grants} sessionID={props.sessionID} />
                    </Col>
                </Row>
            </div>
            <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
        </>
    );
};