import { Button, Result } from 'antd';
import { Col, Row } from 'antd';
import { Helmet } from "react-helmet";
import React from "react";


export function NoAccess() {
    return (
        <>
            <Helmet>
                <title>Forbidden || Bangerhead Admin</title>
            </Helmet>
            <Row style={{ "marginTop": "1em" }}>
                <Col span={8} offset={8}>
                    <Result
                        status="403"
                        title="403"
                        subTitle="No access :("
                        extra={<Button href='/' type="primary">Home</Button>}
                    />
                </Col>
            </Row>
        </>
    )
}