import { Typography } from "antd";
import { GetProductHref } from "./getPageLinks";

const { Paragraph } = Typography

export function ProductLinkInline(props) {
    if (props.sku === "" || props.sku === undefined) {
        return <></>
    }

    return (
        <Paragraph copyable={{ text: props.sku }}>
            <a type="primary"
                href={GetProductHref(props.sku)}
                target={"_blank"}
                rel={"noreferrer"}>
                {props.sku}
            </a>
        </Paragraph>
    )
}

export function StringifiedCategory(cat) {
    if (cat === undefined || cat === null) {
        return ""
    }

    if (cat.ParentName2 !== undefined && cat.ParentName !== undefined && cat.Name !== undefined) {
        return cat.ParentName2 + " > " + cat.ParentName + " > " + cat.Name
    }

    if (cat.ParentName !== undefined && cat.Name !== undefined) {
        return cat.ParentName + " > " + cat.Name
    }

    if (cat.Name !== undefined) {
        return cat.Name
    }

    return ""
}

export function StringifiedCategoryFromProdBrandModel(cat) {
    if (cat === undefined || cat === null) {
        return null
    }

    if (cat.Cat1Name !== undefined && cat.Cat2Name !== undefined && cat.Cat3Name !== undefined) {
        return cat.Cat1Name + " > " + cat.Cat2Name + " > " + cat.Cat3Name
    }

    if (cat.Cat1Name !== undefined && cat.Cat2Name !== undefined) {
        return cat.Cat1Name + " > " + cat.Cat2Name
    }

    if (cat.Cat1Name !== undefined) {
        return cat.Cat1Name
    }

    return null
}

export function StringifiedCategoryFromProdBrandModelLevel1(cat) {
    if (cat === undefined || cat === null) {
        return null
    }

    if (cat.Cat1Name !== undefined) {
        return cat.Cat1Name
    }

    return null
}



export function StringifiedCategoryFromProdBrandModelLevel2(cat) {
    if (cat === undefined || cat === null) {
        return null
    }

    if (cat.Cat1Name !== undefined && cat.Cat2Name !== undefined) {
        return cat.Cat1Name + " > " + cat.Cat2Name
    }

    return null
}


export function StringifiedCategoryFromProdBrandModelLevel3(cat) {
    if (cat === undefined || cat === null) {
        return null
    }

    if (cat.Cat1Name !== undefined && cat.Cat2Name !== undefined && cat.Cat3Name !== undefined) {
        return cat.Cat1Name + " > " + cat.Cat2Name + " > " + cat.Cat3Name
    }

    return null
}

export function PriceStringified(
    price = { PriceStringified: "", CurrencyCode: "" },
    useCurrency = false,
) {

    if (price === null || price === undefined) {
        return "0.00"
    }

    let result = ""

    if (price.PriceStringified === "" || price.PriceStringified === null || price.PriceStringified === undefined) {
        result = "0.00"

        if (useCurrency && price.CurrencyCode !== undefined && price.CurrencyCode !== null) {
            return result + " " + price.CurrencyCode
        }
    }

    result = price.PriceStringified

    if (useCurrency && price.CurrencyCode !== undefined && price.CurrencyCode !== null) {
        return result + " " + price.CurrencyCode
    }

    return result
}

// export function StringifiedPrice(
//     price = {PriceStringified:"",CurrencyCode:""},
//     useZeroDecimal = false,
//     useCurrency = false,
//     returnZero = false,
// ){
//     let result = ""
//
//     if (price === null || price === undefined){
//         if (returnZero){
//             result = "0"
//         }
//
//         if (useZeroDecimal && returnZero){
//             result += ".0"
//         }
//
//         return result
//     }
//
//     if (price.PriceStringified !== "" && price.PriceStringified === null || price.PriceStringified === undefined){
//
//     }
//
//     // if (price.units > 0){
//     //     result += price.units
//     // } else {
//     //     result += "0"
//     // }
//     //
//     // if (price.nanos > 0){
//     //     result += "." + price.nanos
//     // } else if (useZeroDecimal) {
//     //     result += ".0"
//     // }
//     //
//     // if (result === "0.0" || result === "0"){
//     //     if (!returnZero){
//     //         return ""
//     //     }
//     // }
//     //
//     // if (useCurrency){
//     //     result += " " + price.currency_code
//     // }
//
//     return result
// }