import { Button, Result } from 'antd';
import { Col, Row } from 'antd';
import { Helmet } from "react-helmet";
import React from "react";


export function NotFound() {
    return (
        <>
            <Helmet>
                <title>Not found || Bangerhead Admin</title>
            </Helmet>
            <Row style={{ "marginTop": "1em" }}>
                <Col span={8} offset={8}>
                    <Result
                        status="404"
                        title="404"
                        subTitle="No such page :("
                        extra={<Button href='/' type="primary">Home</Button>}
                    />
                </Col>
            </Row>
        </>
    )
}