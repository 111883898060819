import { Breadcrumb } from "antd";
import { pages } from "./breadcrumbs";

export function BreadcrumbsProfile(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["profile"],
                {
                    title: (
                        <>
                            <span>{props.id}</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProfileSettings() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["profile"],
                {
                    title: (
                        <>
                            <span>Settings</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProfileSecurity() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["profile"],
                {
                    title: (
                        <>
                            <span>Security</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProfileMessages() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["profile"],
                {
                    title: (
                        <>
                            <span>Messages</span>
                        </>
                    ),
                },
            ]}
        />
    )
}