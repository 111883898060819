import React from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import { Image } from 'antd';
import { useParams } from 'react-router-dom';
import { QRCode } from 'antd';
import { Avatar } from 'antd';
import { GetRequest } from "../../../components/backend/getRequest"
import { Spinner, SpinnerInline } from "../../../components/spinners"
import { Space } from 'antd';
import { actionOnError, errorTranslation, getBackendLink } from "../../../components/backend/backend"
import { BreadcrumbsProfile } from "../../../components/breadcrumbs/breadcrumbsProfile";
import { useEffect, useState } from "react";
import { NotificationError } from "../../../components/notifications";
import { Helmet } from "react-helmet";

export const Profile = (props) => {
    const { id } = useParams();

    const [profileData, setProfileData] = useState({});
    const [isFetching, setIsFetching] = useState(false);

    const fetchData = () => {
        setIsFetching(true)
        GetRequest(getBackendLink() + `/api/v1/users/get?user_id=${id}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)

                        NotificationError('Error', errorTranslation(result.error))
                    } else {
                        setProfileData(result.result.data)
                    }
                    setIsFetching(false)
                },
                (error) => {
                    NotificationError('Error', errorTranslation(error.message))
                }
            )
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    let avatar = <Avatar shape="square" size={250} src={undefined}>{profileData.FirstName}</Avatar>
    if (profileData.AvatarLink !== "") {
        avatar = <Avatar shape="square" size={250}
            src={<Image src={profileData.AvatarLink} style={{ width: "100%" }} />} />
    }

    let profileName = <SpinnerInline />

    if (isFetching === false) {
        profileName = `${profileData.FirstName} ${profileData.LastName}`
    }

    let titleName = "Employee profile"
    if (id === props.uid) {
        titleName = "My profile"
    }

    return (
        <>
            <Helmet>
                <title>{titleName} || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProfile id={profileName} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        {isFetching ? <Spinner /> : <></>}
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={6}>
                        <Space direction="vertical" style={{ width: '100%', textAlign: "center" }}>
                            {
                                isFetching
                                    ?
                                    <></>
                                    :
                                    <>
                                        <Typography.Title
                                            copyable={{ text: profileData.FirstName + " " + profileData.LastName }}
                                            level={3} style={{ margin: 0 }}>
                                            {profileData.FirstName} {profileData.LastName}
                                        </Typography.Title>
                                        <>
                                            {avatar}
                                        </>
                                        <QRCode style={{ margin: "auto" }}
                                            errorLevel="H"
                                            value={getBackendLink() + "/profile/" + id}
                                            icon="/favicon.png"
                                        >
                                        </QRCode>
                                        <Divider>Tags</Divider>
                                    </>
                            }

                            {/*{CanEditUsers(props.grants) === true ? <>*/}
                            {/*    <Divider>Actions</Divider>{editUserButton}</> : <></>}*/}
                            <Space size={[0, 8]} wrap>
                                {/*{this.state.profileData.is_admin ?*/}
                                {/*    <Popover content={"This user is an administrator"} title={"Admin user"}*/}
                                {/*             trigger="hover">*/}
                                {/*        <Tag color="red">ADMIN</Tag>*/}
                                {/*    </Popover>*/}
                                {/*    :*/}
                                {/*    <></>*/}
                                {/*}*/}
                                {/*{this.state.profileData.ProductAdmin ?*/}
                                {/*    <Popover content={"This user can edit product data"} title={"Product editor"}*/}
                                {/*             trigger="hover">*/}
                                {/*        <Tag color="lime">PROUCT EDITOR</Tag>*/}
                                {/*    </Popover>*/}
                                {/*    :*/}
                                {/*    <></>*/}
                                {/*}*/}
                                {/*{this.state.profileData.IsDeveloper ?*/}
                                {/*    <Popover content={"This user is a developer"} title={"Developer"}*/}
                                {/*             trigger="hover">*/}
                                {/*        <Tag color="blue">DEVELOPER</Tag>*/}
                                {/*    </Popover>*/}
                                {/*    :*/}
                                {/*    <></>*/}
                                {/*}*/}
                            </Space>
                        </Space>
                    </Col>
                    <Col xs={24} xl={6}>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export function GetFriendImg(link, avatar) {
    if (avatar !== "") {
        return <a href={link}>
            <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} shape="square" src={avatar}></Avatar>
        </a>
    }
    return <a href={link}>
        <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} shape="square"></Avatar>
    </a>
}

