import { Breadcrumb } from "antd";
import { pages } from "./breadcrumbs";
import { BarcodeOutlined } from "@ant-design/icons";

export function BreadcrumbsProducts() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                {
                    title: (
                        <>
                            <BarcodeOutlined />
                            <span>Products</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProductsList() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    title: (
                        <>
                            <span>List</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProductsBrands() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    title: (
                        <>
                            <span>Brands</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProductsCategories() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    title: (
                        <>
                            <span>Categories</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProductsCountries() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    title: (
                        <>
                            <span>Countries</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProductsProduct(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    title: (
                        <>
                            <span>{props.sku}</span>
                        </>
                    ),
                },
            ]}
        />
    )
}


export function BreadcrumbsProductsPrices() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    title: (
                        <>
                            <span>Prices</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProductsPricesDynamic() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                pages["prices"],
                {
                    title: (
                        <>
                            <span>Dynamic</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProductsProductSlim4(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    href: `/products/${props.sku}`,
                    title: (
                        <>
                            <span>{props.sku}</span>
                        </>
                    ),
                },
                {
                    title: (
                        <>
                            <span>Slim4</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsProductsProductMoreFlo(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    href: `/products/${props.sku}`,
                    title: (
                        <>
                            <span>{props.sku}</span>
                        </>
                    ),
                },
                {
                    title: (
                        <>
                            <span>MoreFlo</span>
                        </>
                    ),
                },
            ]}
        />
    )
}


export function BreadcrumbsProductsProductRedis(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["products"],
                {
                    href: `/products/${props.sku}`,
                    title: (
                        <>
                            <span>{props.sku}</span>
                        </>
                    ),
                },
                {
                    title: (
                        <>
                            <span>Redis</span>
                        </>
                    ),
                },
            ]}
        />
    )
}