import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import { Space, Table, Tag } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { GetRequest } from "../../../components/backend/getRequest";
import { actionOnError, errorTranslation, getBackendLink } from "../../../components/backend/backend";
import { ErrorRow } from "../../../components/errorBlock";
import { PostRequest } from "../../../components/backend/postRequest";
import { NotificationError, NotificationSuccess } from "../../../components/notifications";
import { GetIPCheckerLink, GetUserLink } from "../../../components/getPageLinks";
import {
    BreadcrumbsAdminUsersSessions
} from "../../../components/breadcrumbs/breadcrumbsAdmin";
import { TimeFromSeconds } from "../../../components/dateAndTime";
import { Helmet } from "react-helmet";
import { FormatShortUUID } from '../../../components/formattingValues';

export const AdminSessions = (props) => {
    const closeSession = (sid) => {
        PostRequest(getBackendLink() + `/api/v1/sessions/admin/close`, {
            "sid": sid,
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        NotificationError('Error', errorTranslation(result.error))
                    } else {
                        NotificationSuccess('Saved!', 'Session closed')
                    }
                },
                (error) => {
                    NotificationError('Error', error.message)
                }
            )
    }

    const [loadingSessionsActiveData, setLoadingSessionsActiveData] = useState(false);
    const [sessionsActiveData, setSessionsActiveData] = useState([]);
    const [sessionsActiveErr, setSessionsActiveErr] = useState("");
    const [sessionsActiveTableParams, setSessionsActiveTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleSessionsActiveTableChange = (pagination, filters, sorter) => {
        setSessionsActiveTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== sessionsActiveTableParams.pagination?.pageSize) {
            setSessionsActiveData([]);
        }
    };

    const fetchSessionsActive = () => {
        setLoadingSessionsActiveData(true);
        GetRequest(getBackendLink() + `/api/v1/sessions/admin/get_list?page=${sessionsActiveTableParams.pagination.current}&size=${sessionsActiveTableParams.pagination.pageSize}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setSessionsActiveErr(result.error)
                    } else {
                        setSessionsActiveErr("")
                        setSessionsActiveData(result.result.Records);
                        setSessionsActiveTableParams({
                            ...sessionsActiveTableParams,
                            pagination: {
                                ...sessionsActiveTableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoadingSessionsActiveData(false);
                },
                (error) => {
                    setLoadingSessionsActiveData(false);
                    NotificationError('Error', error.message)
                }
            )
    };
    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        fetchSessionsActive();
        // eslint-disable-next-line
    }, [JSON.stringify(sessionsActiveTableParams)]);

    const columnsSessionsActive = [
        {
            title: 'ID',
            dataIndex: 'ID',
            key: 'ID',
            render: (_, record) => (
                <><a href={`/admin/sessions/${record.SessionID}`}>{FormatShortUUID(record.SessionID)}</a>
                    {record.SessionID === props.token.SessionID ? <><Tag
                        color={'lime'}>current</Tag></> : <></>}</>
            ),
        },
        {
            title: 'User',
            dataIndex: 'User',
            key: 'User',
            render: (_, record) => (
                <>{record.UserName === "" ? <><a href={GetUserLink(record.UserID)}>{record.UserID}</a></> :
                    <a href={GetUserLink(record.UserID)}>{record.UserName}</a>}</>
            ),
        },
        {
            title: 'Address',
            dataIndex: 'IPAddr',
            key: 'IPAddr',
            render: (_, record) => (
                <>{record.IPAddr !== "" && record.IPAddr !== undefined ? GetIPCheckerLink(record.IPAddr) : <></>}</>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'Location',
            render: (_, record) => (
                <>{record.Country !== "" && record.Country !== undefined ? record.Country : <>unknown</>}{record.Location !== "" && record.Location !== undefined ? <>, {record.Location}</> : <>, unknown</>}</>
            ),
        },
        {
            title: 'OS',
            dataIndex: 'OS',
            key: 'OS',
            render: (_, record) => (
                <>{`${record.OS} ${record.OSVersion}`}</>
            ),
        },
        {
            title: 'Browser',
            dataIndex: 'Browser',
            key: 'Browser',
            render: (_, record) => (
                <>{`${record.BrowserName} ${record.BrowserVersion}`}</>
            ),
        },
        {
            title: 'Started',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (_, record) => (
                <>{record.CreatedAt ? <TimeFromSeconds seconds={record.CreatedAt.seconds * 1000} /> : <></>}</>
            ),
        },
        {
            title: 'Expires',
            dataIndex: 'ExpiresAt',
            key: 'ExpiresAt',
            render: (_, record) => (
                <>{record.ExpiresAt ? <TimeFromSeconds seconds={record.ExpiresAt.seconds * 1000} /> : <></>}</>
            ),
        },
        {
            title: 'Ended',
            dataIndex: 'EndedAt',
            key: 'EndedAt',
            render: (_, record) => (
                <>{record.EndedAt ? <TimeFromSeconds seconds={record.EndedAt.seconds * 1000} /> : <></>}</>
            ),
        },
        {
            title: 'Not my computer',
            dataIndex: 'NotMyDevice',
            key: 'NotMyDevice',
            render: (_, record) => (
                <>{record.NotMyDevice === true ? <Tag color={'volcano'}>YES</Tag> : <></>}</>
            ),
        },
        {
            title: 'SignInMethod',
            dataIndex: 'SignInMethod',
            key: 'SignInMethod',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {record.IsEnded === true
                        ?
                        <></>
                        :
                        <Tooltip placement="top" title={"Close session"}>
                            <Button className="button-primary" type="primary" onClick={function () {
                                closeSession(record.SessionID)
                            }}><LogoutOutlined /></Button>
                        </Tooltip>
                    }
                </Space>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Sessions list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdminUsersSessions />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                {sessionsActiveErr !== "" ? <ErrorRow prefix={"Error"} err={sessionsActiveErr} /> : <></>}
                <Row style={{ marginTop: "1em", marginBottom: 16 }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columnsSessionsActive}
                            rowKey={(record) => record.ID}
                            dataSource={sessionsActiveData}
                            pagination={sessionsActiveTableParams.pagination}
                            loading={loadingSessionsActiveData}
                            size={"small"}
                            onChange={handleSessionsActiveTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};