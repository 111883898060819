import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Tag, Typography, Row, Space, Table, Descriptions, Card } from "antd";
import { EyeOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { ErrorRow } from "../../../components/errorBlock";
import { GetRequest } from "../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../components/backend/backend";
import { NotificationError } from "../../../components/notifications";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/spinners";
import { GetProductHref } from '../../../components/getPageLinks';
import { Tabs } from 'antd';
import Moment from 'react-moment';
import { InlineMenu } from "../../../components/menus/inline";
import { GetProductPageMenuItems } from "../../../components/menus/itemsProductPage";
import { BreadcrumbsProductsProductSlim4 } from "../../../components/breadcrumbs/breadcrumbsProducts";
import { GetLinkProductAskas } from "../../../components/askas";
import { Helmet } from "react-helmet";

const { Paragraph } = Typography

export const ProductSlim4 = () => {
    const { sku } = useParams();

    const [slim4DataErr, setSlim4DataErr] = useState("");
    const [slim4Data, setSlim4Data] = useState();
    const [slim4DataLoading, setSlim4DataLoading] = useState(false);
    const [historyDatatableParams, setHistoryDatatableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const [slim4CurrentDataErr, setSlim4CurrentDataErr] = useState("");
    const [slim4CurrentData, setSlim4CurrentData] = useState({
        ArticleCodeMaster: { Criterium1: {}, SalesPrice: {}, UnitPrice: {}, ArticleStatus: {}, UD2: {} },
        Logistics: { HasActivityIn2years: {}, BuyingPrice: {}, LeadTime: {} },
        Stock: {}
    },
    );
    const [slim4CurrentDataLoading, setSlim4CurrentDataLoading] = useState(false);

    const handleSlim4DataTableChange = (pagination, filters, sorter) => {
        setHistoryDatatableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== historyDatatableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setSlim4Data([]);
        }
    };

    const fetchSlim4Records = () => {
        setSlim4DataLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/slim4/cache_records_get?page=${historyDatatableParams.pagination.current}&size=${historyDatatableParams.pagination.pageSize}&batch_id=all&sku=${sku}&brand_id=all&ud1=all`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setSlim4DataErr(result.error)
                    } else {
                        setSlim4DataErr("")
                        setSlim4Data(result.result.Records);
                        setHistoryDatatableParams({
                            ...historyDatatableParams,
                            pagination: {
                                ...historyDatatableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setSlim4DataLoading(false);
                },
                (error) => {
                    setSlim4DataLoading(false);
                    setSlim4DataErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchSlim4Records();
        // eslint-disable-next-line
    }, [JSON.stringify(historyDatatableParams)]);

    const fetchSlim4CurrentProductState = () => {
        setSlim4CurrentDataLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/slim4/product_current_data_get?sku=${sku}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setSlim4CurrentDataErr(result.error)
                    } else {
                        setSlim4CurrentDataErr("")
                        setSlim4CurrentData(result.result.products[sku]);
                    }
                    setSlim4CurrentDataLoading(false);
                },
                (error) => {
                    setSlim4CurrentDataLoading(false);
                    setSlim4CurrentDataErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchSlim4CurrentProductState();
        // eslint-disable-next-line
    }, []);

    const menuItems = GetProductPageMenuItems(sku)
    menuItems.push(
        {
            label: <a
                href={getBackendLink() + `/api/v1/integrations/slim4/cache_records_get_csv?batch_id=all&sku=${sku}&brand_id=all&ud1=all`}
                target={"_blank"} rel="noreferrer">Download CSV</a>,
            icon: <CloudDownloadOutlined />,
        },
    )

    return (
        <>
            <Helmet>
                <title>Product data (Slim4) || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsProductSlim4 sku={sku} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <InlineMenu items={menuItems} />
                    </Col>
                </Row>
            </div>

            <div className="container-1700" style={{ "marginTop": "1em" }}>
                <ErrorRow prefix={"History"} err={slim4DataErr} />
                <ErrorRow prefix={"Current"} err={slim4CurrentDataErr} />

                <Row style={{ marginTop: "1em", marginBottom: 16 }}>
                    <Col xs={24} xl={24}>
                        <Tabs
                            defaultActiveKey="History"
                            items={[
                                {
                                    label: 'History',
                                    key: 'History',
                                    children: slim4DataLoading === true ? <Spinner /> : <Table
                                        dataSource={slim4Data}
                                        columns={historyColumns}
                                        loading={slim4DataLoading}
                                        pagination={historyDatatableParams.pagination}
                                        rowKey={(record) => record.id}
                                        size={"small"}
                                        onChange={handleSlim4DataTableChange}
                                        showSizeChanger={true}
                                        scroll={{
                                            y: 500,
                                            x: 4000,
                                        }}
                                    />,
                                },
                                {
                                    label: 'Current state',
                                    key: 'Current_state',
                                    children: slim4CurrentDataLoading === true ? <Spinner /> : <>
                                        <Row style={{ marginTop: "1em", marginBottom: 16, }}>
                                            <Col xs={24} xl={24}>
                                                {slim4CurrentData.HasArticleCodeMaster ?
                                                    <Tag color={'lime'}>Has ArticleCodeMaster</Tag> :
                                                    <Tag color={'volcano'}>No ArticleCodeMaster</Tag>}
                                                {slim4CurrentData.HasStock ? <Tag color={'lime'}>Has Stock</Tag> :
                                                    <Tag color={'volcano'}>No Stock</Tag>}
                                                {slim4CurrentData.HasLogistics ?
                                                    <Tag color={'lime'}>Has Logistics</Tag> :
                                                    <Tag color={'volcano'}>No Logistics</Tag>}
                                                {<>Brand ID: {slim4CurrentData.BrandID}</>}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "1em", marginBottom: 16, }}>
                                            <Col xs={24} xl={24}>
                                                <Card title={`${sku} ArticleCodeMaster`}>
                                                    <Descriptions size={"small"}>
                                                        <Descriptions.Item label={<>Warehouse</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.Warehouse}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>CreationDate</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.CreationDate}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>Description</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.Description}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>UnitPrice</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.UnitPrice.Float64}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>SalesPrice</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.SalesPrice.Float64}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>Criterium1</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.Criterium1.Float64}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>GroupCode1</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.GroupCode1}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>GroupCode2</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.GroupCode2}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>GroupCode3</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.GroupCode3}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>GroupCode4</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.GroupCode4}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>UD1</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.UD1}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>UD2</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.UD2.String}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>AUDField1</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.AUDField1}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>AUDField2</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.AUDField2}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>AUDField3</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.AUDField3}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>ArticleStatus</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.ArticleStatus.String}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>BarCode</>}>
                                                            {slim4CurrentData.ArticleCodeMaster.BarCode}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "1em", marginBottom: 16, }}>
                                            <Col xs={24} xl={24}>
                                                <Card title={`${sku} Stock`}>
                                                    <Descriptions size={"small"}>
                                                        <Descriptions.Item label={<>Warehouse</>}>
                                                            {slim4CurrentData.Stock.Warehouse}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>StockOnHand</>}>
                                                            {slim4CurrentData.Stock.StockOnHand}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "1em", marginBottom: 16, }}>
                                            <Col xs={24} xl={24}>
                                                <Card title={`${sku} Logistics`}>
                                                    <Descriptions size={"small"}>
                                                        <Descriptions.Item label={<>Warehouse</>}>
                                                            {slim4CurrentData.Logistics.Warehouse}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>SupplierNumber</>}>
                                                            {slim4CurrentData.Logistics.SupplierNumber}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>SupplierName</>}>
                                                            {slim4CurrentData.Logistics.SupplierName}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>LeadTime</>}>
                                                            {slim4CurrentData.Logistics.LeadTime.Float64}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>StockedItem</>}>
                                                            {slim4CurrentData.Logistics.StockedItem}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>MOQ</>}>
                                                            {slim4CurrentData.Logistics.MOQ}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>IOQ</>}>
                                                            {slim4CurrentData.Logistics.IOQ}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>BuyingPrice</>}>
                                                            {slim4CurrentData.Logistics.BuyingPrice.Float64}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>MSQ</>}>
                                                            {slim4CurrentData.Logistics.MSQ}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>ISQ</>}>
                                                            {slim4CurrentData.Logistics.ISQ}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>SupplierArticleCode</>}>
                                                            {slim4CurrentData.Logistics.SupplierArticleCode}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label={<>HasActivityIn2years</>}>
                                                            {slim4CurrentData.Logistics.HasActivityIn2years.String}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </>,
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}


const historyColumns = [
    {
        title: 'Item SKU',
        dataIndex: 'code',
        key: 'code',
        fixed: 'left',
        render: (_, record) => (
            <>
                <Space wrap>
                    <Paragraph copyable={{ text: record.code }}>{record.code}</Paragraph>
                    <Button type="primary" href={GetLinkProductAskas(record.code)} target={"_blank"}
                        rel={"noreferrer"}>
                        Å
                    </Button>
                    <Button type="primary" href={GetProductHref(record.code)} target={"_blank"}
                        rel={"noreferrer"}>
                        <EyeOutlined />
                    </Button>
                </Space>
            </>
        ),
    },
    {
        title: 'Run ID',
        dataIndex: 'batch_number',
        key: 'batch_number',
        render: (_, record) => (
            <><Paragraph copyable={{ text: record.batch_number }}>{record.batch_number}</Paragraph></>
        ),
    },
    {
        title: 'Run time',
        dataIndex: 'batch_time',
        key: 'batch_time',
        render: (_, record) => (
            <><Moment format="YYYY.MM.DD HH:mm" date={record.batch_time} /></>
        ),
    },
    {
        title: 'Logistics',
        dataIndex: 'has_logistics',
        key: 'has_logistics',
        render: (_, record) => (
            <>{record.has_logistics === true ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Article Code Master',
        dataIndex: 'has_article_code_master',
        key: 'has_article_code_master',
        render: (_, record) => (
            <>{record.has_article_code_master === true ? <Tag color={'lime'}>YES</Tag> :
                <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Stock',
        dataIndex: 'has_stock',
        key: 'has_stock',
        render: (_, record) => (
            <>{record.has_stock === true ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Cancelled',
        dataIndex: 'cancelled',
        key: 'cancelled',
        render: (_, record) => (
            <>{record.cancelled ? <Tag color={'volcano'}>YES</Tag> :
                <Tag color={'lime'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Activity in 2 years',
        dataIndex: 'has_activity_in_2years',
        key: 'has_activity_in_2years',
        render: (_, record) => (
            <>{record.has_activity_in_2years !== "" ? <Tag color={'lime'}>YES</Tag> :
                <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
    {
        title: 'Brand ID',
        dataIndex: 'BrandId',
        key: 'BrandId',
    },
    {
        title: 'Buying Price',
        dataIndex: 'BuyingPrice',
        key: 'BuyingPrice',
        render: (_, record) => (
            <>{record.BuyingPrice}</>
        ),
    },
    {
        title: 'DisposableQuantity',
        dataIndex: 'DisposableQuantity',
        key: 'DisposableQuantity',
    },
    {
        title: 'IOQ',
        dataIndex: 'IOQ',
        key: 'IOQ',
    },
    {
        title: 'ISQ',
        dataIndex: 'ISQ',
        key: 'ISQ',
    },
    {
        title: 'MOQ',
        dataIndex: 'MOQ',
        key: 'MOQ',
    },
    {
        title: 'MSQ',
        dataIndex: 'MSQ',
        key: 'MSQ',
    },
    {
        title: 'LeadTime',
        dataIndex: 'LeadTime',
        key: 'LeadTime',
    },
    {
        title: 'StockedItem',
        dataIndex: 'StockedItem',
        key: 'StockedItem',
    },
    {
        title: 'SupplierName',
        dataIndex: 'SupplierName',
        key: 'SupplierName',
    },
    {
        title: 'SupplierNumber',
        dataIndex: 'SupplierNumber',
        key: 'SupplierNumber',
    },
    {
        title: 'aUDField3',
        dataIndex: 'aUDField3',
        key: 'aUDField3',
    },
    {
        title: 'barCode',
        dataIndex: 'barCode',
        key: 'barCode',
    },
    {
        title: 'creationDate',
        dataIndex: 'creationDate',
        key: 'creationDate',
    },
    {
        title: 'criterium1',
        dataIndex: 'criterium1',
        key: 'criterium1',
        render: (_, record) => (
            <>{record.criterium1}</>
        ),
    },
    {
        title: 'description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'groupCode1',
        dataIndex: 'groupCode1',
        key: 'groupCode1',
    },
    {
        title: 'groupCode2',
        dataIndex: 'groupCode2',
        key: 'groupCode2',
    },
    {
        title: 'groupCode3',
        dataIndex: 'groupCode3',
        key: 'groupCode3',
    },
    {
        title: 'groupCode4',
        dataIndex: 'groupCode4',
        key: 'groupCode4',
    },
    {
        title: 'lineDiscount',
        dataIndex: 'lineDiscount',
        key: 'lineDiscount',
        render: (_, record) => (
            <>{record.lineDiscount}</>
        ),
    },
    {
        title: 'salesPrice',
        dataIndex: 'salesPrice',
        key: 'salesPrice',
        render: (_, record) => (
            <>{record.salesPrice}</>
        ),
    },
    {
        title: 'stockIdOutOfStock',
        dataIndex: 'stockIdOutOfStock',
        key: 'stockIdOutOfStock',
    },
    {
        title: 'uD1',
        dataIndex: 'uD1',
        key: 'uD1',
    },
    {
        title: 'unitPrice',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (_, record) => (
            <>{record.unitPrice}</>
        ),
    },
    {
        title: 'warehouse',
        dataIndex: 'warehouse',
        key: 'warehouse',
    },
];