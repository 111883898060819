import React from 'react';
import { useEffect, useState } from 'react';
import { GetProductLinkTagCopyable, GetUserLinkTag } from "../../components/getPageLinks";
import { Button, Card, Col, Descriptions, Popconfirm, Row, Space, Table, Tabs, Typography } from "antd";
import { CloseCircleOutlined, QuestionCircleOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { ErrorRow } from "../../components/errorBlock";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { getBackendLink } from "../../components/backend/backend";
import { useParams } from "react-router-dom";
import Moment from 'react-moment';
import { Spinner, SpinnerInline } from "../../components/spinners";
import { GetTagFromValue, ZeroIfEmpty } from "../../components/formattingValues";
import { BreadcrumbsCampaignsCampaign } from "../../components/breadcrumbs/breadcrumbsCampaigns";
import { HTTPDeleteInternalAPI } from "../../components/backend/httpDELETE";
import { Helmet } from "react-helmet";
import { PostRequestInternalAPI } from '../../components/backend/postRequest';
import { TimeFromSeconds } from '../../components/dateAndTime';
import { ProductsForCampaignTable } from '../../subpages/products/campaigns/productsForCampaign';

const { Paragraph } = Typography

export const GetCampaignV2 = (props) => {
    const { id } = useParams();
    const [name, setName] = useState(<SpinnerInline />);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState('');

    const fetchData = () => {
        setLoading(true);
        PostRequestInternalAPI(`/api/v1/products/campaigns/v2`,
            {
                "campaign_id": id,
            },
            setLoading,
            setErr,
            null,
        )
            .then(
                (result) => {
                    if (result.data !== undefined && result.data !== null) {
                        setData(result.data);
                        setName(result.data.Name);
                    }
                }
            )
    };
    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const handleDelete = (key = []) => {
        HTTPDeleteInternalAPI(`/api/v1/products/campaigns/v2/delete`, {
            "campaign_ids": [key],
        },
            setLoading, setErr, null,
        )
            .then(
                () => {
                    setErr("Campaign deleted");
                }
            )
    };

    const [logs, setLogs] = useState([]);
    const [loadingLogs, setLoadingLogs] = useState(true);
    const [errLogs, setErrLogs] = useState('');
    const [logsTableParams, setLogsTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 500,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleLogsTableChange = (pagination, filters, sorter) => {
        setLogsTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== logsTableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setLogs([]);
        }
    };

    const fetchLogs = () => {
        GetRequestInternalAPI(`/api/v1/products/campaigns/v2/get_logs?page=${logsTableParams.pagination.current}&size=${logsTableParams.pagination.pageSize}&cid=${id}&sku=all&author=all&level=0&before=all&after=all`,
            setLoadingLogs,
            setErrLogs,
            null
        )
            .then(
                (result) => {
                    setLogs(result.Records);
                    setLogsTableParams({
                        ...logsTableParams,
                        pagination: {
                            ...logsTableParams.pagination,
                            total: result.Total,
                        },
                    });
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchLogs();
        // eslint-disable-next-line
    }, [JSON.stringify(logsTableParams)]);

    if (err !== "") {
        return (
            <>
                <Helmet>
                    <title>Campaign #{id} || Bangerhead Admin</title>
                </Helmet>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <BreadcrumbsCampaignsCampaign id={id} />
                        </Col>
                    </Row>
                </div>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <Space wrap>
                                <Button href={"/campaigns/v2"}
                                    type="primary"
                                >
                                    Campaign list
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </div>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <ErrorRow err={err} />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    return (
        <>
            <Helmet>
                <title>Campaign #{id} || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsCampaignsCampaign id={name} />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button href={"/campaigns/v2"}
                                type="primary"
                            >
                                Campaign list
                            </Button>
                            <Button href={getBackendLink() + `/api/v1/products/campaigns/v2/get_file?campaign_id=${id}`}
                                type="primary"
                                style={{ backgroundColor: "green" }}
                                disabled={data.NumberOfProducts === undefined || data.NumberOfProducts < 1}
                            >
                                Download file <CloudDownloadOutlined />
                            </Button>
                            {data.NumberOfProducts === undefined || data.NumberOfProducts < 1
                                ?
                                <Button type="dashed" danger
                                    disabled={true}
                                >
                                    <CloseCircleOutlined />
                                </Button>
                                :
                                <Popconfirm
                                    title="Delete campaign"
                                    description="Are you sure to delete this campaign?"
                                    onConfirm={() => handleDelete(data.CampaignID)}
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{
                                                color: 'red',
                                            }}
                                        />
                                    }
                                >
                                    <Button type="dashed" danger>
                                        <CloseCircleOutlined />
                                    </Button>
                                </Popconfirm>
                            }
                        </Space>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Tabs
                            defaultActiveKey="BasicData"
                            items={[
                                {
                                    label: 'Basic campaign data',
                                    key: 'BasicData',
                                    children: loading === true ? <Spinner /> :
                                        <>
                                            <Card
                                                title={<Typography.Title copyable={{ text: data.CampaignID }}
                                                    level={4}
                                                    style={{ margin: 0 }}>{data.CampaignID}</Typography.Title>}
                                                bordered={false}
                                            >
                                                <Descriptions>
                                                    <Descriptions.Item label="ID">
                                                        {<Paragraph copyable={{ text: data.CampaignID }}
                                                            style={{ margin: 0 }}>{data.CampaignID}</Paragraph>}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Name">
                                                        {<Paragraph copyable={{ text: data.Name }}
                                                            style={{ margin: 0 }}>{data.Name}</Paragraph>}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Discount">
                                                        {ZeroIfEmpty(data.DiscountPercent)}%
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Brand">
                                                        {<Paragraph copyable={{ text: data.BrandName }}
                                                            style={{ margin: 0 }}>{data.BrandName} ({data.BrandID})</Paragraph>}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Country">
                                                        {data.MarketName} ({data.MarketID})
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Products">
                                                        {data.NumberOfProducts}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Descriptions>
                                                    <Descriptions.Item label="Starts">
                                                        <TimeFromSeconds seconds={data.StartDate.seconds * 1000} />
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Ends">
                                                        <TimeFromSeconds seconds={data.DueDate.seconds * 1000} />
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label="Created">
                                                        <>
                                                            <TimeFromSeconds seconds={data.CreatedAt.seconds * 1000}
                                                            /> ({GetUserLinkTag(data.CreatedBy, data.CreatedByName)})
                                                        </>
                                                    </Descriptions.Item>
                                                </Descriptions>

                                                {
                                                    data.Categories !== undefined && data.Categories !== ""
                                                        ?
                                                        <Descriptions>
                                                            <Descriptions.Item label="Categories">
                                                                {data.Categories}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        :
                                                        <></>
                                                }

                                            </Card>
                                        </>,
                                },
                                {
                                    label: 'Products',
                                    key: 'Products',
                                    children:
                                        <>
                                            <ProductsForCampaignTable campaignID={id} messageAPI={props.messageAPI} />
                                        </>,
                                },
                                {
                                    label: 'Logs for last 365 days',
                                    key: 'Logs',
                                    children: loadingLogs === true ? <Spinner /> :
                                        <>
                                            {errLogs !== "" ?
                                                <div className="container-1200" style={{ "marginTop": "1em" }}>
                                                    <ErrorRow prefix={"Campaign"} err={errLogs} />
                                                </div> : <></>}
                                            <Table
                                                columns={columnsLogs}
                                                rowKey={(record) => record.product}
                                                dataSource={logs}
                                                pagination={logsTableParams.pagination}
                                                loading={loadingLogs}
                                                size={"small"}
                                                onChange={handleLogsTableChange}
                                            />
                                        </>,
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

const columnsLogs = [
    {
        title: 'Time',
        dataIndex: 'Time',
        key: 'CreatedAt',
        render: (_, record) => (
            <><Moment format="YYYY.MM.DD hh:mm" date={record.CreatedAt} /></>
        ),
    },
    {
        title: 'ProductID',
        dataIndex: 'ProductID',
        key: 'ProductID',
        render: (_, record) => (
            <>{record.ProductID !== "" ? <>{GetProductLinkTagCopyable(record.ProductID)}</> : record.ProductID}</>
        ),
    },
    {
        title: 'Level',
        dataIndex: 'LogLevelName',
        key: 'LogLevelName',
        render: (_, record) => (
            <>{GetTagFromValue(record.LogLevelName)}</>
        ),
    },
    {
        title: 'Text',
        dataIndex: 'Text',
        key: 'Text',
    },
    {
        title: 'Author',
        dataIndex: 'Author',
        key: 'Author',
        render: (_, record) => (
            <>{record.AuthorName === "" ? <>Unknown</> : GetUserLinkTag(record.Author, record.AuthorName)}</>
        ),
    },
];