import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";

export function BreadcrumbsMain() {
    return (
        <Breadcrumb
            items={[
                {
                    title: (
                        <>
                            <HomeOutlined />
                        </>
                    ),
                },
            ]}
        />
    )
}