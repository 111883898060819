import React from 'react';
import { Button, Col, Form, Popconfirm, Row, Space, Table, Tour } from "antd";
import { ErrorRow } from "../../components/errorBlock";
import { useEffect, useState, useRef } from 'react';
import { PostRequestInternalAPI } from "../../components/backend/postRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import { GetUserLinkTag } from "../../components/getPageLinks";
import { QuestionCircleOutlined, EyeOutlined, CloseCircleOutlined, CloudDownloadOutlined, MonitorOutlined } from "@ant-design/icons";
import { BreadcrumbsCampaigns } from "../../components/breadcrumbs/breadcrumbsCampaigns";
import { HTTPDelete } from "../../components/backend/httpDELETE";
import { Helmet } from "react-helmet";
import { TimeFromSeconds } from '../../components/dateAndTime';
import { CountriesSelect } from '../../subpages/products/countries';
import { BrandsSelect } from "../../subpages/products/brands";

export const CampaignsV2 = () => {
    const refAddNew = useRef(null);
    const refDelSelected = useRef(null);
    const [open, setOpen] = useState(false);

    const tourSteps = [
        {
            title: 'Create new campaign',
            description: 'Click to open new campaign creation page',
            target: () => refAddNew.current,
        },
        {
            title: 'Actions',
            description: 'See, download or delete existing campaign',
            cover: (
                <img
                    style={{ height: "50%", width: "50%" }}
                    alt="tour.png"
                    src="/img/tours/campaign_creation/1.png"
                />
            ),
            target: "",
            placement: "center",
        },
        {
            title: 'Delete multiple',
            description: 'Select & delete multiple campaigns at once',
            target: () => refDelSelected.current,
        },
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: 'Products',
            dataIndex: 'NumberOfProducts',
            key: 'NumberOfProducts',
        },
        {
            title: 'Discount',
            dataIndex: 'DiscountPercent',
            key: 'DiscountPercent',
            render: (_, record) => (
                <>{record.DiscountPercent !== undefined ? record.DiscountPercent : 0}%</>
            ),
        },
        {
            title: 'CreatedBy',
            dataIndex: 'CreatedBy',
            key: 'CreatedBy',
            render: (_, record) => (
                <>{record.CreatedByName !== "" ? GetUserLinkTag(record.CreatedBy, record.CreatedByName) : GetUserLinkTag(record.CreatedBy, "Unknown")}</>
            ),
        },
        {
            title: 'Created',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.CreatedAt.seconds * 1000} /></>
            ),
        },
        {
            title: 'Brand',
            dataIndex: 'BrandName',
            key: 'BrandName',
            render: (_, record) => (
                <>{record.BrandName} ({record.BrandID})</>
            ),
        },
        {
            title: 'Market',
            dataIndex: 'MarketName',
            key: 'MarketName',
        },
        {
            title: 'Categories',
            dataIndex: 'Categories',
            key: 'Categories',
        },
        {
            title: 'Start',
            dataIndex: 'StartDate',
            key: 'StartDate',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.StartDate.seconds * 1000} /></>
            ),
        },
        {
            title: 'Due',
            dataIndex: 'DueDate',
            key: 'DueDate',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.DueDate.seconds * 1000} /></>
            ),
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                data.length >= 1 ? (
                    <>
                        <Space wrap>
                            <Button type="primary" href={`/campaigns/v2/${record.CampaignID}`} target={"_blank"}
                                rel={"noreferrer"}>
                                <EyeOutlined />
                            </Button>
                            <Button href={getBackendLink() + `/api/v1/products/campaigns/v2/get_file?campaign_id=${record.CampaignID}`}
                                type="primary"
                                style={{ backgroundColor: "green" }}
                            >
                                <CloudDownloadOutlined />
                            </Button>
                            <Popconfirm
                                title="Delete row"
                                description="Are you sure to delete this record?"
                                onConfirm={() => handleDelete([record.CampaignID])}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                            >
                                <Button type="dashed" danger>
                                    <CloseCircleOutlined />
                                </Button>
                            </Popconfirm>
                        </Space>
                    </>
                ) : null,
        },
    ];

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [brand, setBrand] = useState(0);
    const [market, setMarket] = useState(0);

    const fetchData = () => {
        PostRequestInternalAPI(`/api/v1/products/campaigns/v2/list`,
            {
                "page": tableParams.pagination.current,
                "size": tableParams.pagination.pageSize,
                "brand_id": brand,
                "market_id": market,

                // StartDate api.DateOnly `json:"start_date"`
                // DueDate   api.DateOnly `json:"due_date"`
                // BrandID   int32        `json:"brand_id"`
                // MarketID  int32        `json:"market_id"`
                // ItemSKU   string       `json:"item_sku"`
            },
            setLoading,
            setErr,
            null,
        )
            .then(
                (result) => {
                    setData(result.records);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: result.total,
                        },
                    });
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleDelete = (key = []) => {
        setLoading(true)
        HTTPDelete(getBackendLink() + `/api/v1/products/campaigns/v2/delete`, {
            "campaign_ids": key,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Deleted!')
                    let newData = data

                    if (Array.isArray(key)) {
                        key.forEach((element) => {
                            newData = newData.filter((item) => item.CampaignID !== element);
                        }
                        );
                    } else {
                        newData = newData.filter((item) => item.CampaignID !== key);
                    }
                    setData(newData);
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
        setLoading(false)
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const start = () => {
        handleDelete(selectedRowKeys)
        setSelectedRowKeys([]);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <>
            <Helmet>
                <title>Campaigns || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsCampaigns />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <ErrorRow err={err} prefix={"Campaigns"} />
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpen(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                Take a Tour <QuestionCircleOutlined />
                            </Button>
                            <Button href={"/campaigns/v2/create"}
                                type="primary"
                                ref={refAddNew}
                            >
                                Add new
                            </Button>
                            <Popconfirm
                                title="Delete selected"
                                description="Are you sure to delete these records?"
                                onConfirm={start}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                            >
                                <Button
                                    disabled={!hasSelected}
                                    type="dashed"
                                    danger
                                    ref={refDelSelected}
                                >
                                    Delete selected
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Button type="primary" target={"_blank"}
                            rel={"noreferrer"}
                            onClick={fetchData}
                        >
                            Get logs <MonitorOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">

                <Row>
                    <Col xs={24} xl={24}>

                        <Form.Item
                            name={`Market`}
                            label={`Market`}
                        >
                            <CountriesSelect onChange={setMarket} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>

                        <Form.Item
                            name={`Brand`}
                            label={`Brand`}
                        >
                            <BrandsSelect onChange={setBrand} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            dataSource={data}
                            columns={columns}
                            loading={loading}
                            pagination={tableParams.pagination}
                            rowKey={(record) => record.CampaignID}
                            size={"small"}
                            onChange={handleTableChange}
                            rowSelection={rowSelection}
                        />
                    </Col>
                </Row>
                <Tour open={open} onClose={() => setOpen(false)} steps={tourSteps} />
            </div>
        </>
    );
};