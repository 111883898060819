import { SubscriptionsCheckList } from "../../components/notificationsList";
import { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { PostRequestInternalAPI } from "../../components/backend/postRequest";
import { ErrorRow } from "../../components/errorBlock";
import React from "react";
import { Spinner } from "../../components/spinners";

export const SettingsNotifications = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const onChange = (e) => {
        if (e.target.checked === false) {
            let newData = []

            data.forEach((item) => {
                if (e.target.Source !== item.Source || e.target.Priority !== item.Priority) {
                    newData.push(item)
                }
            }
            );

            setData(newData)
        } else {
            let newData = []

            if (data !== null && data.length > 0) {
                newData = [...data]
            }

            newData.push({
                Source: e.target.Source,
                Priority: e.target.Priority,
            })

            setData(newData)
        }

        let url = ""
        if (e.target.checked === true) {
            url = `/api/v1/notifications/subscriptions/self/subscribe`
        }
        if (e.target.checked === false) {
            url = `/api/v1/notifications/subscriptions/self/unsubscribe`
        }

        PostRequestInternalAPI(url, {
            Source: e.target.Source,
            Priority: e.target.Priority,
        },
            null,
            null,
            null,
            props.messageAPI,
            {
                message: "Saving",
            }
        )
            .then(
        )
    };

    const fetchData = () => {
        GetRequestInternalAPI(
            `/api/v1/notifications/subscriptions/self/list/json`,
            setLoading,
            setErr,
            null
        ).then(
            (result = { Total: 0, Records: [] }) => {
                setData(result.Records)
            }
        )
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, []);

    if (err !== "") {
        return <ErrorRow prefix={"Notification subscriptions"} err={err} />
    }

    if (loading === true) {
        return <Spinner />
    }

    return (
        <>
            <SubscriptionsCheckList onChange={onChange} userSubscriptions={data}
                allChecksDisabled={loading} />
        </>
    )
}