import React from "react";
import { getBackendLink } from "../backend/backend";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

// ButtonDownloadCSV sets link from props.link and renders as disabled on props.disabled = true
export function ButtonDownloadCSV(props) {
    return (
        <Button href={getBackendLink() + props.link} type="primary" disabled={props.disabled}
            style={
                { backgroundColor: "green" }
            }
        >
            Download CSV <CloudDownloadOutlined />
        </Button>
    )
}
