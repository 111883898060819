import { GetRequestInternalAPI } from "./getRequest";
import { PostRequestInternalAPI } from "./postRequest";

export function GetBrands(setLoading, setErr, setData) {
    return GetRequestInternalAPI(
        `/api/v1/products/brands?page=${0}&size=${0}&ids=''`,
        setLoading,
        setErr,
        setData,
    )
}

export function GetCountries(setLoading, setErr, setData) {
    return GetRequestInternalAPI(
        `/api/v1/products/countries?page=${0}&size=${0}&ids=''`,
        setLoading,
        setErr,
        setData,
    )
}

export function GetCategories(
    page, size, ids,
    setLoading,
    setErr,
    setData,
) {
    return PostRequestInternalAPI(
        `/api/v1/products/categories`,
        {
            page: page,
            size: size,
            ids: ids
        },
        setLoading,
        setErr,
        setData,
    )
}