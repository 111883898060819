import { Select, Tag } from "antd";

export const GetEventCategories = (props) => {
    return (
        <Select
            onChange={(value) => {
                props.setCategory(value)
            }}
            options={categories}
            fieldNames={{ label: "Name", value: "ID" }}
        >
        </Select>
    )
}

export const GetEventSubCategories = (props) => {
    return (
        <Select
            onChange={(value) => {
                props.setSubCategory(value)
            }}
            options={subCategories}
            fieldNames={{ label: "Name", value: "ID" }}
        >
        </Select>
    )
}

const categories = [
    { ID: "false_positive", Name: "False positive", tag: <Tag color="#52c41a">False positive</Tag> },
    { ID: "long_processing_time", Name: "Long processing time", tag: <Tag color="orange">Long processing time</Tag> },
    { ID: "external_service_error", Name: "External service error", tag: <Tag color="#f50">External service error</Tag> },
    { ID: "internal_service_error", Name: "Internal service error", tag: <Tag color="#f50">Internal service error</Tag> },
    { ID: "integration_error", Name: "Integration error", tag: <Tag color="#f50">Integration error</Tag> },
    { ID: "bug", Name: "Bug", tag: <Tag color="#f50">Bug</Tag> },
    { ID: "dev_debug", Name: "Developer debug", tag: <Tag color="#52c41a">Developer debug</Tag> },
]

const subCategories = [
    { ID: "microsoft", Name: "Microsoft", tag: <Tag color="#f50">Microsoft</Tag> },
    { ID: "more_flo", Name: "MoreFlo", tag: <Tag color="#f50">MoreFlo</Tag> },
    { ID: "slim4", Name: "Slim4", tag: <Tag color="#f50">Slim4</Tag> },
    { ID: "sql", Name: "SQL", tag: <Tag color="cyan">SQL</Tag> },
    { ID: "api", Name: "API", tag: <Tag color="#2db7f5">API</Tag> },
]

export const TranslateCategory = (category) => {
    if (!category) {
        return "";
    }

    const categoryObj = categories.find(item => item.ID === category);

    if (!categoryObj) {
        return "";
    }

    return categoryObj.tag;
};


export const TranslateSubCategory = (subCategory) => {
    if (!subCategory) {
        return "";
    }

    const subCategoryObj = subCategories.find(item => item.ID === subCategory);

    if (!subCategoryObj) {
        return "";
    }

    return subCategoryObj.tag;
};