import { notification } from "antd";

export function NotificationSuccess(message, description) {
    notification.config({
        placement: "bottomRight"
    })
    notification.success({
        message: message,
        description: description,
        duration: 5
    });
}

export function NotificationError(message, description) {
    notification.config({
        placement: "bottomRight"
    })
    notification.error({
        message: message,
        description: description,
        duration: 5
    });
}

export function NotificationInfo(message, description) {
    notification.config({
        placement: "bottomRight"
    })
    notification.info({
        message: message,
        description: description,
        duration: 5
    });
}