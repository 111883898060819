import React from "react";
import { Col, Row, Table } from "antd";
import { BreadcrumbsDeveloperHealthMonitorIndexes } from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "../../../components/backend/getRequest";
import { ErrorRow } from "../../../components/errorBlock";
import { HandleTableChange } from "../../../components/tables/tableFunctions";
import { Helmet } from "react-helmet";

export const DeveloperHealthmonitorIndexes = () => {
    const [indexesError, setIndexesError] = useState("");
    const [indexesFetching, setIndexesFetching] = useState(true);
    const [indexesData, setIndexesData] = useState([]);
    const [indexesTableParams, setIndexesTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const handleIndexesTableChange = (pagination, filters, sorter) => {
        HandleTableChange(
            pagination,
            filters,
            sorter,
            indexesTableParams,
            setIndexesTableParams,
            setIndexesData,
        )
    }

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/statistic/healthmonitor/indexes/json?page=${indexesTableParams.pagination.current}&size=${indexesTableParams.pagination.pageSize}`,
            setIndexesFetching,
            setIndexesError,
            setIndexesData
        ).then(
            (result) => {
                let newIndexesFilter = []
                Object.entries(result.records).map((t, k, m) => newIndexesFilter.push({
                    id: m[k][0],
                    name: m[k][0],
                })
                )
            }
        )
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Dev: SQL indexes || Bangerhead Admin</title>
            </Helmet>
            <ErrorRow err={indexesError} prefix={"Tables sizes"} />
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperHealthMonitorIndexes />
                        <Table
                            columns={columnsIndexes}
                            rowKey={(record) => record.id}
                            dataSource={indexesData.records}
                            pagination={indexesTableParams.pagination}
                            loading={indexesFetching}
                            size={"small"}
                            onChange={handleIndexesTableChange}
                            scroll={{ x: 'max-content' }}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

const columnsIndexes = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Database',
        dataIndex: 'database',
        key: 'database',
    },
    {
        title: 'Table',
        dataIndex: 'table',
        key: 'table',
    },
    {
        title: 'Index',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'Fragmentation',
        dataIndex: 'avg_fragmentation_in_percent',
        key: 'avg_fragmentation_in_percent',
        render(_, record) {
            let fragmentation = parseFloat(record.avg_fragmentation_in_percent).toFixed(2)
            let hue = 100 - Math.trunc(fragmentation)
            let color = "hsl(" + hue + ", 100%, 70%)"
            return {
                props: {
                    style: {
                        background: color,
                    }
                },
                children: <div>{fragmentation}%</div>
            };
        },
        sorter: (a, b) => {
            if (a.avg_fragmentation_in_percent < b.avg_fragmentation_in_percent) {
                return -1;
            }
            if (a.avg_fragmentation_in_percent > b.avg_fragmentation_in_percent) {
                return 1;
            }

            return 0;
        }
    },
    {
        title: 'PagesCount',
        dataIndex: 'page_count',
        key: 'page_count',
        sorter: (a, b) => {
            if (a.page_count < b.page_count) {
                return -1;
            }
            if (a.page_count > b.page_count) {
                return 1;
            }

            return 0;
        }
    },
]