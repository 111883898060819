import React from "react";
import { Col, Row, Table } from "antd";
import { BreadcrumbsDeveloperHealthMonitorTablesizes } from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "../../../components/backend/getRequest";
import { ErrorRow } from "../../../components/errorBlock";
import { HandleTableChange } from "../../../components/tables/tableFunctions";
import { Helmet } from "react-helmet";

export const DeveloperHealthmonitorTablesizes = () => {
    const [tablesizesError, setTablesizesError] = useState("");
    const [tablesizesFetching, setTablesizesFetching] = useState(true);
    const [tablesizesData, setTablesizesData] = useState([]);
    const [tablesizesTableParams, setTablesizesTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const handleTablesizesTableChange = (pagination, filters, sorter) => {
        HandleTableChange(
            pagination,
            filters,
            sorter,
            tablesizesTableParams,
            setTablesizesTableParams,
            setTablesizesData,
        )
    }

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/statistic/healthmonitor/tablesizes/json?page=${tablesizesTableParams.pagination.current}&size=${tablesizesTableParams.pagination.pageSize}`,
            setTablesizesFetching,
            setTablesizesError,
            setTablesizesData
        ).then(
            (result) => {
                let newTablesizesFilter = []
                Object.entries(result.records).map((t, k, m) => newTablesizesFilter.push({
                    id: m[k][0],
                    name: m[k][0],
                })
                )
            }
        )
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Dev: SQL table sizes || Bangerhead Admin</title>
            </Helmet>
            <ErrorRow err={tablesizesError} prefix={"Tables sizes"} />
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperHealthMonitorTablesizes />
                        <Table
                            columns={columnsTablesizes}
                            rowKey={(record) => record.id}
                            dataSource={tablesizesData.records}
                            pagination={tablesizesTableParams.pagination}
                            loading={tablesizesFetching}
                            size={"small"}
                            onChange={handleTablesizesTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

const columnsTablesizes = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Database',
        dataIndex: 'db_name',
        key: 'db_name',
    },
    {
        title: 'Table',
        dataIndex: 'table_name',
        key: 'table_name',
    },
    {
        title: 'Rows',
        dataIndex: 'row_count',
        key: 'row_count',
        sorter: (a, b) => {
            if (Number(a.row_count) < Number(b.row_count)) {
                return -1;
            }
            if (Number(a.row_count) > Number(b.row_count)) {
                return 1;
            }

            return 0;
        }
    },
    {
        title: 'Reserved size (kb)',
        dataIndex: 'reserved_size_kbytes',
        key: 'reserved_size_kbytes',
        sorter: (a, b) => {
            if (Number(a.reserved_size_kbytes) < Number(b.reserved_size_kbytes)) {
                return -1;
            }
            if (Number(a.reserved_size_kbytes) > Number(b.reserved_size_kbytes)) {
                return 1;
            }

            return 0;
        }
    },
    {
        title: 'Used size (kb)',
        dataIndex: 'used_size_kbytes',
        key: 'used_size_kbytes',
        sorter: (a, b) => {
            if (Number(a.used_size_kbytes) < Number(b.used_size_kbytes)) {
                return -1;
            }
            if (Number(a.used_size_kbytes) > Number(b.used_size_kbytes)) {
                return 1;
            }

            return 0;
        }
    },
]