import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { GetTagFromValue } from "../../components/formattingValues";
import { GetAppLink, GetUserLink } from "../../components/getPageLinks";
import { ErrorRow } from "../../components/errorBlock";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const RequestLogsListTable = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const fetchData = () => {
        GetRequestInternalAPI(
            `/api/v1/logs/requests_get_list/json?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}` +
            `&min_request_size=${props.minRequestSize}` +
            `&min_response_size=${props.minResponseSize}` +
            `&min_duration=${props.minDuration}` +
            `&response_code=${props.responseRode}` +
            `&proto=` +
            `&method=${props.method}` +
            `&remote_addr=${props.remoteAddr}` +
            `&endpoint=${props.endpoint}` +
            `&user_id=${props.userID}` +
            `&session_id=${props.sessionID}` +
            `&before=${props.before}&after=${props.after}`,
            setLoadingData,
            setErr,
            null
        ).then(
            (result = { total: 0, records: [] }) => {
                setData(result.records)
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: result.total,
                    },
                });
                if (props.setTotal !== null && props.setTotal !== undefined) {
                    props.setTotal(result.total)
                }
            }
        )
    };

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [tableParams.pagination.pageSize, tableParams.pagination.current, props.refreshData]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    const columns = [
        {
            title: 'Time',
            dataIndex: 'Time',
            key: 'Time',
            render: (_, record) => (
                <>
                    <TimeFromSeconds seconds={record.RequestTime.seconds * 1000} /> - <TimeFromSeconds
                        seconds={record.ResponseTime.seconds * 1000} /> ({record.Duration} ms)
                </>
            ),
        },
        {
            title: 'Request',
            dataIndex: 'Request',
            key: 'Request',
            render: (_, record) => (
                <>
                    {GetTagFromValue(record.Method)} from <Tag>{record.RemoteAddr}</Tag> to {record.EndpointID ?
                        <a target={"_blank"} rel={"noreferrer"}
                            href={`/developer/endpoints/${record.EndpointID}`}>{record.Endpoint}</a>
                        : <>{record.Endpoint}</>} {record.RequestSize > 0 ? <>({record.RequestSize})
                            bytes</> : <></>}
                </>
            ),
        },
        {
            title: 'User',
            dataIndex: 'User',
            key: 'User',
            render: (_, record) => (
                <>{record.UserID !== "" ? record.UserName === "" ? <><a
                    href={GetUserLink(record.UserID)}>{record.UserID}</a></> :
                    <a href={GetUserLink(record.UserID)}>{record.UserName}</a> : record.IntegrationID !== "" ? record.IntegrationName === "" ? <>
                        <a href={GetUserLink(record.UserID)}>{record.UserID}</a></> :
                        <a href={GetAppLink(record.IntegrationID)}>{record.IntegrationName}</a> : <></>
                }
                    {props.currentSessionID === record.SessionID ? <Tag color={'lime'}>Current session</Tag> : <></>}
                </>
            ),
        },
        {
            title: 'Response',
            dataIndex: 'Response',
            key: 'Response',
            render: (_, record) => (
                <>{record.Success ? <Tag icon={<CheckCircleOutlined />} color="success">OK</Tag> : <Tag icon={<CloseCircleOutlined />} color="error">FAIL</Tag>}
                    {record.ResponseCode} / {record.ResponseSize} bytes
                    / {record.ResponseDescription}</>
            ),
        },
    ]

    if (err !== "") {
        return <ErrorRow prefix={"Request logs"} err={err} />
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loadingData}
                size={"small"}
                onChange={handleTableChange}
            />
        </>
    )
}
