import { Breadcrumb } from "antd";
import { pages } from "./breadcrumbs";
import {
    ClusterOutlined
} from '@ant-design/icons'
import React from "react";
import { SpinnerInline } from "../spinners";

export function BreadcrumbsLogs() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["stat"],
                {
                    title: (
                        <>
                            <span>Logs</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsEvents() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["stat"],
                {
                    title: (
                        <>
                            <span>Events</span>
                        </>
                    ),
                },
            ]}
        />
    )
}


export function BreadcrumbsEventsEvent(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["stat"],
                pages["stat_events"],
                {
                    title: (
                        <>
                            <span>{props.loading ? <SpinnerInline /> : <>Event #{props.id} from {props.time}</>}</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsRequestLogs() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["stat"],
                {
                    title: (
                        <>
                            <ClusterOutlined />
                            <span>Request logs</span>
                        </>
                    ),
                },
            ]}
        />
    )
}