import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Button, Col, Row, Tag, Typography } from "antd";
import { BreadcrumbsAdminUsersSessionsSession } from "../../components/breadcrumbs/breadcrumbsAdmin";
import { SpinnerInline } from "../../components/spinners";
import { RequestLogsListTable } from "../../subpages/stat/logRequests";
import { GetDefaultBefore } from "../../components/dateAndTime";
import { SessionData } from "../../subpages/admin/sessionData";
import { MonitorOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

const { Title } = Typography;

export const AdminSessionsSession = () => {
    const { id } = useParams();
    const [crumbsName, setCrumbsName] = useState(<SpinnerInline />);
    const [total, setTotal] = useState(0);
    const [refreshData, setRefreshData] = useState(0);
    // eslint-disable-next-line
    const [before, setBefore] = useState(GetDefaultBefore());

    return (
        <>
            <Helmet>
                <title>{`Session #${id}`} || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdminUsersSessionsSession id={crumbsName} />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <SessionData id={id} setCrumbsName={setCrumbsName} />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Title className={"no-margin"} level={3}>Request log</Title>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        Total: <Tag color={"purple"}>{total}</Tag>
                        <Button type="primary" target={"_blank"}
                            rel={"noreferrer"}
                            onClick={() => {
                                setRefreshData(refreshData + 1)
                            }}
                        >
                            Refresh logs <MonitorOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <RequestLogsListTable
                            currentSessionID={""}
                            minRequestSize={0}
                            minResponseSize={0}
                            minDuration={0}
                            responseRode={0}
                            method={""}
                            remoteAddr={""}
                            endpoint={""}
                            userID={""}
                            sessionID={id}
                            before={before}
                            after={"1970-01-01"}
                            setTotal={setTotal}
                            refreshData={refreshData}

                            size={"small"}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}
