import { ClockCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import { GetRequest } from "../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../components/backend/backend";
import { NotificationError } from "../../../components/notifications";
import { ErrorRow } from "../../../components/errorBlock";
import { Spinner } from "../../../components/spinners";
import { GetAppLink } from "../../../components/getPageLinks";
import { Button, Result, Typography } from 'antd';
import { BreadcrumbsDeveloper } from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { TimeFromSeconds } from "../../../components/dateAndTime";
import { Helmet } from "react-helmet";

const { Paragraph, Text } = Typography;

export const DeveloperMain = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState({
        1: {},
        2: {},
        3: {},
        4: {},
    });
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/apps/statuses_last_get`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <title>Developer root || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloper />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <ErrorRow err={err} prefix={"Products"} />
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={12}>
                        <GetStatusBlock loading={loading} data={data[4]} />
                    </Col>
                    <Col xs={24} xl={12}>
                        <GetStatusBlock loading={loading} data={data[2]} />
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={12}>
                        <GetStatusBlock loading={loading} data={data[1]} />
                    </Col>
                    <Col xs={24} xl={12}>
                        <GetStatusBlock loading={loading} data={data[3]} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

function GetStatusBlock(props) {
    if (props.loading === undefined || props.loading === null || props.data === undefined || props.data === null) {
        return <>N/A</>
    }
    if (props.loading) {
        return <Card title={`${props.data.AppName} (${props.data.AppComment})`}
            extra={<a target={"_blank"} rel={"noreferrer"} href={GetAppLink(props.data.AppID)}>More</a>}>
            <Spinner />
        </Card>
    }
    if (props.data.AppStatusID === 0) {
        return (
            <Card title={`${props.data.AppName} (${props.data.AppComment})`}
                extra={<a target={"_blank"} rel={"noreferrer"} href={GetAppLink(props.data.AppID)}>More</a>}>
                <Result
                    status="warning"
                    title="No records"
                    extra={
                        <Button type="primary" key="console" href={GetAppLink(props.data.AppID)}>
                            Check page
                        </Button>
                    }
                />
            </Card>
        )
    }
    if (props.data.AppStatusID === 1 || props.data.AppStatusID === 3) {
        return (
            <Card title={`${props.data.AppName} (${props.data.AppComment})`}
                extra={<a target={"_blank"} rel={"noreferrer"} href={GetAppLink(props.data.AppID)}>More</a>}>
                <Result
                    status="success"
                    title="Working"
                >
                    <div className="desc">
                        <Paragraph>
                            <Text
                                strong
                                style={{
                                    fontSize: 16,
                                }}
                            >
                                Status:
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <ClockCircleOutlined className="site-result-demo-error-icon" /> Last
                            activity: {props.data.LastActivity.Valid ?
                                <TimeFromSeconds seconds={props.data.LastActivity.Time} /> : <>Unknown</>}
                        </Paragraph>
                        <Paragraph>
                            <CheckCircleOutlined className="site-result-demo-error-icon" /> Last
                            result: {props.data.Result}
                        </Paragraph>
                    </div>
                </Result>
            </Card>
        )
    }
    if (props.data.AppStatusID === 2 || props.data.AppStatusID === 4) {
        return (
            <Card title={`${props.data.AppName} (${props.data.AppComment})`}
                extra={<a target={"_blank"} rel={"noreferrer"} href={GetAppLink(props.data.AppID)}>More</a>}>
                <Result
                    status="warning"
                    title="Error"
                    extra={
                        <Button type="primary" key="console" href={GetAppLink(props.data.AppID)}>
                            Check page
                        </Button>
                    }
                />
            </Card>
        )
    }
}