import React from 'react';
import { Alert } from 'antd';
import { errorTranslation } from "./backend/backend";

export function ErrorRow(props) {
    if (props.err !== "" && props.err !== undefined) {
        let error = errorTranslation(props.err)
        if (props.prefix !== "" && props.prefix !== undefined) {
            error = props.prefix + ": " + error
        }
        return (
            <ErrorBlock error={error} />
        )
    }

    return (
        <>
        </>
    )
}

export function ErrorBlock(props) {
    return (
        <Alert
            message="Error"
            description={props.error}
            type="error"
            closable
            style={{ backgroundColor: "#ff00001a", borderRadius: 0, margin: "1em 0 1em 0" }}
        />
    )
}