import { Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const SpinProps = {
    indicator:
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />

}

export function Spinner(props) {
    let tip = "Loading"
    if (props.tip !== undefined) {
        tip = props.tip
    }

    return (
        <Row className="container-1700" style={{ backgroundColor: "transparent" }}>
            <Col xl={24} xs={24}>
                <Spin tip={tip} size="large" style={{ margin: "auto", width: "100%" }}
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 24,
                            }}
                            spin
                        />
                    }
                />
            </Col>
        </Row>

    )
}


export function SpinnerInline(props) {
    let tip = "Loading"
    if (props.tip !== undefined) {
        tip = props.tip
    }

    return (
        <><LoadingOutlined spin /> {tip}</>
    )
}