import { Button, Result } from 'antd';
import { Col, Row } from 'antd';
import { Helmet } from "react-helmet";
import React from "react";

export function MustChangePassword() {
    return (
        <>
            <Helmet>
                <title>Warning || Bangerhead Admin</title>
            </Helmet>
            <Row style={{ "marginTop": "1em" }}>
                <Col span={8} offset={8}>
                    <Result
                        subTitle="You must change password before proceed"
                        extra={<Button href={"/profile/edit"} type="primary">Go to Settings</Button>}
                    />
                </Col>
            </Row>
        </>
    )
}