import { Alert, Button, Card, Col, Row, Space, Table, Tag } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import React from 'react';
import { useEffect, useState } from "react";
import { GetRequest } from "../../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../../components/backend/backend";
import { NotificationError } from "../../../../components/notifications";
import { ErrorRow } from "../../../../components/errorBlock";
import { Spinner } from "../../../../components/spinners";
import {
    BreadcrumbsDeveloperProductsFeedsV2
} from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import { Helmet } from "react-helmet";

const duration = require('format-duration')

export const DeveloperProductFeedsV2 = () => {
    const [err, setErr] = useState("");
    const [total, setTotal] = useState(0);
    const [data, setData] = useState({
        "PriceIndex": [],
        "PriceShape": [],
        "PriceSpy": [],
        "Google": [],
        "Facebook": [],
        "Kelkoo": [],
        "Vergelijk": [],
        "Vertaa": [],
        "Voyado": [],
        "VoyadoAggregated": [],
        "Pricerunner": [],
        "Infobaleen": [],
        "Gamifiera user feed": [],
        "Precis": [],
        "Beslist": [],
    });
    const [loading, setLoading] = useState(false);

    const getDefaultBefore = () => {
        const beforeParsed = new Date(Date.now());
        beforeParsed.setUTCDate(beforeParsed.getUTCDate() + 1)
        const beforeYear = beforeParsed.toLocaleString('default', { year: 'numeric' });
        const beforeMonth = beforeParsed.toLocaleString('default', { month: '2-digit', });
        const beforeDay = beforeParsed.toLocaleString('default', { day: '2-digit' });
        const beforeText = [beforeYear, beforeMonth, beforeDay].join('-');

        return beforeText
    }

    const getDefaultAfter = () => {
        const afterParsed = new Date(Date.now());
        afterParsed.setUTCDate(afterParsed.getUTCDate() - 1)
        const afterYear = afterParsed.toLocaleString('default', { year: 'numeric' });
        const afterMonth = afterParsed.toLocaleString('default', { month: '2-digit', });
        const afterDay = afterParsed.toLocaleString('default', { day: '2-digit' });
        const afterText = [afterYear, afterMonth, afterDay].join('-');

        return afterText
    }

    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/feeds/v2?before=${getDefaultBefore()}&after=${getDefaultAfter()}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTotal(result.result.Total)
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Dev: product feeds v2 || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperProductsFeedsV2 />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button type="primary">
                                <FileTextOutlined /> <a
                                    href={`https://app.clickup.com/4575209/v/dc/4bkz9-15921/4bkz9-8041`} target={"_blank"}
                                    rel="noreferrer">Documentation</a>
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em", marginBottom: 16, }}>
                    <Col xs={24} xl={24}>
                        <Alert type="warning" showIcon message="Important" description={
                            <>
                                <p>
                                    Numbers here shown for today and yesterday combined. Detailed logs can be found <a
                                        href={"/developer/products/feeds/v2/logs"} target={"_blank"}
                                        rel={"noreferrer"}>here</a>.
                                </p>
                                <p>{loading ? <><Spinner /></> : <>Total feeds {total}.</>}</p>
                            </>
                        } />
                    </Col>
                </Row>
                <ErrorRow err={err} prefix={"Products"} />
            </div>
            <div className="container-1800" style={{ "marginTop": "1em" }}>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="PriceIndex: XML" extra={<a href="/">More</a>}>
                            <Alert type="info" showIcon message="Important" description={
                                <>
                                    PriceIndex serves not just a specified market data, but all markets at once.
                                    <br />
                                    Plus brands & product categories list.Brands & categories are taken with
                                    separate scanners from same Redis cache and sent to separate arrays in XML-tree
                                    (brands & categories)
                                </>
                            } />
                            <FeedTable dataSource={data["PriceIndex"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="PriceShape: XML" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["PriceShape"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Pricespy: XML & CSV" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["PriceSpy"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Google: XML" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Google"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Facebook: CSV" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Facebook"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Kelkoo: CSV" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Kelkoo"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Vergelijk: XML" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Vergelijk"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Vertaa: XML" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Vertaa"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Voyado: XML" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Voyado"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Voyado Aggregated: XML" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["VoyadoAggregated"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Pricerunner: CSV" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Pricerunner"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Infobaleen: CSV" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Infobaleen"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Gamifiera user feed: JSON" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Gamifiera user feed"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Precis + fallback: CSV" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Precis"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Card title="Beslist: XML" extra={<a href="/">More</a>}>
                            <FeedTable dataSource={data["Beslist"]} loading={loading} />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

function FeedTable(props) {
    return <Table
        pagination={false}
        columns={columns}
        loading={props.loading}
        dataSource={props.dataSource}
        size={"small"}
    />
}


const columns = [
    {
        title: 'Market',
        dataIndex: 'Market',
        key: 'Market',
        render: (_, record) => (
            record.Market === "ALL" ? <Tag color={'volcano'}>{record.Market}</Tag> : record.Market
        ),
    },
    {
        title: 'Format',
        dataIndex: 'Format',
        key: 'Format',
        render: (_, record) => (
            record.Format === "CSV" ? <Tag color={'lime'}>{record.Format}</Tag> :
                <Tag color={'cyan'}>{record.Format}</Tag>
        ),
    },
    {
        title: 'AVG time',
        dataIndex: 'Avgtime',
        key: 'Avgtime',
        render: (_, record) => (
            duration(record.Avgtime * 1000)
        ),
    },
    {
        title: 'AVG prods',
        dataIndex: 'Avgproducts',
        key: 'Avgproducts',
    },
    {
        title: 'MAX time',
        dataIndex: 'Maxtime',
        key: 'Maxtime',
        render: (_, record) => (
            duration(record.Maxtime * 1000)
        ),
    },
    {
        title: 'MAX prods',
        dataIndex: 'Maxproducts',
        key: 'Maxproducts',
    },
    {
        title: 'Production link',
        dataIndex: 'LinkProduction',
        key: 'LinkProduction',
        render: (_, record) => (
            <a href={record.LinkProduction} target={"_blank"} rel={"noreferrer"}>{record.LinkProduction}</a>
        ),
    },
    {
        title: 'Developing link',
        dataIndex: 'LinkDeveloping',
        key: 'LinkDeveloping',
        render: (_, record) => (
            <a href={record.LinkDeveloping} target={"_blank"} rel={"noreferrer"}>{record.LinkDeveloping}</a>
        ),
    },
    {
        title: 'Comment',
        dataIndex: 'Comment',
        key: 'Comment',
    },
];