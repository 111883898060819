import React, { useEffect, useState } from "react";
import { Button, Col, Descriptions, Popconfirm, Row, Space, Typography } from "antd";
import { ErrorRow } from "../../../../components/errorBlock";
import { TimeFromSeconds } from "../../../../components/dateAndTime";
import {
    GetApplicationRunHref, GetApplicationRunLink,
} from "../../../../components/getPageLinks";
import { GetRequestInternalAPI } from "../../../../components/backend/getRequest";
import { BreadcrumbsDeveloperServicesProducts } from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import JSONPretty from "react-json-pretty";
import { Spinner } from "../../../../components/spinners";
import { CopyableText } from "../../../../components/copyable";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { PostRequestInternalAPI } from "../../../../components/backend/postRequest";
import { NotificationError, NotificationSuccess } from "../../../../components/notifications";
import { Helmet } from "react-helmet";
const JSONPrettyMon = require('react-json-pretty/dist/monikai');

export const ProductService = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const getData = () => {
        GetRequestInternalAPI(
            `/api/v1/dev/services/parameters/products`,
            setLoading,
            setErr,
            null,
        ).then(
            (result = { parameters: {} }) => {
                if (result.parameters !== null) {
                    setData(result.parameters)
                }
            }
        )
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, []);


    const [triggerErr, setTriggerErr] = useState("");
    const [triggerLoading, setTriggerLoading] = useState(false);

    const triggerProductReplication = () => {
        PostRequestInternalAPI(
            `/api/v1/products/trigger-product-replication`, {},
            setTriggerLoading,
            setTriggerErr,
            null,
            props.messageAPI,
        ).then(
            (result = { parameters: {} }) => {
                if (result.result !== null) {
                    if (result.result.Error !== undefined) {
                        NotificationError('Error!', result.result.Error)
                    } else {
                        NotificationSuccess('Started!', <>Logs can be checked here {GetApplicationRunLink(result.result.RunID)}</>)
                    }
                }
            }
        )
    }

    if (err !== "" || triggerErr !== "") {
        return (
            <>
                <Helmet>
                    <title>Product service || Bangerhead Admin</title>
                </Helmet>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <BreadcrumbsDeveloperServicesProducts />
                        </Col>
                    </Row>
                </div>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <ErrorRow err={err} prefix={"Product service stats"} />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    if (loading === true || triggerLoading === true) {
        return (
            <>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <BreadcrumbsDeveloperServicesProducts />
                        </Col>
                    </Row>
                </div>
                <Spinner />
            </>
        )
    }

    return (
        <>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperServicesProducts />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Popconfirm
                                title="Trigger"
                                description="Products will be replicated right now. Are you sure?"
                                onConfirm={triggerProductReplication}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                            >
                                <Button
                                    type="dashed"
                                >
                                    Trigger product replication
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Descriptions>
                    <Descriptions.Item label="Run ID">
                        <Typography.Text
                            copyable={{ text: GetApplicationRunHref(data.RunID) }}>
                            {GetApplicationRunLink(data.RunID)}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Started at">{data.ServiceStartedAt !== undefined && data.ServiceStartedAt !== null ? <TimeFromSeconds seconds={data.ServiceStartedAt.seconds * 1000} /> : <></>}</Descriptions.Item>
                    <Descriptions.Item label="Last replication ended">{data.LastProductReplicationFinishedAt !== undefined && data.LastProductReplicationFinishedAt !== null ? <TimeFromSeconds seconds={data.LastProductReplicationFinishedAt.seconds * 1000} /> : <></>}</Descriptions.Item>
                    <Descriptions.Item label="Exchange rates updated at">{data.CurrencyRatesUpdatedAt !== undefined && data.CurrencyRatesUpdatedAt !== null ? <TimeFromSeconds seconds={data.CurrencyRatesUpdatedAt.seconds * 1000} /> : <></>}</Descriptions.Item>
                    <Descriptions.Item label="Total cached products">{data.TotalProductsInCache !== undefined && data.TotalProductsInCache !== null ? data.TotalProductsInCache : <>0</>}</Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Responses with cache">{data.ResponsesServedWithCache !== undefined && data.ResponsesServedWithCache !== null ? data.ResponsesServedWithCache : <>0</>}</Descriptions.Item>
                    <Descriptions.Item label="Responses w/o cache">{data.ResponsesServedWithoutCache !== undefined && data.ResponsesServedWithoutCache !== null ? data.ResponsesServedWithoutCache : <>0</>}</Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Typography.Title
                    level={3} style={{ margin: 0 }}>
                    Currency rates
                </Typography.Title>
                {data.CurrencyRates !== undefined && data.CurrencyRates !== null
                    ?
                    <Descriptions>
                        {data.CurrencyRates.map((rate) => (
                            <Descriptions.Item label={`${rate.Currency}`}><CopyableText text={rate.Rate} /></Descriptions.Item>
                        ))}
                    </Descriptions>
                    :
                    <></>
                }
            </div>
            <div className="container-1700">
                <Typography.Title
                    level={3} style={{ margin: 0 }}>
                    Active settings
                </Typography.Title>
                {data.Settings !== undefined && data.Settings !== null
                    ?
                    <Descriptions>
                        {data.Settings.map((setting) => (
                            <Descriptions.Item label={`${setting.Name}`}><CopyableText text={setting.Value} /></Descriptions.Item>
                        ))}
                    </Descriptions>
                    :
                    <></>
                }
            </div>
            <div className="container-1700">
                {<JSONPretty id="json-pretty" data={data} theme={JSONPrettyMon} />}
            </div>
        </>
    )
}
