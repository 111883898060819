import React from 'react';
import { Col, Row } from 'antd';
import { BreadcrumbsAdmin } from "../../components/breadcrumbs/breadcrumbsAdmin";
import { Helmet } from "react-helmet";

export const Admin = () => {
    return (
        <>
            <Helmet>
                <title>Admin root || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdmin />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                </Row>
            </div>
        </>
    );
};