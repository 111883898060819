export function IsWarning(value = "") {
    return value === "warning" || value === "Warning" || value === "WARNING"
}

export function IsError(value = "") {
    return value === "error" || value === "Error" || value === "ERROR"
}

export function IsDanger(value = "") {
    return value === "danger" || value === "Danger" || value === "DANGER"
}

export function IsCrititcal(value = "") {
    return value === "critical" || value === "Crititcal" || value === "CRITICAL"
}