import { actionOnError, errorTranslation, getBackendLink } from "./backend";
import { NotificationError } from "../notifications";

export async function PostRequest(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

export async function PostRequestV2(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    return response
}

export async function PostRequestInternalAPI(
    url = '', data = {},
    toggleState = null,
    setError = null,
    setData = null,
    messageAPI = null,
    messenger = {
        message: "Loading",
        key: "messagekey",
        success: "Success",
        error: "Error",
    },
) {
    if (toggleState !== null) {
        toggleState(true)
    }

    let messageKey = "message_post"
    let messageToUser = "Loading"
    let messageToUserSuccess = "Success"
    let messageToUserError = "Error"

    if (messageAPI !== null) {
        if (messenger !== undefined) {
            if (messenger.key !== undefined) {
                messageKey = "message_" + messenger.key
            }
            if (messenger.message !== undefined) {
                messageToUser = messenger.message
            }
            if (messenger.success !== undefined) {
                messageToUserSuccess = messenger.success
            }
            if (messenger.error !== undefined && messenger.error !== null) {
                messageToUserError = messenger.error
            }
        }

        messageAPI.open({
            messageKey,
            type: 'loading',
            content: messageToUser,
            //duration: 100,
        })

    }

    let responseData

    try {
        const response = await PostRequestV2(getBackendLink() + url, data);
        const result = await response.json()
        if (result.success !== true) {
            actionOnError(result.error)
            if (setError !== null) {
                setError(errorTranslation(result.error));
            }
            if (messageAPI !== null) {
                messageAPI.error(messageToUserError, 2.5)
            }
            NotificationError(errorTranslation(result.error))
        } else {
            if (setError !== null) {
                setError("")
            }
            if (setData !== null) {
                setData(result.result)
            }
            responseData = result.result

            if (messageAPI !== null) {
                messageAPI.success(messageToUserSuccess, 2.5)
            }
        }
    } catch (error) {
        if (setError !== null) {
            setError(error.message)
        }
        if (messageAPI !== null) {
            messageAPI.error(messageToUserError, 2.5)
        }
        NotificationError(errorTranslation(error.message))
    }

    if (toggleState !== null) {
        toggleState(false)
    }

    return responseData
}

export async function PostRequestMultipart(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include',
        headers: {
            'Content-Type': 'multipart/form-data'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}