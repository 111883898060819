import { useState } from "react";
import React, { useEffect } from "react";
import { Select } from "antd";
import { GetBrands } from "../../components/backend/getters";

const filterOptionBrands = (input, option) => (option?.Name ?? '').toLowerCase().includes(input.toLowerCase());

export const BrandsSelect = (props) => {
    const [err, setErr] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        GetBrands(
            setLoading,
            setErr,
            setData,
        ).then(
            (result) => {
                setData(result.Records)
            }
        )
        // eslint-disable-next-line
    }, []);

    if (err !== "") {
        return <Select status="error" value={"Error: " + err} />
    }

    return (
        <>
            <Select
                showSearch
                loading={loading}
                onChange={(value) => {
                    if (props.onChange !== null && props.onChange !== undefined) {
                        props.onChange(value)
                    }
                }}
                filterOption={filterOptionBrands}
                filterSort={(optionA, optionB) =>
                    (optionA?.Name ?? '').toLowerCase().localeCompare((optionB?.Name ?? '').toLowerCase())
                }
                options={data}
                fieldNames={{ label: "Name", value: "ID" }}
            >
            </Select>
        </>
    )
}