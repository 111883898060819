import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Form, Avatar, Popover } from 'antd';
import { ErrorRow } from "../../../components/errorBlock";
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    MonitorOutlined,
} from "@ant-design/icons";
import { GetUserLink } from "../../../components/getPageLinks";
import { PostRequestInternalAPI } from "../../../components/backend/postRequest";
import { GetUserSelect } from "../../../components/getUsers";
import { DateAndTime, GetDefaultAfter, GetDefaultBefore, TimeFromSeconds } from "../../../components/dateAndTime";
import { EventLevelsMap } from "../../../components/getEventsData";
import {
    BreadcrumbsProfileMessages,
} from "../../../components/breadcrumbs/breadcrumbsProfile";
import { Helmet } from "react-helmet";

export const MessagesList = (props) => {
    const [before, setBefore] = useState(GetDefaultBefore());
    const [after, setAfter] = useState(GetDefaultAfter());

    const columns = [
        {
            title: '',
            dataIndex: 'Seen',
            key: 'Seen',
            render: (_, record) => (
                <>
                    {
                        record.IsSeen
                            ?
                            <Popover content={"Mark message as NOT seen"} title="Not seen">
                                <Button
                                    onClick={() => markNotSeen(record.MessageID)}
                                >
                                    <EyeInvisibleOutlined />
                                </Button>
                            </Popover>
                            :
                            <Popover content={"Mark message as seen"} title="Seen">
                                <Button
                                    onClick={() => markSeen(record.MessageID)}
                                    type="primary"
                                    rel={"noreferrer"}>
                                    <EyeOutlined />
                                </Button>
                            </Popover>
                    }
                </>
            ),
        },
        {
            title: 'Theme',
            dataIndex: 'Head',
            key: 'Head',
            render: (_, record) => (
                <>
                    {
                        record.IsSeen
                            ?
                            record.EventID ? <a href={`/stat/events/${record.EventID}`}>{record.Head}</a> : record.Head
                            :
                            <strong>{record.EventID ? <a href={`/stat/events/${record.EventID}`} target='_blank'
                                rel='noreferrer'>{record.Head}</a> : record.Head}</strong>
                    }
                </>
            ),
        },
        {
            title: 'Time',
            dataIndex: 'Time',
            key: 'Time',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.CreatedAt.seconds * 1000} /></>
            ),
        },
        {
            title: 'Sent by',
            dataIndex: 'SentBy',
            key: 'SentBy',
            render: (_, record) => (
                <>
                    <a href={GetUserLink(record.SentByID)} target={"_blank"} rel={"noreferrer"}>
                        <Avatar style={{ borderRadius: 0 }} src={record.SentByAvatar}>{record.SentByName}</Avatar>
                        <br />
                        {record.SentByName}
                    </a>
                </>
            ),
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            render: (_, record) => (
                <>{EventLevelsMap[record.Priority].tag}</>
            ),
        },
        {
            title: 'Sent to',
            dataIndex: 'ChannelsUsed',
            key: 'ChannelsUsed',
        },
    ];

    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line

    // eslint-disable-next-line
    const [authorUser, setAuthorUser] = useState("");
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const fetchData = () => {
        PostRequestInternalAPI(`/api/v1/notifications/messages/self/list/json`, {
            page: tableParams.pagination.current,
            size: tableParams.pagination.pageSize,
            author: authorUser,
            before: before,
            after: after,
        },
            setLoading,
            setErr,
            null,
        )
            .then(
                (result) => {
                    setData(result.Records);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: result.Total,
                        },
                    });
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    const markSeen = (key = "") => {
        PostRequestInternalAPI(`/api/v1/notifications/messages/self/mark_seen`, {
            "MessageID": key,
        },
            setLoading,
            null,
            null,
            props.messageAPI,
            {
                message: "Saving",
                success: "Marked seen",
                error: "Error",
            },
        )
            .then(() => {
                let newData = data
                data.forEach((element) => {
                    if (element.MessageID === key) {
                        element.IsSeen = true
                    }
                    newData.push(element)
                }
                );
                setData(newData);

                props.recountUnreads(-1)
            })
    };

    const markNotSeen = (key = "") => {
        PostRequestInternalAPI(`/api/v1/notifications/messages/self/mark_not_seen`, {
            "MessageID": key,
        },

            setLoading,
            null,
            null,
            props.messageAPI,
            {
                message: "Saving",
                success: "Marked not seen",
                error: "Error",
            },
        )
            .then(() => {
                let newData = data
                data.forEach((element) => {
                    if (element.MessageID === key) {
                        element.IsSeen = false
                    }
                    newData.push(element)
                }
                );
                setData(newData);

                props.recountUnreads(1)
            })
    };

    return (
        <>
            <Helmet>
                <title>Messages || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProfileMessages />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Button type="primary" target={"_blank"}
                            rel={"noreferrer"}
                            onClick={fetchData}
                        >
                            Get messages <MonitorOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <ErrorRow err={err} prefix={"Logs"} />
                <Row>
                    <Col xs={24} xl={24}>
                        <Form.Item label="Dates" name="Dates"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select Before and after',
                                },
                            ]}>
                            <DateAndTime setBefore={setBefore} setAfter={setAfter} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Form.Item
                            name={`Author`}
                            label={`Author`}
                        >
                            <GetUserSelect grants={props.grants} setUser={setAuthorUser} />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.MessageID}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                            expandable={{
                                expandRowByClick: true,
                                expandedRowRender: (record) => (
                                    <p
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        {record.Content}
                                    </p>
                                ),
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
