import React, { useEffect, useState, useRef } from 'react';
import { Col, Space, Row, Table, Form, Tour, Button, Select, Input } from "antd";
import { CaretRightOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { NotificationError } from "../../components/notifications";
import { ErrorRow } from "../../components/errorBlock";
import { GetRequest } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { BreadcrumbsMorefloHistory } from "../../components/breadcrumbs/breadcrumbsMoreflo";
import {
    ColumnsMoreFloHistory as columns, GetLinkCSVMoreFloHistory,
} from "../../components/moreflo";
import { ButtonDownloadCSV } from "../../components/buttons/downloadCSV";
import Moment from "react-moment";
import { Helmet } from "react-helmet";

export function MorefloPipelineHistory() {
    const refPickRun = useRef(null);
    const refDownload = useRef(null);
    const refGetData = useRef(null);
    const [open, setOpen] = useState(false);

    const tourSteps = [
        {
            title: 'Pick run',
            description: 'You can get data for all runs or pick specific one from the list.',
            target: () => refPickRun.current,
        },
        {
            title: 'Download CSV',
            description: <>You can download in CSV, but only for one specific run at once. It will take some time. Pick any record in dropdown and the button will become active. You <strong>don`t</strong> need to get data in browser before that: simply pick a record and press download.</>,
            target: () => refDownload.current,
        },
        {
            title: 'Get data',
            description: 'Press "Get history" to get product data records on browser tab.',
            target: () => refGetData.current,
        },
    ];

    const [form] = Form.useForm();
    const [lastBatches, setLatsBatches] = useState([])
    const batchValue = Form.useWatch('batch', form);
    const [batchSelectedValue, setBatchSelectedValue] = useState('')
    const skuValue = Form.useWatch('sku', form);
    const barcodeValue = Form.useWatch('barcode', form);

    const [err, setErr] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    const getBatchID = () => {
        let batchIDLocal = "all"
        if (batchValue !== undefined && batchValue !== null) {
            batchIDLocal = batchValue
        }
        if (batchSelectedValue !== "" && batchSelectedValue !== undefined) {
            batchIDLocal = batchSelectedValue
        }

        return batchIDLocal
    }

    const getSKU = () => {
        let skuLocal = "all"
        if (skuValue !== undefined && skuValue !== null && skuValue !== "") {
            skuLocal = skuValue
        }

        return skuLocal
    }

    const getBarcode = () => {
        let barcodeLocal = "all"
        if (barcodeValue !== undefined && barcodeValue !== null && barcodeValue !== "") {
            barcodeLocal = barcodeValue
        }

        return barcodeLocal
    }

    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/more_flo/cache_records_get?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&batch_id=${getBatchID()}&sku=${getSKU()}&barcode=${getBarcode()}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                        let newLastRuns = [
                            { ID: "all", Name: "all" }
                        ]

                        result.result.LastRuns.map((x) => (
                            newLastRuns.push({
                                ID: x.batch_number,
                                Name: <>{x.batch_number} (<Moment format="YYYY.MM.DD HH:mm:ss" date={x.batch_time} />)</>,
                            })
                        ))
                        setLatsBatches(newLastRuns)
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    setErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    return (
        <>
            <Helmet>
                <title>MoreFlo history || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsMorefloHistory />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24} ref={refDownload}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpen(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                Take a Tour <QuestionCircleOutlined />
                            </Button>
                            <ButtonDownloadCSV
                                link={GetLinkCSVMoreFloHistory(getBatchID(), getSKU(), getBarcode())}
                                disabled={getBatchID() === "all" && getSKU() === "all" && getBarcode() === "all"}
                            />
                        </Space>
                    </Col>
                </Row>
                <ErrorRow err={err} prefix={"History"} />
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24} ref={refPickRun} >
                        <Form form={form} layout="vertical" autoComplete="off">
                            <Form.Item
                                name={`Batch ID`}
                                label={`Last runs`}
                            >
                                <Select
                                    onChange={(value) => { setBatchSelectedValue(value) }}
                                    options={lastBatches}
                                    fieldNames={{ label: "Name", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item name="sku" label="Item SKU">
                                <Input />
                            </Form.Item>
                            <Form.Item name="barcode" label="Item barcode">
                                <Input />
                            </Form.Item>
                            <Button type="primary" onClick={fetchData} ref={refGetData}>
                                Get history <CaretRightOutlined />
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                            scroll={{
                                y: 500,
                                x: 4000,
                            }}
                        />
                    </Col>
                </Row>
                <Tour open={open} onClose={() => setOpen(false)} steps={tourSteps} />
            </div>
        </>
    )
}

