import { actionOnError, errorTranslation, getBackendLink } from "./backend";
import { NotificationError } from "../notifications";

export async function GetRequest(url = '') {
    const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        credentials: "include"
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

export async function GetRequestV2(url = '') {
    const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        credentials: "include"
    });

    return response
}

export async function GetRequestInternalAPI(
    url = '',
    toggleState,
    setError,
    setData,
) {
    if (toggleState !== null) {
        toggleState(true)
    }

    let data

    try {
        const response = await GetRequestV2(getBackendLink() + url);
        const result = await response.json()
        if (result.success !== true) {
            actionOnError(result.error)
            if (setError !== null) {
                setError(errorTranslation(result.error));
            }

            NotificationError(errorTranslation(result.error))
        } else {
            if (setError !== null) {
                setError("")
            }

            if (setData !== null) {
                setData(result.result)
            }

            data = result.result
        }
    } catch (error) {
        if (toggleState !== null) {
            setError(error.message);
        }

        NotificationError(errorTranslation(error.message))
    }

    if (toggleState !== null) {
        toggleState(false)
    }

    return data
}