export const HandleTableChange = (pagination, filters, sorter, tableParams, setTableParams, setData) => {
    setTableParams({
        pagination,
        filters,
        ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        // eslint-disable-next-line
        setData([]);
    }
};