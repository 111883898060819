import { Checkbox, Col, Divider, Row } from "antd";
import React from "react";

export function SubscriptionsCheckList(props = {
    userSubscriptions: [],
    allChecksDisabled: false,
    onChange: null,
}) {
    let activeUserSubscriptions = {
        personal_account: { info: false, warning: false, error: false, danger: false, critical: false, },
        system: { info: false, warning: false, error: false, danger: false, critical: false, },
        bh_admin: { info: false, warning: false, error: false, danger: false, critical: false, },
        dev_debug: { info: false, warning: false, error: false, danger: false, critical: false, },
        auth_service: { info: false, warning: false, error: false, danger: false, critical: false, },
        logger_service: { info: false, warning: false, error: false, danger: false, critical: false, },
        notify_service: { info: false, warning: false, error: false, danger: false, critical: false, },
        products_service: { info: false, warning: false, error: false, danger: false, critical: false, },
        eco_service: { info: false, warning: false, error: false, danger: false, critical: false, },
        integration_more_flo: { info: false, warning: false, error: false, danger: false, critical: false, },
        integration_slim4: { info: false, warning: false, error: false, danger: false, critical: false, },
        integration_feeds_v2: { info: false, warning: false, error: false, danger: false, critical: false, },
        integration_gamifiera_user_feed_v2: { info: false, warning: false, error: false, danger: false, critical: false, },
        integration_replicator_v1: { info: false, warning: false, error: false, danger: false, critical: false, },
        redis_product_replicator_v3: { info: false, warning: false, error: false, danger: false, critical: false, },
    }

    let userSubscriptions = [
        { Source: "", Priority: "", }
    ]

    if (props.userSubscriptions !== null && props.userSubscriptions !== undefined) {
        userSubscriptions = props.userSubscriptions

        userSubscriptions.forEach((subscription) => {
            activeUserSubscriptions[subscription.Source][subscription.Priority] = subscription
        }
        );
    }

    return (
        <>
            <Divider>General</Divider>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={8}>
                    <h3>Personal account</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"PersonalAccount"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
            </Row>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={8}>
                    <h3>System</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"System"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Products</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"ProductsService"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Auth service</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"AuthService"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
            </Row>

            <Divider>Integrations (internal & 3rd party)</Divider>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={8}>
                    <h3>MoreFlo integration</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"IntegrationMoreFlo"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Slim4 integration</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"IntegrationSlim4"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Product feeds (v2)</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"IntegrationFeedsV2"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Gamifiera users feed (v2)</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"GamifieraUsersV2"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Product replicator (v1)</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"IntegrationReplicatorV1"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Redis product replicator (v3)</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"RedisProductReplicatorV3"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
            </Row>

            <Divider>For developers</Divider>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={8}>
                    <h3>Admin panel</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"BHAdmin"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Logger service</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"LoggerService"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Notify service</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"NotifyService"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <h3>Developer debug</h3>
                    <SubscriptionLevelsList
                        onChange={props.onChange}
                        activeUserSubscriptions={activeUserSubscriptions}
                        service={"DevDebug"}
                        allChecksDisabled={props.allChecksDisabled}
                    />
                </Col>
            </Row>
        </>
    )
}

export const SubscriptionTypesList = {
    PersonalAccount: {
        id: "personal_account",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    System: {
        id: "system",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    BHAdmin: {
        id: "bh_admin",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    DevDebug: {
        id: "dev_debug",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    AuthService: {
        id: "auth_service",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    LoggerService: {
        id: "logger_service",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    NotifyService: {
        id: "notify_service",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    ProductsService: {
        id: "products_service",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    EcoService: {
        id: "eco_service",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    IntegrationMoreFlo: {
        id: "integration_more_flo",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    IntegrationSlim4: {
        id: "integration_slim4",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    IntegrationFeedsV2: {
        id: "integration_feeds_v2",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    GamifieraUsersV2: {
        id: "integration_gamifiera_user_feed_v2",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    IntegrationReplicatorV1: {
        id: "integration_replicator_v1",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
    RedisProductReplicatorV3: {
        id: "redis_product_replicator_v3",
        Info: { id: "info", name: "Info", }, Warning: { id: "warning", name: "Warning", },
        Error: { id: "error", name: "Error", }, Danger: { id: "danger", name: "Danger", },
        Critical: { id: "critical", name: "Critical", },
    },
}

export function SubscriptionLevelsList(props = {}) {
    return (
        <>
            <Checkbox onChange={props.onChange} Source={SubscriptionTypesList[props.service].id}
                Priority={SubscriptionTypesList[props.service].Info.id}
                checked={
                    props.activeUserSubscriptions[SubscriptionTypesList[props.service].id][SubscriptionTypesList[props.service].Info.id]
                }
                disabled={props.allChecksDisabled === true}>
                {SubscriptionTypesList[props.service].Info.name}
            </Checkbox>
            <Checkbox onChange={props.onChange} Source={SubscriptionTypesList[props.service].id}
                Priority={SubscriptionTypesList[props.service].Warning.id}
                checked={
                    props.activeUserSubscriptions[SubscriptionTypesList[props.service].id][SubscriptionTypesList[props.service].Warning.id]
                }
                disabled={props.allChecksDisabled === true}>
                {SubscriptionTypesList[props.service].Warning.name}
            </Checkbox>
            <Checkbox onChange={props.onChange} Source={SubscriptionTypesList[props.service].id}
                Priority={SubscriptionTypesList[props.service].Error.id}
                checked={
                    props.activeUserSubscriptions[SubscriptionTypesList[props.service].id][SubscriptionTypesList[props.service].Error.id]
                }
                disabled={props.allChecksDisabled === true}>
                {SubscriptionTypesList[props.service].Error.name}
            </Checkbox>
            <Checkbox onChange={props.onChange} Source={SubscriptionTypesList[props.service].id}
                Priority={SubscriptionTypesList[props.service].Danger.id}
                checked={
                    props.activeUserSubscriptions[SubscriptionTypesList[props.service].id][SubscriptionTypesList[props.service].Danger.id]
                }
                disabled={props.allChecksDisabled === true}>
                {SubscriptionTypesList[props.service].Danger.name}
            </Checkbox>
            <Checkbox onChange={props.onChange} Source={SubscriptionTypesList[props.service].id}
                Priority={SubscriptionTypesList[props.service].Critical.id}
                checked={
                    props.activeUserSubscriptions[SubscriptionTypesList[props.service].id][SubscriptionTypesList[props.service].Critical.id]
                }
                disabled={props.allChecksDisabled === true}>
                {SubscriptionTypesList[props.service].Critical.name}
            </Checkbox>
        </>
    )
}

