import { Button, Popconfirm, Space } from "antd";
import React from "react";
import { QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { GetUserLink } from "../getPageLinks";
import Moment from "react-moment";

export const columnsSlim4PCSNotEditable = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
    },
    {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
        editable: true,
        sorter: (a, b) => a.brand.length - b.brand.length,
        render: (_, record) =>
            <>
                {record.brand ? <>{record.brand} : {record.brand_id}</> : <></>}
            </>
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        editable: true,
        sorter: (a, b) => a.category.length - b.category.length,
        render: (_, record) =>
            <>
                {record.category ? <>{record.category} : {record.category_id}</> : <></>}
            </>
    },
    {
        title: 'Purchaser Code',
        dataIndex: 'purchaser_code',
        key: 'purchaser_code',
        editable: true,
        sorter: (a, b) => a.purchaser_code.length - b.purchaser_code.length,
    },
    {
        title: 'Last editor',
        dataIndex: 'lastEditedBy',
        key: 'lastEditedBy',
        render: (_, record) => (
            <>{record.lastEditedByName === "" ? <><a href={GetUserLink(record.lastEditedBy)}>{record.lastEditedBy}</a></> :
                <a href={GetUserLink(record.lastEditedBy)}>{record.lastEditedByName}</a>}</>
        ),
    },
    {
        title: 'Last change',
        dataIndex: 'lastUpdate',
        key: 'lastUpdate',
        render: (_, record) => (
            <><Moment format="YYYY.MM.DD HH:mm:ss" date={record.lastUpdate} /></>
        ),
    },
];

export function GetColumnsSlim4PCSEditable(handleDelete, handleSaveV2) {
    let columnsSlim4PCSEditable = columnsSlim4PCSNotEditable.map((x) => x);

    columnsSlim4PCSEditable[columnsSlim4PCSEditable.length] = {
        title: 'Operations',
        dataIndex: 'operation',
        render: (_, record) =>
            <>
                <Space wrap>
                    <Button type="dashed" icon={<SaveOutlined />}
                        onClick={() => handleSaveV2(record)}>Save</Button>
                    <Popconfirm
                        title="Delete row"
                        description="Are you sure to delete this code?"
                        onConfirm={() => handleDelete(record.id)}
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                    >
                        <Button type="dashed" danger>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            </>
    }

    return columnsSlim4PCSEditable
}





