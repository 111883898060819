import { Checkbox, Col, Divider, Row } from "antd";
import React from "react";

export const GrantsList = {
    Grants: {
        See: {
            id: "grants_see",
            name: "Grants: see",
            description: "Permission to see grants given to users & tokens",
        },
        Add: {
            id: "grants_add",
            name: "Grants: add",
            description: "",
        },
        Remove: {
            id: "grants_remove",
            name: "Grants: remove",
            description: "",
        },
        Admin: {
            id: "grants_admin",
            name: "Grants: admin",
            description: "",
        },
    },
    Session: {
        See: {
            id: "session_see",
            name: "Session: see",
            description: "",
        },
        Create: {
            id: "session_create",
            name: "Session: create",
            description: "Technical grant that must be given to applications only (not users)",
        },
        Close: {
            id: "session_close",
            name: "Session: close",
            description: "Technical grant that must be given to applications only (not users)",
        },
        Update: {
            id: "session_update",
            name: "Session: update",
            description: "Technical grant that must be given to applications only (not users)",
        },
    },
    AccessToken: {
        See: {
            id: "access_token_add",
            name: "Access token: add",
            description: "",
        },
        Add: {
            id: "access_token_see",
            name: "Access token: see",
            description: "See access token list",
        },
        Expire: {
            id: "access_token_expire",
            name: "Access token: expire",
            description: "",
        },
        Revoke: {
            id: "access_token_revoke",
            name: "Access token: revoke",
            description: "",
        },
    },
    Security: {
        Logs: {
            See: {
                id: "security_logs_see",
                name: "Security logs: see",
                description: "",
            },
            Add: {
                id: "security_logs_add",
                name: "Security logs: add",
                description: "",
            },
        },
        IP: {
            See: {
                id: "ip_see",
                name: "IP: add",
                description: "",
            },
            Block: {
                id: "ip_block",
                name: "IP: see",
                description: "",
            },
        },
    },
    Integrations: {
        See: {
            id: "integration_see",
            name: "Integration: See",
            description: "",
        },
        Admin: {
            id: "integration_admin",
            name: "Integration: Admin",
            description: "",
        },
        Slim4: {
            See: {
                id: "integration_slim4_see",
                name: "Slim4: See",
                description: "",
            },
            Admin: {
                id: "integration_slim4_admin",
                name: "Slim4: Admin",
                description: "Slim4: add & edit integration data",
            },
        },
        MoreFlo: {
            See: {
                id: "integration_moreflo_see",
                name: "MoreFlo: See",
                description: "",
            },
        }
    },
    Developer: {
        Info: {
            See: {
                id: "developer_info_see",
                name: "Developer: see",
                description: "",
            },
            Write: {
                id: "developer_info_write",
                name: "Developer: write",
                description: "",
            },
        },
        Events: {
            Create: {
                id: "developer_events_create",
                name: "Developer: events create",
                description: "",
            },
            Resolve: {
                id: "developer_events_resolve",
                name: "Dev events: resolve",
                description: "",
            },
        },
        Admin: {
            id: "developer_admin",
            name: "Developer: admin",
            description: "",
        },
    },
    Users: {
        See: {
            id: "users_see",
            name: "Users: see",
            description: "",
        },
        Add: {
            id: "users_add",
            name: "Users: add",
            description: "",
        },
        Edit: {
            id: "users_edit",
            name: "Users: edit",
            description: "",
        },
        Remove: {
            id: "users_remove",
            name: "Users: remove",
            description: "",
        },
        Admin: {
            id: "users_admin",
            name: "Users: admin",
            description: "",
        },
        ChangePassword: {
            id: "users_change_password",
            name: "Users: change password",
            description: "",
        },
        EnableDisable: {
            id: "users_enable_disable",
            name: "Users: enable/disable",
            description: "",
        },
    },
    Statistic: {
        See: {
            id: "statistic_see",
            name: "Statistic: see",
            description: "",
        },
        Logs: {
            See: {
                id: "logs_see",
                name: "Logs: see",
                description: "",
            },
            Add: {
                id: "logs_add",
                name: "Logs: add",
                description: "",
            },
        },
    },
    Notifications: {
        Messages: {
            Push: {
                id: "messages_push",
                name: "Messages: push",
                description: "",
            },
        },
        Events: {
            See: {
                id: "event_see",
                name: "Events: see",
                description: "",
            },
            Create: {
                id: "event_create",
                name: "Events: create",
                description: "",
            },
            Resolve: {
                id: "event_resolve",
                name: "Events: resolve",
                description: "",
            },
        },
    },
    Products: {
        See: {
            id: "products_see",
            name: "Products: see",
            description: "",
        },
        Campaigns: {
            See: {
                id: "campaigns_see",
                name: "Campaigns: see",
                description: "",
            },
            Create: {
                id: "campaigns_create",
                name: "Campaigns: create",
                description: "",
            },
            Edit: {
                id: "campaigns_edit",
                name: "Campaigns: edit",
                description: "",
            },
            Delete: {
                id: "campaigns_delete",
                name: "Campaigns: delete",
                description: "",
            },
        },
    },
    Administration: {
        Administrator: {
            id: "administrator",
            name: "Administrator",
            description: "Nominal grant that indicates that user has higher privileges and has to be treated nicely",
        },
    }
}

export function AccessGrantsCheckList(props) {
    let activeUserGrants = {}

    if (props.activeUserGrants !== null && props.activeUserGrants !== undefined) {
        activeUserGrants = props.activeUserGrants
    } else {
        let userGrants = []
        if (props.userGrants !== null && props.userGrants !== undefined) {
            userGrants = props.userGrants
        }

        userGrants.forEach((grant) => {
            activeUserGrants[grant] = true;
        }
        );
    }

    return (
        <>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Products</h3>
                    <Checkbox name={GrantsList.Products.See.id}
                        checked={activeUserGrants[GrantsList.Products.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Products.See.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Products: campaigns</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Products.Campaigns.See.id}
                        checked={activeUserGrants[GrantsList.Products.Campaigns.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Products.Campaigns.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Products.Campaigns.Create.id}
                        checked={activeUserGrants[GrantsList.Products.Campaigns.Create.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Products.Campaigns.Create.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Products.Campaigns.Edit.id}
                        checked={activeUserGrants[GrantsList.Products.Campaigns.Edit.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Products.Campaigns.Edit.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Products.Campaigns.Delete.id}
                        checked={activeUserGrants[GrantsList.Products.Campaigns.Delete.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Products.Campaigns.Delete.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Statistic</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Statistic.See.id}
                        checked={activeUserGrants[GrantsList.Statistic.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Statistic.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Statistic.Logs.See.id}
                        checked={activeUserGrants[GrantsList.Statistic.Logs.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Statistic.Logs.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Statistic.Logs.Add.id}
                        checked={activeUserGrants[GrantsList.Statistic.Logs.Add.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Statistic.Logs.Add.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Notifications</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Notifications.Events.See.id}
                        checked={activeUserGrants[GrantsList.Notifications.Events.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Notifications.Events.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Notifications.Events.Resolve.id}
                        checked={activeUserGrants[GrantsList.Notifications.Events.Resolve.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Notifications.Events.Resolve.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Users</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Users.See.id}
                        checked={activeUserGrants[GrantsList.Users.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Users.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Users.Add.id}
                        checked={activeUserGrants[GrantsList.Users.Add.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Users.Add.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Users.Edit.id}
                        checked={activeUserGrants[GrantsList.Users.Edit.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Users.Edit.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Users.Remove.id}
                        checked={activeUserGrants[GrantsList.Users.Remove.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Users.Remove.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Users.Admin.id}
                        checked={activeUserGrants[GrantsList.Users.Admin.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Users.Admin.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Users.ChangePassword.id}
                        checked={activeUserGrants[GrantsList.Users.ChangePassword.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Users.ChangePassword.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Users.EnableDisable.id}
                        checked={activeUserGrants[GrantsList.Users.EnableDisable.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Users.EnableDisable.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Security</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Security.Logs.See.id}
                        checked={activeUserGrants[GrantsList.Security.Logs.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Security.Logs.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Security.Logs.Add.id}
                        checked={activeUserGrants[GrantsList.Security.Logs.Add.id]}
                        className={"orange-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Security.Logs.Add.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Security.IP.See.id}
                        checked={activeUserGrants[GrantsList.Security.IP.See.id]}
                        className={"orange-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Security.IP.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Security.IP.Block.id}
                        checked={activeUserGrants[GrantsList.Security.IP.Block.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Security.IP.Block.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Integrations</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Integrations.See.id}
                        checked={activeUserGrants[GrantsList.Integrations.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Integrations.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Integrations.Admin.id}
                        checked={activeUserGrants[GrantsList.Integrations.Admin.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Integrations.Admin.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Slim4</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Integrations.Slim4.See.id}
                        checked={activeUserGrants[GrantsList.Integrations.Slim4.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Integrations.Slim4.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Integrations.Slim4.Admin.id}
                        checked={activeUserGrants[GrantsList.Integrations.Slim4.Admin.id]}
                        className={"orange-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Integrations.Slim4.Admin.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>MoreFlo</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Integrations.MoreFlo.See.id}
                        checked={activeUserGrants[GrantsList.Integrations.MoreFlo.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Integrations.MoreFlo.See.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Developer</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Developer.Info.See.id}
                        checked={activeUserGrants[GrantsList.Developer.Info.See.id]}
                        className={"orange-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Developer.Info.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Developer.Events.Resolve.id}
                        checked={activeUserGrants[GrantsList.Developer.Events.Resolve.id]}
                        className={"orange-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Developer.Events.Resolve.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Administration</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Administration.Administrator.id}
                        checked={activeUserGrants[GrantsList.Administration.Administrator.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Administration.Administrator.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Access grants</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Grants.See.id}
                        checked={activeUserGrants[GrantsList.Grants.See.id]}
                        className={"orange-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Grants.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Grants.Add.id}
                        checked={activeUserGrants[GrantsList.Grants.Add.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Grants.Add.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Grants.Remove.id}
                        checked={activeUserGrants[GrantsList.Grants.Remove.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Grants.Remove.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Grants.Admin.id}
                        checked={activeUserGrants[GrantsList.Grants.Admin.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Grants.Admin.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Session</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Session.See.id}
                        checked={activeUserGrants[GrantsList.Session.See.id]}
                        className={"orange-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Session.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Session.Close.id}
                        checked={activeUserGrants[GrantsList.Session.Close.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Session.Close.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>AccessToken</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.AccessToken.See.id}
                        checked={activeUserGrants[GrantsList.AccessToken.See.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.AccessToken.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.AccessToken.Add.id}
                        checked={activeUserGrants[GrantsList.AccessToken.Add.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.AccessToken.Add.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.AccessToken.Expire.id}
                        checked={activeUserGrants[GrantsList.AccessToken.Expire.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.AccessToken.Expire.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.AccessToken.Revoke.id}
                        checked={activeUserGrants[GrantsList.AccessToken.Revoke.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.AccessToken.Revoke.name}
                    </Checkbox>
                </Col>
            </Row>

            <Divider>Technical grants (don't use on users)</Divider>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Session</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Session.Create.id}
                        checked={activeUserGrants[GrantsList.Session.Create.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Session.Create.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Session.Update.id}
                        checked={activeUserGrants[GrantsList.Session.Update.id]}
                        className={"red-checkbox"}
                        disabled={props.disableAll === true}>
                        {GrantsList.Session.Update.name}
                    </Checkbox>
                </Col>
            </Row>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={24}>
                    <h3>Notifications</h3>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Notifications.Messages.Push.id}
                        checked={activeUserGrants[GrantsList.Notifications.Messages.Push.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Notifications.Messages.Push.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <Checkbox name={GrantsList.Notifications.Events.Create.id}
                        checked={activeUserGrants[GrantsList.Notifications.Events.Create.id]}
                        disabled={props.disableAll === true}>
                        {GrantsList.Notifications.Events.Create.name}
                    </Checkbox>
                </Col>
            </Row>
        </>
    )
}