import React, { useState, useEffect } from "react";
import { CanSeeUsers } from "./backend/backendRoles";
import { GetRequestInternalAPI } from "./backend/getRequest";
import { ErrorRow } from "./errorBlock";
import { Select } from "antd";

export const GetUserSelect = (props) => {
    const [err, setErr] = useState("")
    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/users/admin/get_list?page=1&size=500`,
            setLoadingUsers,
            setErr,
            null
        ).then(
            (result) => {
                let newUsers = [
                    { ID: "", Name: " " }
                ]

                result.Records.map((x) => (
                    newUsers.push({
                        ID: x.ID,
                        Name: <>{x.FirstName} {x.LastName}</>,
                    })
                ))

                setUsers(newUsers)
            }
        )
        // eslint-disable-next-line
    }, []);

    if (err !== "") {
        return <ErrorRow prefix={"User list"} err={err} />
    }

    return (
        <Select
            loading={loadingUsers}
            disabled={!CanSeeUsers(props.grants)}
            onChange={(value) => {
                props.setUser(value)
            }}
            options={users}
            fieldNames={{ label: "Name", value: "ID" }}
        >
        </Select>
    )
}