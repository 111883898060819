import { Breadcrumb } from "antd";
import { pages } from "./breadcrumbs";
import { FallOutlined } from "@ant-design/icons";

export function BreadcrumbsCampaigns() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                {
                    title: (
                        <>
                            <FallOutlined />
                            <span>Campaigns</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsCampaignsCreate() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["campaigns"],
                {
                    title: (
                        <>
                            <span>Campaigns</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsCampaignsLogs() {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["campaigns"],
                {
                    title: (
                        <>
                            <span>Logs</span>
                        </>
                    ),
                },
            ]}
        />
    )
}

export function BreadcrumbsCampaignsCampaign(props) {
    return (
        <Breadcrumb
            items={[
                pages["home"],
                pages["campaigns"],
                {
                    title: (
                        <>
                            <span>{props.id}</span>
                        </>
                    ),
                },
            ]}
        />
    )
}