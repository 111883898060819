import { useState } from "react";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import React, { useEffect } from "react";
import { PostRequest } from "../../components/backend/postRequest";
import { Button, Modal, Popconfirm, Table } from "antd";
import { AccessGrantsCheckList } from "../../components/grantsList";
import { GetUserLink } from "../../components/getPageLinks";
import { CloseCircleOutlined, QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { ErrorRow } from "../../components/errorBlock";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { FormatShortUUID } from "../../components/formattingValues";

// OwnAccessTokens returns OWN user tokens, not all tokens in the system.
export const OwnAccessTokensTable = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleTableChangeAccessTokens = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    useEffect(() => {
        // Here we load OWN user tokens, not all in the system.
        GetRequestInternalAPI(
            `/api/v1/security/access_token/own/list/json?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`,
            setLoading,
            setErr,
            null
        ).then(
            (result = { Total: 0, Records: [] }) => {
                setData(result.Records)
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: result.Total,
                    },
                });
            }
        )
        // eslint-disable-next-line
    }, [tableParams.pagination.pageSize, tableParams.pagination.current]);

    const handleRevoke = (tokenID = "") => {
        setLoading(true)
        PostRequest(getBackendLink() + `/api/v1/security/access_token/revoke`, {
            "tokenID": tokenID,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Revoked!')
                    let newData = data

                    if (Array.isArray(tokenID)) {
                        tokenID.forEach((element) => {
                            newData = newData.filter((item) => item.tokenID !== element);
                        }
                        );
                    } else {
                        newData = newData.filter((item) => item.tokenID !== tokenID);
                    }
                    setData(newData);
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
        setLoading(false)
    };

    const handleExpire = (tokenID = "") => {
        setLoading(true)
        PostRequest(getBackendLink() + `/api/v1/security/access_token/expire`, {
            "tokenID": tokenID,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Expired!')
                    let newData = data

                    if (Array.isArray(tokenID)) {
                        tokenID.forEach((element) => {
                            newData = newData.filter((item) => item.tokenID !== element);
                        }
                        );
                    } else {
                        newData = newData.filter((item) => item.tokenID !== tokenID);
                    }
                    setData(newData);
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
        setLoading(false)
    };

    const [grantsOpen, setGrantsOpen] = useState(false);
    const [currentGrants, setCurrentGrants] = useState([])

    const GrantsPopup = ({ open, onCreate, onCancel }) => {
        return (
            <Modal
                open={open}
                okText="OK"
                cancelText="Cancel"
                onCancel={onCancel}
                title="Token grants"
            >
                <AccessGrantsCheckList userGrants={currentGrants} disableAll />
            </Modal>
        );
    };

    const columnsAccessTokens = [
        {
            title: 'ID',
            dataIndex: 'tokenID',
            key: 'tokenID',
        },
        {
            title: 'Owner',
            dataIndex: 'Owner',
            key: 'Owner',
            render: (_, record) => (
                <>{record.ownerName === "" ? <><a href={GetUserLink(record.ownerID)}>{record.ownerID}</a></> :
                    <a href={GetUserLink(record.ownerID)}>{record.ownerName}</a>}</>
            ),
        },
        {
            title: 'CreatedAt',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.createdAt.seconds * 1000} /></>
            ),
        },
        {
            title: 'ExpiresAt',
            dataIndex: 'ExpiresAt',
            key: 'ExpiresAt',
            render: (_, record) => (
                <><TimeFromSeconds seconds={record.expiresAt.seconds * 1000} /></>
            ),
        },
        {
            title: 'Creator',
            dataIndex: 'Creator',
            key: 'Creator',
            render: (_, record) => (
                <>{record.createdByName === "" ? <><a href={GetUserLink(record.createdBy)}>{record.createdBy}</a></> :
                    <a href={GetUserLink(record.createdBy)}>{record.createdByName}</a>}</>
            ),
        },
        {
            title: 'Session',
            dataIndex: 'Session',
            key: 'Session',
            render: (_, record) => (
                <>{record.sessionID === "" ? <></> :
                    FormatShortUUID(record.sessionID)}</>
            ),
        },
        {
            title: 'Comment',
            dataIndex: 'Comment',
            key: 'Comment',
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            key: 'Actions',
            render: (_, record) => (
                <>
                    <>
                        <Button
                            onClick={() => {
                                setGrantsOpen(true);
                                setCurrentGrants(record.grants);
                            }}
                            type="primary"
                        >
                            Grants
                        </Button>
                        <Popconfirm
                            title="Expire token"
                            description="Are you sure to expire this token?"
                            onConfirm={() => handleExpire(record.tokenID)}
                            icon={
                                <WarningOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                        >
                            <Button type="dashed" danger
                            >
                                <WarningOutlined
                                    style={{
                                        color: 'magenta',
                                    }}
                                />
                            </Button>
                        </Popconfirm>

                        <Popconfirm
                            title="Revoke token"
                            description="Are you sure to revoke this token?"
                            onConfirm={() => handleRevoke(record.tokenID)}
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                        >
                            <Button type="dashed" danger>
                                <CloseCircleOutlined />
                            </Button>
                        </Popconfirm>

                    </>
                </>
            ),
        },
    ]

    if (err !== "") {
        return <ErrorRow prefix={"Access tokens"} err={err} />
    }

    return (
        <>
            <Table
                columns={columnsAccessTokens}
                rowKey={(record) => record.tokenID}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                size={"small"}
                onChange={handleTableChangeAccessTokens}
            />
            <GrantsPopup
                open={grantsOpen}
                onCancel={() => {
                    setGrantsOpen(false);
                }}
            />
        </>
    )


}