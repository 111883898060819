export var RedisProductLocalData = {

}
export var RedisProductData = {
    Id: "",
    Gtin: "",
    Parent: "",
    Visibility: "",
    Offer: false,
    VatId: 0,
    Price: "",
    PriceExtra: "",
    PurchasePrice: null,
    Mpn: "",
    EAN: "",
    ShippingClassId: 1,
    StockId: 5,
    BrandId: "",
    BrandName: "",
    BrandLimitCountry: "",
    InStock: false,
    Quantity: "0",
    IsSetPrice: false,
    Supplier: "JÅM Produkter",
    BuyTogether: "",
    DataReplicatedAt: "",
    RecommendedRetailPrices: [],
    ProductLink: [],
    ParentLink: [],
    ProductSet: "",
    ProductGroups: [],
    DeliveryCost: [],
    FreeShippingLimit: [],
    ProductPriceCurrencies: [],
    Rates: { Rate: "", Vat: "" },
    Group: { Products: "", Min: "", Prices: "" },
    CurrentPrices: [],
    CompetitorPricing: [],
    Features: [],
    Feature: [],
    Vegan: [],
    Eko: [],
    DescriptionParent: [],
    Description: [],
    ProductType: [],
    Title: [],
    VoucherCodes: [],
    Deals: [],
}


export function GetLinkProductRedis(sku = '') {
    return `/products/${sku}/cached`
}