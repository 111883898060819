import React from 'react';
import { Row, Col, Typography, Divider, Tabs, Checkbox, Descriptions } from 'antd';
import { Image } from 'antd';
import { useParams } from 'react-router-dom';
import { Avatar } from 'antd';
import { GetRequest, GetRequestInternalAPI } from "../../components/backend/getRequest"
import { ErrorRow } from "../../components/errorBlock"
import { Spinner, SpinnerInline } from "../../components/spinners"
import { Space } from 'antd';
import { actionOnError, errorTranslation, getBackendLink } from "../../components/backend/backend"
import { useEffect, useState } from "react";
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import { BreadcrumbsAdminUsersEdit } from "../../components/breadcrumbs/breadcrumbsAdmin";
import { GrantsList } from "../../components/grantsList";
import { PostRequest } from "../../components/backend/postRequest";
import { message } from 'antd';
import { Helmet } from "react-helmet";
import { TimeFromSecondsSafe } from '../../components/dateAndTime';
import { GetTagFromBooleanYesNo } from '../../components/formattingValues';

export const AdminUserEditV2 = () => {
    const { id } = useParams();

    const [profileData, setProfileData] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [err, setErr] = useState("");

    const [messageApi, contextHolder] = message.useMessage();
    const messageKey = 'savingmessage';
    const loadingMessage = () => {
        messageApi.open({
            messageKey,
            type: 'loading',
            content: 'Saving...',
            duration: 100,
        });
    };

    const fetchData = () => {
        GetRequestInternalAPI(`/api/v1/users/admin/get?user_id=${id}`,
            setIsFetching,
            setErr,
            null,
        )
            .then(
                (result) => {
                    setProfileData(result.data)
                }

            )
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);


    const [accessGrants, setAccessGrants] = useState([]);
    const [grantsFetching, setGrantsFetching] = useState(false);
    const [allGrantsDisabled, setAllGrantsDisabled] = useState(false);
    const [errGrants, setErrGrants] = useState("");
    const fetchGrants = () => {
        GetRequestInternalAPI(`/api/v1/users/admin/grants/get?user_id=${id}`,
            setGrantsFetching,
            setErrGrants,
            null,
        )
            .then(
                (result) => {
                    if (result.accessGrants !== null && result.accessGrants !== undefined) {
                        setAccessGrants(result.accessGrants)
                    }
                }
            )
    }

    useEffect(() => {
        fetchGrants();
        // eslint-disable-next-line
    }, []);

    const fetchDataSilently = () => {
        GetRequest(getBackendLink() + `/api/v1/users/admin/get?user_id=${id}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)

                        setErr(errorTranslation(result.error))
                    } else {
                        setProfileData(result.result.data)
                        if (result.result.accessGrants !== null && result.result.accessGrants !== undefined) {
                            setAccessGrants(result.result.accessGrants)
                        }

                        messageApi.destroy()
                        NotificationSuccess('Saved')
                        setAllGrantsDisabled(false)
                    }
                },
                (error) => {
                    setErr(errorTranslation(error.message))
                    messageApi.destroy()
                }
            )
    }

    const onChange = (e) => {
        loadingMessage()
        setAllGrantsDisabled(true)

        let url = ""
        if (e.target.checked === true) {
            url = `/api/v1/users/admin/grants/add`
        }
        if (e.target.checked === false) {
            url = `/api/v1/users/admin/grants/remove`
        }

        PostRequest(getBackendLink() + url, {
            grant_id: e.target.name,
            user_id: id,
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)

                        NotificationError('Error', errorTranslation(result.error))
                        setAllGrantsDisabled(false)
                        messageApi.destroy()
                    } else {
                        fetchDataSilently()
                    }
                },
                (error) => {
                    NotificationError('Error', errorTranslation(error.message))
                    setAllGrantsDisabled(false)
                    messageApi.destroy()
                }
            )

    };

    let avatar = <SpinnerInline />
    let profileName = <SpinnerInline />
    let grants = <SpinnerInline />
    let basicData = <><Avatar shape="square" size={250} loading /></>


    if (!isFetching) {
        if (err === "") {
            profileName = `${profileData.FirstName} ${profileData.LastName}`

            if (profileData.AvatarLink !== "") {
                avatar = <Avatar shape="square" size={250}
                    src={<Image src={profileData.AvatarLink} style={{ width: "100%" }} />} />
            } else {
                <Avatar shape="square" size={250} src={undefined}>{profileData.FirstName}</Avatar>
            }

            basicData = <>
                <Row>
                    <Col xs={24} xl={6}>
                        {avatar}
                    </Col>
                    <Col xs={24} xl={18}>
                        <Descriptions>
                            <Descriptions.Item label="ID">
                                <Typography.Text
                                    copyable={{ text: profileData.ID }}
                                    level={3} style={{ margin: 0 }}>
                                    {profileData.ID}
                                </Typography.Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Name">{profileData.FirstName} {profileData.LastName}</Descriptions.Item>
                            <Descriptions.Item label="Login">{profileData.Login}</Descriptions.Item>
                            <Descriptions.Item label="Corp email">{profileData.BHEmail}</Descriptions.Item>
                            <Descriptions.Item label="Gmail">{profileData.Gmail}</Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="Created"><TimeFromSecondsSafe time={profileData.CreatedAt} /></Descriptions.Item>
                            <Descriptions.Item label="Updated"><TimeFromSecondsSafe time={profileData.UpdatedAt} /></Descriptions.Item>

                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="Can login">{GetTagFromBooleanYesNo(profileData.LoginAllowed)}</Descriptions.Item>
                            <Descriptions.Item label="Login via password">{GetTagFromBooleanYesNo(profileData.LoginAllowedPlaintext)}</Descriptions.Item>
                            <Descriptions.Item label="Login via corp email">{GetTagFromBooleanYesNo(profileData.LoginAllowedBHEmail)}</Descriptions.Item>
                            <Descriptions.Item label="Last login"><TimeFromSecondsSafe time={profileData.LastLogin} /></Descriptions.Item>
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="Password strength">{profileData.PasswordStrength}</Descriptions.Item>
                            <Descriptions.Item label="Password updated"><TimeFromSecondsSafe time={profileData.PasswordUpdatedAt} /></Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </>
        } else {
            profileName = <>{id}</>
        }
    }

    if (!grantsFetching) {
        if (errGrants === "") {
            grants = AccessGrants(accessGrants, allGrantsDisabled, onChange)
        } else {
            grants = <></>
        }
    }

    return (
        <>
            {contextHolder}
            <Helmet>
                <title>{`Edit user #${id}`} || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsAdminUsersEdit id={profileName} />
                    </Col>
                </Row>
                <ErrorRow err={err} />
                <Row>
                    <Col xs={24} xl={24}>
                        {isFetching ? <Spinner /> : <></>}
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        {isFetching || err !== "" ? <></> :
                            <Typography.Title
                                copyable={{ text: profileData.FirstName + " " + profileData.LastName }}
                                level={3} style={{ margin: 0 }}>
                                {profileData.FirstName} {profileData.LastName}
                            </Typography.Title>
                        }
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Tabs
                            defaultActiveKey="BasicData"
                            items={[
                                {
                                    label: 'Basic Data',
                                    key: 'BasicData',
                                    children: basicData,
                                },
                                {
                                    label: 'Access',
                                    key: 'Access',
                                    children: grants,
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export function GetFriendImg(link, avatar) {
    if (avatar !== "") {
        return <a href={link}>
            <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} shape="square" src={avatar} />
        </a>
    }
    return <a href={link}>
        <Avatar size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} shape="square" />
    </a>
}


export function AccessGrants(userGrants = [], allGrantsDisabled = false, onChange) {
    let activeUserGrants = {}

    userGrants.forEach((grant) => {
        activeUserGrants[grant.GrantID] = true;
    }
    );

    return (
        <>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <h3>Products</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Products.See.id}
                        checked={activeUserGrants[GrantsList.Products.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Products.See.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <h3>Products: campaigns</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Products.Campaigns.See.id}
                        checked={activeUserGrants[GrantsList.Products.Campaigns.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Products.Campaigns.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Products.Campaigns.Create.id}
                        checked={activeUserGrants[GrantsList.Products.Campaigns.Create.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Products.Campaigns.Create.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Products.Campaigns.Edit.id}
                        checked={activeUserGrants[GrantsList.Products.Campaigns.Edit.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Products.Campaigns.Edit.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Products.Campaigns.Delete.id}
                        checked={activeUserGrants[GrantsList.Products.Campaigns.Delete.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Products.Campaigns.Delete.name}
                    </Checkbox>
                </Col>
            </Row>


            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <Col xs={24} xl={24}>
                        <h3>Notifications</h3>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Checkbox onChange={onChange} name={GrantsList.Notifications.Events.See.id}
                            checked={activeUserGrants[GrantsList.Notifications.Events.See.id]}
                            disabled={allGrantsDisabled}>
                            {GrantsList.Notifications.Events.See.name}
                        </Checkbox>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Checkbox onChange={onChange} name={GrantsList.Notifications.Events.Resolve.id}
                            checked={activeUserGrants[GrantsList.Notifications.Events.Resolve.id]}
                            className={"red-checkbox"}
                            disabled={allGrantsDisabled}>
                            {GrantsList.Notifications.Events.Resolve.name}
                        </Checkbox>
                    </Col>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <h3>Statistic</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Statistic.See.id}
                        checked={activeUserGrants[GrantsList.Statistic.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Statistic.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Statistic.Logs.See.id}
                        checked={activeUserGrants[GrantsList.Statistic.Logs.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Statistic.Logs.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Statistic.Logs.Add.id}
                        checked={activeUserGrants[GrantsList.Statistic.Logs.Add.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Statistic.Logs.Add.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <h3>Users</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Users.See.id}
                        checked={activeUserGrants[GrantsList.Users.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Users.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Users.Add.id}
                        checked={activeUserGrants[GrantsList.Users.Add.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Users.Add.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Users.Edit.id}
                        checked={activeUserGrants[GrantsList.Users.Edit.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Users.Edit.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Users.Remove.id}
                        checked={activeUserGrants[GrantsList.Users.Remove.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Users.Remove.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Users.Admin.id}
                        checked={activeUserGrants[GrantsList.Users.Admin.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Users.Admin.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Users.ChangePassword.id}
                        checked={activeUserGrants[GrantsList.Users.ChangePassword.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Users.ChangePassword.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Users.EnableDisable.id}
                        checked={activeUserGrants[GrantsList.Users.EnableDisable.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Users.EnableDisable.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <h3>Security</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Security.Logs.See.id}
                        checked={activeUserGrants[GrantsList.Security.Logs.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Security.Logs.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Security.Logs.Add.id}
                        checked={activeUserGrants[GrantsList.Security.Logs.Add.id]}
                        className={"orange-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Security.Logs.Add.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Security.IP.See.id}
                        checked={activeUserGrants[GrantsList.Security.IP.See.id]}
                        className={"orange-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Security.IP.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Security.IP.Block.id}
                        checked={activeUserGrants[GrantsList.Security.IP.Block.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Security.IP.Block.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <h3>Integrations</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Integrations.See.id}
                        checked={activeUserGrants[GrantsList.Integrations.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Integrations.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Integrations.Admin.id}
                        checked={activeUserGrants[GrantsList.Integrations.Admin.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Integrations.Admin.name}
                    </Checkbox>
                </Col>
            </Row>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <h3>Slim4</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Integrations.Slim4.See.id}
                        checked={activeUserGrants[GrantsList.Integrations.Slim4.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Integrations.Slim4.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Integrations.Slim4.Admin.id}
                        checked={activeUserGrants[GrantsList.Integrations.Slim4.Admin.id]}
                        className={"orange-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Integrations.Slim4.Admin.name}
                    </Checkbox>
                </Col>

                <Col xs={24} xl={12}>
                    <h3>MoreFlo</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Integrations.MoreFlo.See.id}
                        checked={activeUserGrants[GrantsList.Integrations.MoreFlo.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Integrations.MoreFlo.See.name}
                    </Checkbox>
                </Col>
            </Row>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <h3>Developer</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Developer.Info.See.id}
                        checked={activeUserGrants[GrantsList.Developer.Info.See.id]}
                        className={"orange-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Developer.Info.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Developer.Info.Write.id}
                        checked={activeUserGrants[GrantsList.Developer.Info.Write.id]}
                        className={"orange-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Developer.Info.Write.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Developer.Events.Create.id}
                        checked={activeUserGrants[GrantsList.Developer.Events.Create.id]}
                        className={"orange-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Developer.Events.Create.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Developer.Events.Resolve.id}
                        checked={activeUserGrants[GrantsList.Developer.Events.Resolve.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Developer.Events.Resolve.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Developer.Admin.id}
                        checked={activeUserGrants[GrantsList.Developer.Admin.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Developer.Admin.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <h3>Administration</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Administration.Administrator.id}
                        checked={activeUserGrants[GrantsList.Administration.Administrator.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Administration.Administrator.name}
                    </Checkbox>
                </Col>
            </Row>

            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <h3>Access grants</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Grants.See.id}
                        checked={activeUserGrants[GrantsList.Grants.See.id]}
                        className={"orange-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Grants.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Grants.Add.id}
                        checked={activeUserGrants[GrantsList.Grants.Add.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Grants.Add.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Grants.Remove.id}
                        checked={activeUserGrants[GrantsList.Grants.Remove.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Grants.Remove.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Grants.Admin.id}
                        checked={activeUserGrants[GrantsList.Grants.Admin.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Grants.Admin.name}
                    </Checkbox>
                </Col>
                <Col xs={24} xl={12}>
                    <h3>Session</h3>
                    <Space size={[0, 8]} wrap>
                        <Checkbox onChange={onChange} name={GrantsList.Session.See.id}
                            checked={activeUserGrants[GrantsList.Session.See.id]}
                            className={"orange-checkbox"}
                            disabled={allGrantsDisabled}>
                            {GrantsList.Session.See.name}
                        </Checkbox>
                        <Checkbox onChange={onChange} name={GrantsList.Session.Close.id}
                            checked={activeUserGrants[GrantsList.Session.Close.id]}
                            className={"red-checkbox"}
                            disabled={allGrantsDisabled}>
                            {GrantsList.Session.Close.name}
                        </Checkbox>
                    </Space>
                </Col>
            </Row>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <h3>AccessToken</h3>
                    <Checkbox onChange={onChange} name={GrantsList.AccessToken.See.id}
                        checked={activeUserGrants[GrantsList.AccessToken.See.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.AccessToken.See.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.AccessToken.Add.id}
                        checked={activeUserGrants[GrantsList.AccessToken.Add.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.AccessToken.Add.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.AccessToken.Expire.id}
                        checked={activeUserGrants[GrantsList.AccessToken.Expire.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.AccessToken.Expire.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.AccessToken.Revoke.id}
                        checked={activeUserGrants[GrantsList.AccessToken.Revoke.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.AccessToken.Revoke.name}
                    </Checkbox>
                </Col>
            </Row>

            <Divider>Technical grants (don't use on users)</Divider>
            <Row className={"bottom-1em top-1em"}>
                <Col xs={24} xl={12}>
                    <h3>Session</h3>
                    <Checkbox onChange={onChange} name={GrantsList.Session.Create.id}
                        checked={activeUserGrants[GrantsList.Session.Create.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Session.Create.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Session.Update.id}
                        checked={activeUserGrants[GrantsList.Session.Update.id]}
                        className={"red-checkbox"}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Session.Update.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Notifications.Messages.Push.id}
                        checked={activeUserGrants[GrantsList.Notifications.Messages.Push.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Notifications.Messages.Push.name}
                    </Checkbox>
                    <Checkbox onChange={onChange} name={GrantsList.Notifications.Events.Create.id}
                        checked={activeUserGrants[GrantsList.Notifications.Events.Create.id]}
                        disabled={allGrantsDisabled}>
                        {GrantsList.Notifications.Events.Create.name}
                    </Checkbox>
                </Col>
            </Row>
        </>
    )
}