import { Alert, Col, Descriptions, Row, Table, Typography } from "antd";
import React from "react";
import "swagger-ui-react/swagger-ui.css"
import { BreadcrumbsDeveloperServicesRunsRun } from "../../../../components/breadcrumbs/breadcrumbsDeveloper";
import { GetDefaultAfter, GetDefaultBefore, TimeFromSeconds } from "../../../../components/dateAndTime";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { EventLevelsMap, EventSourcesMap } from "../../../../components/getEventsData";
import { IsCrititcal, IsDanger, IsError, IsWarning } from "../../../../components/checkingValues";
import { GetRequest, GetRequestInternalAPI } from "../../../../components/backend/getRequest";
import { actionOnError, getBackendLink } from "../../../../components/backend/backend";
import { NotificationError } from "../../../../components/notifications";
import { useEffect } from "react";
import { ErrorRow } from "../../../../components/errorBlock";
import { GetApplicationRunHref, GetUserLink } from "../../../../components/getPageLinks";
import JSONPretty from "react-json-pretty";
import { Helmet } from "react-helmet";
const JSONPrettyMon = require('react-json-pretty/dist/monikai');

const columns = [
    {
        title: 'Time',
        dataIndex: 'Time',
        key: 'Time',
        render: (_, record) => (
            <><TimeFromSeconds seconds={record.Timing.CreatedAt.seconds * 1000} /></>
        ),
    },
    {
        title: 'Source',
        dataIndex: 'logSource',
        key: 'logSource',
        render: (_, record) => (
            <>{record.LogSource !== undefined && record.LogSource !== null ? EventSourcesMap[record.LogSource].tag : <>???</>}</>
        ),
    },
    {
        title: 'Level',
        dataIndex: 'logLevel',
        key: 'logLevel',
        render: (_, record) => (
            <>{record.LogLevel !== undefined && record.LogLevel !== null ? EventLevelsMap[record.LogLevel].tag : <>???</>}</>
        ),
    },
    {
        title: 'Text',
        dataIndex: 'text',
        key: 'text',
        render: (_, record) => (
            IsWarning(record.LogLevel)
                ? <Alert message={record.Text} type="warning" />
                :
                IsError(record.LogLevel) || IsDanger(record.LogLevel) || IsCrititcal(record.LogLevel) ?
                    <Alert message={record.Text} type="error" />
                    : <>{record.Text}</>
        ),
    },
    {
        title: 'Author',
        dataIndex: 'LogAuthor',
        key: 'LogAuthor',
        render: (_, record) => (
            <>{record.Author !== "" ? <><a href={GetUserLink(record.Author)}>{record.AuthorName ? record.AuthorName : record.Author}</a></> :
                <></>}</>
        ),
    },
];

export const Run = () => {
    const { id } = useParams();

    const [err, setErr] = useState("");
    const [data, setData] = useState({ StartedAt: {} });
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLogsLoading(true);
        GetRequestInternalAPI(`/api/v1/dev/services/runs/get?run_id=${id}`, setLoading, setErr, null)
            .then(
                (result = {}) => {
                    if (result.record !== null && result.record !== undefined) {
                        setData(result.record)
                    }
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const [logsErr, setLogsErr] = useState("");
    const [logsData, setLogsData] = useState();
    const [logsLoading, setLogsLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [before] = useState(GetDefaultBefore());
    const [after] = useState(GetDefaultAfter());

    const fetchLogsData = () => {
        setLogsLoading(true);
        GetRequest(getBackendLink() + `/api/v1/logs/get_list?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&before=${before}&after=${after}&run_id=${id}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setLogsErr(result.error)
                    } else {
                        setLogsErr("")
                        setLogsData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLogsLoading(false);
                },
                (error) => {
                    setLogsLoading(false);
                    setLogsErr(error.message)
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchLogsData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setLogsData([]);
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Application run #${id}`} || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperServicesRunsRun loading={loading} id={id}
                            time={<TimeFromSeconds seconds={data.StartedAt.seconds * 1000} />} />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Descriptions>
                    <Descriptions.Item label="Run ID">
                        <Typography.Text
                            copyable={{ text: GetApplicationRunHref(data.RunID) }}>
                            {data.RunID}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Started at">{data.StartedAt !== undefined && data.StartedAt !== null ? <TimeFromSeconds seconds={data.StartedAt.seconds * 1000} /> : <></>}</Descriptions.Item>
                    <Descriptions.Item label="Done">
                        <Typography.Text>
                            {data.IsStopped} {data.IsStopped === true ? <>({data.Result})</> : <></>}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Done at">
                        <Typography.Text>
                            {data.StoppedAt !== undefined && data.StoppedAt !== null ? <TimeFromSeconds seconds={data.StoppedAt.seconds * 1000} /> : <></>}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Application">
                        <Typography.Text>
                            {data.SourceID}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">
                        <Typography.Text>
                            {data.SourceType}
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Metadata">
                        <Typography.Text>
                            <JSONPretty id="json-pretty" data={data.Metadata} theme={JSONPrettyMon} />
                        </Typography.Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Initial message">
                        <Typography.Text>
                            {data.Text}
                        </Typography.Text>
                    </Descriptions.Item>
                </Descriptions>
            </div>
            <div className="container-1700">
                <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} xl={24}>
                        {
                            logsErr !== ""
                                ?
                                <ErrorRow err={err} prefix={"Runs"} />
                                :
                                <Table
                                    columns={columns}
                                    rowKey={(record) => record.product}
                                    dataSource={logsData}
                                    pagination={tableParams.pagination}
                                    loading={logsLoading}
                                    size={"small"}
                                    onChange={handleTableChange}
                                />
                        }

                    </Col>
                </Row>
            </div>

        </>
    )

}