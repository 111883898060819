import React, { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { Table, Tag } from "antd";
import { ErrorRow } from "../../components/errorBlock";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { FormatShortUUID } from "../../components/formattingValues";

export const SecuritySessionsClosed = (props) => {
    const [loadingSessionsClosedData, setLoadingSessionsClosedData] = useState(false);
    const [sessionsClosedData, setSessionsClosedData] = useState([]);
    const [sessionsClosedErr, setSessionsClosedErr] = useState("");
    const [sessionsClosedTableParams, setSessionsClosedTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleSessionsClosedTableChange = (pagination, filters, sorter) => {
        setSessionsClosedTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== sessionsClosedTableParams.pagination?.pageSize) {
            setSessionsClosedData([]);
        }
    };

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/sessions/self/gel_list/closed?page=${sessionsClosedTableParams.pagination.current}&size=${sessionsClosedTableParams.pagination.pageSize}`,
            setLoadingSessionsClosedData,
            setSessionsClosedErr,
            null
        ).then(
            (result) => {
                setSessionsClosedData(result.Records)
                setSessionsClosedTableParams({
                    ...sessionsClosedTableParams,
                    pagination: {
                        ...sessionsClosedTableParams.pagination,
                        total: result.Total,
                    },
                });
            }
        )
        // eslint-disable-next-line
    }, [sessionsClosedTableParams.pagination.pageSize, sessionsClosedTableParams.pagination.current]);

    const columnsSessionsClosed = [
        {
            title: 'ID',
            dataIndex: 'SessionID',
            key: 'SessionID',
            render: (_, record) => (
                <>{record.SessionID === props.token.SessionID ? <>{FormatShortUUID(record.SessionID)} <Tag
                    color={'lime'}>current</Tag></> : <>{FormatShortUUID(record.SessionID)}</>}</>
            ),
        },
        {
            title: 'Address',
            dataIndex: 'IPAddr',
            key: 'IPAddr',
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'Location',
            render: (_, record) => (
                <>{record.Country !== "" && record.Country !== undefined ? record.Country : <>unknown</>}{record.Location !== "" && record.Location !== undefined ? <>, {record.Location}</> : <>, unknown</>}</>
            ),
        },
        {
            title: 'OS',
            dataIndex: 'OS',
            key: 'OS',
            render: (_, record) => (
                <>{`${record.OS} ${record.OSVersion}`}</>
            ),
        },
        {
            title: 'Browser',
            dataIndex: 'Browser',
            key: 'Browser',
            render: (_, record) => (
                <>{`${record.BrowserName} ${record.BrowserVersion}`}</>
            ),
        },
        {
            title: 'Started',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: (_, record) => (
                <>{record.CreatedAt ? <TimeFromSeconds seconds={record.CreatedAt.seconds * 1000} /> : <></>}</>
            ),
        },
        {
            title: 'Ended',
            dataIndex: 'EndedAt',
            key: 'EndedAt',
            render: (_, record) => (
                <>{record.EndedAt ? <TimeFromSeconds seconds={record.EndedAt.seconds * 1000} /> : <></>}</>
            ),
        },
        {
            title: 'Sign Method',
            dataIndex: 'SignInMethod',
            key: 'SignInMethod',
            render: (_, record) => (
                <>{record.SignInMethod === "internal" ? <Tag color={'volcano'}>Login & password</Tag> :
                    <Tag color={'lime'}>Microsoft Auth</Tag>}</>
            ),
        },
        {
            title: 'Not my computer',
            dataIndex: 'NotMyDevice',
            key: 'NotMyDevice',
            render: (_, record) => (
                <>{record.NotMyDevice === true ? <Tag color={'volcano'}>YES</Tag> : <></>}</>
            ),
        },
    ];

    if (sessionsClosedErr !== "") {
        return <ErrorRow prefix={"Closed sessions"} err={sessionsClosedErr} />
    }

    return (
        <>
            <Table
                columns={columnsSessionsClosed}
                rowKey={(record) => record.ID}
                dataSource={sessionsClosedData}
                pagination={sessionsClosedTableParams.pagination}
                loading={loadingSessionsClosedData}
                size={"small"}
                onChange={handleSessionsClosedTableChange}
            />
        </>
    )

}

