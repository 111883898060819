import Cookies from 'universal-cookie';
import { getCookieDomain } from "./backend"

export function UserToken(token = '') {
    const cookies = new Cookies();
    cookies.set('userToken', token, { path: '/' });
}

export function removeToken() {
    const cookies = new Cookies();
    cookies.remove('userToken', { domain: getCookieDomain(), path: "/" })
}