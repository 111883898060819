import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Spin } from "antd";
import { ConfigProvider } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import enGB from 'antd/lib/locale/en_GB';

const root = ReactDOM.createRoot(document.getElementById('root'));
Spin.setDefaultIndicator(<LoadingOutlined spin />)
TimeAgo.addDefaultLocale(en)

root.render(
  <React.StrictMode>
    <ConfigProvider
      locale={enGB}
      theme={{
        token: {
          "fontSize": 16, "fontSizeSM": 16
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
