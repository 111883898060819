import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Spin, Checkbox, Form, Input, Alert, Col, Row, Image } from 'antd';
import Cookies from 'universal-cookie';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { ParseUserToken } from "../components/backend/parseUserToken"
import { PostRequest } from "../components/backend/postRequest"
import {
    errorTranslation,
    getBackendLink,
    getCookieDomain, getMicrosoftClientID,
    getMicrosoftRediredURI
} from "../components/backend/backend"
import { PublicClientApplication } from "@azure/msal-browser";
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

const msalConfig = {
    auth: {
        clientId: getMicrosoftClientID(),
        redirectUri: getMicrosoftRediredURI()
    }
};

async function InitMS() {
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize()
    return msalInstance
}

export const Login = () => {
    const [hasError, setHasError] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState("");
    const [msalInstance, setMsalInstance] = useState(new PublicClientApplication(msalConfig));
    const [form] = Form.useForm();
    const alienValue = Form.useWatch('ualien', form);

    useEffect(() => {
        InitMS()
            .then((res) => {
                setMsalInstance(res)
            })
            .catch((err) => {
                console.error('Microsoft Error:', err);
            });
    }, []);

    const sendCredentials = (login = "", password = "", TokenBytes, alien = false, UserProvider = "internal") => {
        setIsFetching(true)
        PostRequest(getBackendLink() + '/api/v1/sessions/login', {
            "login": login,
            "password": password,
            "TokenBytes": TokenBytes,
            "alien": alien,
            "BrowserName": browserName,
            "BrowserVersion": browserVersion,
            "OS": osName,
            "OSVersion": osVersion,
            "UserProvider": UserProvider,
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        setHasError(true)
                        setError(result.error)

                        if (result.error === "not_found") {
                            setError("Wrong login or password")
                        }
                        if (result.error === "incorrect_credentials") {
                            setError("Wrong login or password")
                        }
                        if (result.error === "login_not_allowed") {
                            setError("Login method not allowed or user blocked")
                        }
                        if (result.error === "ip_blocked") {
                            setError("IP address blocked")
                        }
                    } else {
                        const cookies = new Cookies();
                        cookies.set('userToken', result.token, {
                            domain: getCookieDomain(),
                            path: '/',
                            sameSite: 'none',
                            secure: true
                        });
                        let parsed = ParseUserToken()
                        window.location.href = "/profile/" + parsed.ID
                    }
                    setIsFetching(false)
                },
                (error) => {
                    setHasError(true)
                    setError(error.message)
                    setIsFetching(false)
                }
            )
    }

    const onFinish = (values) => {
        sendCredentials(values.username, values.password, "", values.ualien, "internal")
    }

    return (
        <>
            <Helmet>
                <title>Login || Bangerhead Admin</title>
            </Helmet>
            {hasError
                ?
                <Row style={{ "marginTop": "1em" }}>
                    <Col span={8} offset={8}>
                        <Alert
                            message="Error"
                            description={errorTranslation(error)}
                            type="error"
                        />
                    </Col>
                </Row>
                :
                <>
                </>
            }
            <div className="container-1700">
                <Row>
                    <Col xs={0} xl={10}>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Image
                            width={200}
                            preview={false}
                            src="/logo_transparent_long.png"
                        />
                    </Col>
                </Row>
            </div>

            <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Spin spinning={isFetching}>
                    <div className="container-1700">
                        <Row>
                            <Col xs={0} xl={6}>
                            </Col>
                            <Col xs={24} xl={12}>
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Login can not be empty' }]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />}
                                        placeholder="Username" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Password can not be empty' }]}
                                >
                                    <Input.Password placeholder="Password"
                                        prefix={<LockOutlined className="site-form-item-icon" />} />

                                </Form.Item>
                                <Form.Item>
                                    <Form.Item style={{ "float": "left" }} name="ualien" valuePropName="checked" noStyle>
                                        <Checkbox>NOT my device</Checkbox>
                                    </Form.Item>

                                    <a style={{ "float": "right" }} className="login-form-forgot"
                                        href="/restore_password">
                                        Restore password
                                    </a>
                                </Form.Item>

                                <Form.Item>
                                    <Button style={{ "width": "100%" }} type="primary" htmlType="submit"
                                        className="login-form-button">
                                        Login
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="container-1700">
                        <Row>
                            <Col xs={0} xl={6}>
                            </Col>
                            <Col xs={24} xl={12}>
                                <Form.Item>
                                    <img alt={""}
                                        src={"https://learn.microsoft.com/en-us/entra/identity-platform/media/howto-add-branding-in-apps/ms-symbollockup_signin_light.svg"}
                                        onClick={() => {
                                            msalInstance.loginPopup({
                                                scopes: ["email"]
                                            }).then(result => {
                                                sendCredentials(result.account.username, "-", result.idToken, alienValue, "microsoft")
                                            }).catch(err => {
                                                setHasError(true)
                                                setError(err.message)
                                            })
                                        }}
                                        style={{ cursor: "pointer" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Spin>
            </Form>
        </>
    )

}