import React from 'react';
import { Alert, Button, Col, Form, Row, Space, Table, Tour } from "antd";
import { ErrorRow } from "../../components/errorBlock";
import { useState, useRef } from 'react';
import { actionOnError, getBackendLink } from "../../components/backend/backend";
import { NotificationError } from "../../components/notifications";
import { PostRequest, PostRequestInternalAPI } from "../../components/backend/postRequest";
import {
    InputNumber,
    Select,
} from 'antd';
import { message } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
    BreadcrumbsCampaignsCreate
} from "../../components/breadcrumbs/breadcrumbsCampaigns";
import { StringifiedCategoryFromProdBrandModelLevel1, StringifiedCategoryFromProdBrandModelLevel2, StringifiedCategoryFromProdBrandModelLevel3 } from "../../components/product";
import { BrandsSelect } from "../../subpages/products/brands";
import { Helmet } from "react-helmet";
import { GetCampaignLink } from "../../components/getPageLinks";
import { DateAndTime } from "../../components/dateAndTime";
import { CountriesSelect } from '../../subpages/products/countries';

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 24,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 24,
            offset: 0,
        },
    },
};

export const CampaignCreateV2 = () => {
    const refBrand = useRef(null);
    const refDates = useRef(null);
    const refMarketsInclude = useRef(null);
    const refProducts = useRef(null);
    const refCats = useRef(null);
    const refCreate = useRef(null);
    const [open, setOpen] = useState(false);

    const tourSteps = [
        {
            title: 'Choose brand',
            description: 'The first step is to choose a brand. After choosing or changing brand, items & categories will appear',
            target: () => refBrand.current,
        },
        {
            title: 'Pick dates',
            description: 'Dates are necessary',
            target: () => refDates.current,
        },
        {
            title: 'Include markets',
            description: 'Empty markets = use all available. Some (like DE) will not create any campaign for now',
            target: () => refMarketsInclude.current,
        },
        {
            title: 'Pick products',
            description: 'Blank = use all products for the brand (except those in the Exclude field)',
            target: () => refProducts.current,
        },
        {
            title: 'Categories',
            description: 'Blank = Create one campaign per category + market pair',
            target: () => refCats.current,
        },
        {
            title: 'Press Create',
            description: 'Campaigns may take up to 20 seconds to create. After that, you will see the following list of campaigns or errors',
            target: () => refCreate.current,
        },
    ];

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        messageApi.open({
            type: 'loading',
            content: 'Sending requests...',
            duration: 0,
        });
    };

    const [form] = Form.useForm();

    const [counts, setCounts] = useState([]);
    const [startDate, setAfter] = useState();
    const [dueDate, setBefore] = useState();
    const [prodsErr, setProdsErr] = useState('');
    const [prodsIn, setProdsIn] = useState();
    const [prodsEx, setProdsEx] = useState();
    const [markets, setMarkets] = useState([]);
    const [prodsLoading, setProdsLoading] = useState(false);

    const fetchProds = (brand) => {
        PostRequestInternalAPI(`/api/v1/products/list_for_campaign`,
            {
                brand: brand,
            },
            setProdsLoading,
            setProdsErr,
            null
        )
            .then(
                (result) => {
                    let newProdsIn = []
                    let newProdsEx = []
                    result.Records.forEach((element) => {
                        element.ItemSKU = element.Product.ItemSKU
                        newProdsIn.push(element)
                        newProdsEx.push(element)
                    }
                    );
                    setProdsIn(newProdsIn);
                    setProdsEx(newProdsEx);
                    setAvailableCats(result.Records)
                }
            )
    };

    const [cats, setCats] = useState();


    const setAvailableCats = (products = []) => {
        let newCats = []
        if (Array.isArray(products)) {
            products.forEach((element) => {
                newCats.push(
                    {
                        "ID": element.Categories.Cat1ID,
                        "Stringified": (StringifiedCategoryFromProdBrandModelLevel1(element.Categories)),
                    },
                    {
                        "ID": element.Categories.Cat2ID,
                        "Stringified": (StringifiedCategoryFromProdBrandModelLevel2(element.Categories)),
                    },
                    {
                        "ID": element.Categories.Cat3ID,
                        "Stringified": (StringifiedCategoryFromProdBrandModelLevel3(element.Categories)),
                    }
                )

                setCats(deduplicate(newCats));

            }
            );
        }
    }

    const deduplicate = (array) => {
        const seen = new Set();
        return array.filter(item => {
            if (item === undefined || item === null || item.Stringified === "" || item.ID === 0 || item.ID === undefined || item.ID === null) {
                return false;
            }

            const itemString = JSON.stringify(item);
            if (seen.has(itemString)) {
                return false;
            } else {
                seen.add(itemString);
                return true;
            }
        });
    };

    const [results, setResults] = useState([]);

    const onCreateButtonPushed = (values) => {
        let marketsToUse = []

        if (markets.length === 0 || markets === undefined) {
            counts.forEach((element) => {
                if (element.ID !== 255 || element.ID !== 251 || element.ID !== 245 || element.ID !== 252) {
                    marketsToUse.push(element.ID)
                }
            })
        } else {
            marketsToUse = markets
        }

        if (values.CategoriesInclude === undefined) {
            values.CategoriesInclude = []
        }

        if (values.ProductsInclude === undefined) {
            values.ProductsInclude = []
        }

        if (values.ProductsExclude === undefined) {
            values.ProductsExclude = []
        }

        if (values.DiscountPercent === undefined) {
            values.DiscountPercent = "0"
        }

        marketsToUse.forEach((market) => {
            // Initial filter to avoid some placeholder markets.
            // TODO: refactor to filter on the market getter stage.
            if (market === 255 || market === 251 || market === 245 || market === 252) {
                messageApi.destroy()

                return
            }

            success()

            PostRequest(getBackendLink() + `/api/v1/products/campaigns/v2/add`,
                {
                    "brand_id": values.BrandID,
                    "market": market,
                    "categories_include": values.CategoriesInclude,
                    "categories_exclude": values.CategoriesExclude,
                    "start_date": startDate,
                    "due_date": dueDate,
                    "discount_percent": values.DiscountPercent,
                    "skus_include": values.ProductsInclude,
                    "skus_exclude": values.ProductsExclude,
                }
            )
                .then(
                    (result) => {
                        if (result.success !== true) {
                            actionOnError(result.error)

                            setResults(results => [...results, {
                                "CampaignName": `${startDate} - ${counts.find(record => record.ID === market).Name} - ${values.DiscountPercent}% - ${values.BrandID}`,
                                "Error": result.error,
                            }]);

                            messageApi.destroy()
                        } else {
                            let name = result.result.CampaignName

                            if (name === undefined || name === null || name === "") {
                                name = `${startDate} - ${counts.find(record => record.ID === market).Name} - ${values.DiscountPercent}% - ${values.BrandID}`
                            }


                            setResults(results => [...results, {
                                "CampaignID": result.result.CampaignID,
                                "CampaignName": name,
                                "NumberOfProducts": result.result.NumberOfProducts,
                                "Error": result.result.Error,
                                "Warning": result.result.Warning,
                            }]);

                            messageApi.destroy()
                        }
                    },
                    (error) => {
                        setResults(results => [...results, {
                            "Error": error.message,
                        }]);

                        messageApi.destroy()
                        NotificationError('Error', error.message)
                    }
                )
        }
        )
    }

    const changeBrand = (brand) => {
        form.setFieldsValue({
            ProductsExclude: [],
            ProductsInclude: [],
            CategoriesInclude: [],
            CategoriesExclude: [],
        });

        fetchProds(brand)
    }

    const filterOptionProds = (input, option) => (option?.Product.ItemSKU ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <Helmet>
                <title>Campaign constructor || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsCampaignsCreate />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                {contextHolder}
                <Row className={"bottom-1em"}
                    style={{
                        marginBottom: 16,
                    }}>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpen(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                About this page <QuestionCircleOutlined />
                            </Button>
                            <Button href={"/campaigns/v2"}
                                type="primary"
                            >
                                Campaign list
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <ErrorRow err={prodsErr} prefix={"Products"} />
                <Form
                    name="create_campaign"
                    {...formItemLayout}
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onCreateButtonPushed}
                >
                    <Row className={"bottom-1em"}>
                        <Col xs={24} xl={8}
                            ref={refBrand}
                        >
                            <Form.Item
                                name={`BrandID`}
                                label={`Brand`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select Brand',
                                    },
                                ]}
                            >
                                <BrandsSelect
                                    onChange={changeBrand}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                name="DiscountPercent"
                                label="Discount"
                            >
                                <InputNumber
                                    defaultValue="0"
                                    min="-100"
                                    max="100"
                                    step="0.01"
                                    stringMode
                                    addonAfter="%"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}
                            ref={refDates}
                        >
                            <Form.Item label="Dates" name="Dates">
                                <DateAndTime setBefore={setBefore} setAfter={setAfter} noDefaults={true} disableBeforeYesterday={true} appendDayToEnd={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={"bottom-1em top-1em"}>
                        <Col xs={24} xl={12}
                            ref={refMarketsInclude}
                        >
                            <Form.Item
                                label={`Markets include`}
                            >
                                <CountriesSelect
                                    onChange={setMarkets}
                                    setExternalData={setCounts}
                                    mode="multiple"
                                    valueID={true}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={"bottom-1em top-1em"}>
                        <Col xs={24} xl={12}
                            ref={refProducts}
                        >
                            <Form.Item
                                name={`ProductsInclude`}
                                label={`Products include`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={prodsLoading}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={filterOptionProds}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.Product.ItemSKU ?? '').toLowerCase().localeCompare((optionB?.Product.ItemSKU ?? '').toLowerCase())
                                    }
                                    options={prodsIn}
                                    fieldNames={{ label: "ItemSKU", value: "ItemSKU" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}>
                            <Form.Item
                                name={`ProductsExclude`}
                                label={`Products exclude`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={prodsLoading}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) => (option?.Product.ItemSKU ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.Product.ItemSKU ?? '').toLowerCase().localeCompare((optionB?.Product.ItemSKU ?? '').toLowerCase())
                                    }
                                    options={prodsEx}
                                    fieldNames={{ label: "ItemSKU", value: "ItemSKU" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={"bottom-1em top-1em"}>
                        <Col xs={24} xl={12}
                            ref={refCats}
                        >
                            <Form.Item
                                name={`CategoriesInclude`}
                                label={`Categories include`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={prodsLoading}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) => (option?.Stringified ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.Stringified ?? '').toLowerCase().localeCompare((optionB?.Stringified ?? '').toLowerCase())
                                    }
                                    options={cats}
                                    fieldNames={{ label: "Stringified", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={12}
                            ref={refCats}
                        >
                            <Form.Item
                                name={`CategoriesExclude`}
                                label={`Categories exclude`}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    loading={prodsLoading}
                                    mode="multiple"
                                    tokenSeparators={[',']}
                                    filterOption={(input, option) => (option?.Stringified ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.Stringified ?? '').toLowerCase().localeCompare((optionB?.Stringified ?? '').toLowerCase())
                                    }
                                    options={cats}
                                    fieldNames={{ label: "Stringified", value: "ID" }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={"bottom-1em top-1em"}>
                        <Col xs={24} xl={24}>
                            <Form.Item {...tailFormItemLayout}>
                                <Button style={{ "width": "100%" }} type="primary" htmlType="submit"
                                    ref={refCreate}
                                >
                                    Create campaign
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {
                    results.length > 0
                        ?
                        <Row className={"bottom-1em top-1em"}>
                            <Col xs={24} xl={24}>
                                <Table
                                    dataSource={results}
                                    columns={resultColumns}
                                    rowKey={(record) => record.CampaignID}
                                    size={"small"}
                                    pagination={{ position: ['topCenter', 'bottomCenter'] }}
                                />
                            </Col>
                        </Row>
                        :
                        <></>
                }
                <Tour open={open} onClose={() => setOpen(false)} steps={tourSteps} />
            </div>
        </>
    )
}

const resultColumns = [
    {
        title: 'ID',
        dataIndex: 'CampaignID',
        key: 'CampaignID',
        render: (_, record) => (
            <>{GetCampaignLink(record.CampaignID, record.CampaignID)}</>
        ),
    },
    {
        title: 'Name',
        dataIndex: 'CampaignName',
        key: 'CampaignName',
    },
    {
        title: 'Products',
        dataIndex: 'NumberOfProducts',
        key: 'NumberOfProducts',
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        render: (_, record) => (
            <>
                {
                    record.Warning !== undefined && record.Warning !== null && record.Warning !== ""
                        ?
                        <Alert
                            description={record.Warning}
                            type="error"
                            style={{ backgroundColor: "rgba(248,214,86,0.27)", borderRadius: 0, margin: "1em 0 1em 0" }}
                        />
                        :
                        <></>
                }
                {
                    record.Error !== undefined && record.Error !== null && record.Error !== ""
                        ?
                        <Alert
                            description={record.Error === "bad_request" ? "Something is wrong with the request :(" : record.Error}
                            type="error"
                            style={{ backgroundColor: "#ff00001a", borderRadius: 0, margin: "1em 0 1em 0" }}
                        />
                        :
                        <></>
                }
            </>
        ),
    },
];


