import { Table } from "antd";
import React from "react";
import { PostRequestInternalAPI } from "../../components/backend/postRequest";
import { useEffect, useState } from "react";
import { ErrorRow } from "../../components/errorBlock";
import { TimeFromSeconds } from "../../components/dateAndTime";
import { GetPriceCurrencyFromMarketCopyable } from "../../components/formattingValues";
import { GetProductLinkTagCopyable } from "../../components/getPageLinks";


export const ProductsPricesDynamicTable = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    let reqData = {
        page: tableParams.pagination.current,
        size: tableParams.pagination.pageSize,
        market: props.market,
        skus: props.skus,
    };

    const fetchData = () => {
        PostRequestInternalAPI(`/api/v1/products/prices/dynamic`, reqData,
            setLoading,
            setErr,
            null,
            props.messageAPI,
        )
            .then(
                (result) => {
                    if (result !== undefined && result.Records !== undefined) {
                        setData(result.Records);
                        props.setTotal(result.Total);

                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.Total,
                            },
                        });
                    }
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams), props.refreshData]);

    if (err !== "") {
        return (
            <>
                <ErrorRow err={err} prefix={"Dynamic prices"} />
            </>
        )
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                size={"small"}
                onChange={handleTableChange}
            />
        </>
    )
}


const columns = [
    {
        title: 'ItemSKU',
        dataIndex: 'ItemSKU',
        key: 'ItemSKU',
        render: (_, record) => (
            <>
                {GetProductLinkTagCopyable(record.ItemSKU)}
            </>
        ),
    },
    {
        title: 'Market',
        dataIndex: 'Market',
        key: 'Market',
    },
    {
        title: 'MatchingAgainst',
        dataIndex: 'MatchingAgainst',
        key: 'MatchingAgainst',
    },
    {
        title: 'PriceReason',
        dataIndex: 'PriceReason',
        key: 'PriceReason',
    },
    {
        title: 'Timestamp',
        dataIndex: 'Timestamp',
        key: 'Timestamp',
        render: (_, record) => (
            <>
                <TimeFromSeconds seconds={record.Timestamp.seconds * 1000} />
            </>
        ),
    },
    {
        title: 'Price',
        dataIndex: 'Price',
        key: 'Price',
        render: (_, record) => (
            <>
                {GetPriceCurrencyFromMarketCopyable(record.Price, record.Market)}
            </>
        ),
    },
    {
        title: 'RRP',
        dataIndex: 'RRP',
        key: 'RRP',
        render: (_, record) => (
            <>
                {GetPriceCurrencyFromMarketCopyable(record.RRP, record.Market)}
            </>
        ),
    },
]