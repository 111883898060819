import { Space, Table, Button, Popconfirm } from "antd";
import React from "react";
import { PostRequestInternalAPI } from "../../../components/backend/postRequest";
import { useEffect, useState } from "react";
import { ErrorRow } from "../../../components/errorBlock";
import { GetProductHref, GetProductLinkTagCopyable } from "../../../components/getPageLinks";
import { CloseCircleOutlined, EyeOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { HTTPDeleteInternalAPI } from "../../../components/backend/httpDELETE";


export const ProductsForCampaignTable = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setData([]);
        }
    };

    let reqData = {
        page: tableParams.pagination.current,
        size: tableParams.pagination.pageSize,
        campaign_id: props.campaignID,
    };

    const fetchData = () => {
        PostRequestInternalAPI(`/api/v1/products/campaigns/v2/products`,
            reqData,
            setLoading,
            setErr,
            null,
        )
            .then(
                (result) => {
                    setData(result.records);
                    setTableParams({
                        ...tableParams,
                        pagination: {
                            ...tableParams.pagination,
                            total: result.total,
                        },
                    });
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams), props.refreshData]);

    const handleDeleteProduct = (camp = '', sku = '') => {
        HTTPDeleteInternalAPI(`/api/v1/products/campaigns/v2/delete_product`, {
            "campaign_id": camp,
            "sku": sku,
        },
            setLoading,
            setErr,
            null,
        )
            .then(() => {
                let newProducts = data

                if (Array.isArray(sku)) {
                    sku.forEach((element) => {
                        newProducts = newProducts.filter((item) => item.SKU !== element);
                    }
                    );
                } else {
                    newProducts = newProducts.filter((item) => item.SKU !== sku);
                }

                setData(newProducts);
            })
    };


    const columns = [
        {
            title: 'SKU',
            dataIndex: 'SKU',
            key: 'SKU',
            render: (_, record) => (
                <>{record.SKU !== "" ? GetProductLinkTagCopyable(record.SKU) : <></>}</>
            ),
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) =>
                <>
                    <Space wrap>
                        <Button type="primary" href={GetProductHref(record.SKU)} target={"_blank"}
                            rel={"noreferrer"}>
                            <EyeOutlined />
                        </Button>
                        <Popconfirm
                            title="Delete product from campaign"
                            description="You can't add it again, only recreate campaign"
                            onConfirm={() => handleDeleteProduct(props.campaignID, record.SKU)}
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                        >
                            <Button type="dashed" danger>
                                <CloseCircleOutlined />
                            </Button>
                        </Popconfirm>
                    </Space>
                </>
        },
    ];

    if (err !== "") {
        return (
            <>
                <ErrorRow err={err} prefix={"Products for campaign"} />
            </>
        )
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                size={"small"}
                onChange={handleTableChange}
            />
        </>
    )
}

