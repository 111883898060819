import { useState } from "react";
import { GetRequestInternalAPI } from "../../components/backend/getRequest";
import { actionOnError, errorTranslation, getBackendLink, getCookieDomain } from "../../components/backend/backend";
import { NotificationError, NotificationSuccess } from "../../components/notifications";
import { Form, Input, Typography } from 'antd';
import React, { useEffect } from "react";
import { PostRequest } from "../../components/backend/postRequest";
import { Avatar, Button, Col, Image, Row, Space } from "antd";
import {
    LockOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { ErrorRow } from "../../components/errorBlock";
import Cookies from "universal-cookie";
import { Spinner } from "../../components/spinners";

export const SettingsProfile = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    //     sendNewAvatar = async options => {
    //         const {onSuccess, onError, file, onProgress} = options;
    //
    //         const fmData = new FormData();
    //         const config = {
    //             headers: {"content-type": "multipart/form-data"}, withCredentials: true, onUploadProgress: event => {
    //                 const percent = Math.floor((event.loaded / event.total) * 100);
    //                 this.setState({progress: percent});
    //                 if (percent === 100) {
    //                     setTimeout(() => this.setState({progress: 0}), 1000);
    //                 }
    //                 onProgress({percent: (event.loaded / event.total) * 100});
    //             }
    //         };
    //         fmData.append("avatar", file);
    //         try {
    //             const res = await axios.post(getBackendLink() + "/api/v1/users/upload/avatar", fmData, config);
    //             let result = res.data
    //             if (result.success !== true) {
    //                 actionOnError(result.error)
    //
    //                 this.setState({hasError: true});
    //                 this.setState({error: result.error});
    //                 onError(result.error);
    //             } else {
    //                 if (result.refresh_token === true) {
    //                     const cookies = new Cookies();
    //                     cookies.set('userToken', result.new_token, {
    //                         domain: getCookieDomain(),
    //                         path: '/',
    //                         sameSite: 'none',
    //                         secure: true
    //                     });
    //                 }
    //                 onSuccess("ok");
    //             }
    //
    //         } catch (err) {
    //             onError({err});
    //         }
    //     };


    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/users/self/get`,
            setLoading,
            setErr,
            null
        ).then(
            (result) => {
                setData(result)
            }
        )
        // eslint-disable-next-line
    }, []);

    const onFinish = (values) => {
        PostRequest(getBackendLink() + '/api/v1/users/self/update', {
            "login": values.login,
            "email": values.uemail,
            "first_name": values.first_name,
            "last_name": values.last_name,
            "password": values.upass,
            "avatar_link": values.avatar_link,
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)

                        NotificationError('Error', errorTranslation(result.error))
                    } else {
                        if (result.refresh_token === true) {
                            const cookies = new Cookies();
                            cookies.set('userToken', result.new_token, {
                                domain: getCookieDomain(),
                                path: '/',
                                sameSite: 'none',
                                secure: true
                            });
                        }
                        NotificationSuccess('Saved!', 'Data has been saved')
                    }
                },
                (error) => {
                    NotificationError('Error', error.message)
                }
            )
    }

    let avatar = <Avatar shape="square" size={250} src={undefined}></Avatar>
    if (data.avatar !== "") {
        avatar = <Avatar shape="square" size={250}
            src={<Image src={data.avatar} style={{ width: "100%" }} />} />
    }

    if (err !== "") {
        return <ErrorRow prefix={"Main info"} err={err} />
    }

    if (loading === true) {
        return <Spinner />
    }

    return (
        <>

            <Row>
                <Col xs={24} xl={6} style={{ textAlign: "center" }}>
                    <Space direction="vertical" style={{ width: '100%', textAlign: "center" }}>
                        <Typography.Title
                            copyable={{ text: data.first_name + " " + data.last_name }}
                            level={3} style={{ margin: 0 }}>
                            {data.first_name} {data.last_name}
                        </Typography.Title>
                        {avatar}
                    </Space>
                </Col>
                <Col xs={24} xl={18}>
                    <Form
                        name="unamereal"
                        {...formItemLayout}
                        className="login-form"
                        initialValues={
                            {
                                first_name: data.first_name,
                                last_name: data.last_name,
                                uemail: data.email,
                                login: data.login,
                                avatar_link: data.avatar,
                            }
                        }
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="first_name"
                            label="First name"
                            rules={[{ message: 'Name should not be empty' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label="Last name"
                            rules={[{ message: 'Name should not be empty' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                        </Form.Item>
                        <Form.Item
                            name="avatar_link"
                            label="Avatar link"
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} />
                        </Form.Item>

                        <Form.Item
                            name="uemail"
                            label="Email"
                            rules={[{
                                type: 'email', message: 'Does not look like a real email...',
                            }, {
                                message: 'Email should not be empty',
                            },]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name="upass"
                            label="New password"
                            rules={[{ message: 'Password should not be empty' }]}
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />

                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Repeat new password"
                            dependencies={['upass']}
                            hasFeedback
                            rules={[{

                                message: 'Please, repeat the password',
                            }, ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('upass') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Passwords are different'));
                                },
                            }),]}
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} />
                        </Form.Item>

                        <Form.Item
                            name="login"
                            label="Login"
                            rules={[{
                                message: 'Pick a username (login), please',
                            },]}
                        >
                            <Input
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            <Button style={{ "width": "100%" }} type="primary" htmlType="submit"
                                className="login-form-button">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    )


}