import React from 'react';
import { Col, Modal, Popover, Select, Tour } from 'antd';
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { GetRequest } from "../../../components/backend/getRequest"
import { ErrorBlock } from "../../../components/errorBlock"
import { Space } from 'antd';
import { actionOnError, errorTranslation, getBackendLink } from "../../../components/backend/backend"
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Row } from 'antd';
import { PostRequest } from "../../../components/backend/postRequest";
import { NotificationError, NotificationSuccess } from "../../../components/notifications";
import { CanEditSlim4 } from "../../../components/backend/backendRoles";
import { GetColumnsSlim4PCSEditable, columnsSlim4PCSNotEditable } from "../../../components/tables/heads";
import {
    BreadcrumbsSlim4PurchaseCategorySplit
} from "../../../components/breadcrumbs/breadcrumbsSlim4";
import { Helmet } from "react-helmet";


export const Slim4PurchaseCategoriesSplit = (props) => {
    const [openTour, setOpenTour] = useState(false);

    const tourSteps = [
        {
            title: 'This page',
            description: <>This page is a part of 'Bangerhead to Slim4' integration. Documentation for users can be
                found in our <a target={"_blank"} rel={"noreferrer"}
                    href={"https://app.clickup.com/4575209/v/dc/4bkz9-18321/4bkz9-9801"}>tech space</a>.</>,
        },
        {
            title: 'Purchaser category codes for Slim4 pipeline',
            description: <>Here you can find a table of purchaser codes that will replace
                ones on products that match the brand+category criteria.</>,
        },
        {
            title: 'Table',
            description: <>Each line represents one combination of brand+category and can be reasonably combined.</>,
        },
        {
            title: 'Edit',
            description: <>If you have necessary access, you can add, edit or delete rows. Treat these with caution.</>,
        },
    ];

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');
    const [open, setOpen] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 500,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const [udOnes, setUDOnes] = useState([])
    const [brands, setBrands] = useState();
    const [brandsLoading, setBrandsLoading] = useState(false);
    const [categories, setCategories] = useState();
    const [loadingCategories, setLoadingCategories] = useState(false);

    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/integrations/slim4/purchase_categories_split_get?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.purchase_categories);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.total,
                            },
                        });


                        let newUDOnes = []
                        result.result.ud1_options.map((x) => (
                            newUDOnes.push({
                                ID: x.UD1,
                                Name: x.UD1,
                            })
                        ))
                        setUDOnes(newUDOnes)
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [JSON.stringify(tableParams)]);

    const fetchCategories = () => {
        setLoadingCategories(true);
        PostRequest(getBackendLink() + `/api/v1/products/categories`, {
            page: 1,
            size: 5000
        })
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")

                        let newUDOnes = []
                        result.result.records.map((x) => (
                            newUDOnes.push({
                                ID: `${x.NameSE} ::: ${x.ID}`,
                                Name: `${x.NameSE} : ${x.ID}`,
                            })
                        ))
                        setCategories(newUDOnes)
                    }
                    setLoadingCategories(false);
                },
                (error) => {
                    setLoadingCategories(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchCategories()
    }, []);

    const fetchBrands = () => {
        setBrandsLoading(true);
        GetRequest(getBackendLink() + `/api/v1/products/brands?page=1&size=1000&ids=''`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")

                        let newBrands = []
                        result.result.Records.map((x) => (
                            newBrands.push({
                                ID: `${x.Name} ::: ${x.ID}`,
                                Name: `${x.Name} : ${x.ID}`,
                            })
                        ))
                        setBrands(newBrands)
                    }
                    setBrandsLoading(false);
                },
                (error) => {
                    setBrandsLoading(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => {
        fetchBrands();
    }, []);

    const handleDelete = (keys = []) => {
        let cats = []
        if (Array.isArray(keys)) {
            cats = keys
        } else {
            cats = [`${keys}`]
        }

        PostRequest(getBackendLink() + `/api/v1/integrations/slim4/purchase_category_split_delete`, {
            "cats": cats,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Deleted!')
                    let newData = data

                    if (Array.isArray(keys)) {
                        keys.forEach((element) => {
                            newData = newData.filter((item) => item.id !== element);
                        }
                        );
                    } else {
                        newData = newData.filter((item) => item.id !== keys);
                    }

                    setData(newData);
                }
            }, (error) => {
                NotificationError('Error', error.message)
            })
    };

    const handleSaveV2 = (row) => {
        setLoading(true)

        PostRequest(getBackendLink() + `/api/v1/integrations/slim4/purchase_category_split_update`, {
            "id": row.id,
            "brand": row.brand,
            "category": row.category,
            "brand_id": row.brand_id,
            "category_id": row.category_id,
            "purchaser_code": row.purchaser_code,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Updated')

                }
                setLoading(false)
            }, (error) => {
                NotificationError('Error', error.message)
                setLoading(false)
            })
    };

    const handleSave = (row) => {
        const newData = [...data];
        const index = newData.findIndex((item) => row.id === item.id);
        const item = newData[index];

        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setData(newData);
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columnsSlim4PCSEditable = GetColumnsSlim4PCSEditable(handleDelete, handleSaveV2)

    const columns = columnsSlim4PCSEditable.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const onCreate = (values) => {
        let brandData = ["", ""]
        if (values.brand !== undefined && values.brand !== null) {
            brandData = values.brand.split(' ::: ')
        }

        let categoryData = ["", ""]
        if (values.category !== undefined && values.category !== null) {
            categoryData = values.category.split(' ::: ')
        }

        PostRequest(getBackendLink() + `/api/v1/integrations/slim4/purchase_category_split_add`, {
            "brand": brandData[0],
            "category": categoryData[0],
            "brand_id": brandData[1],
            "category_id": categoryData[1],
            "purchaser_code": values.purchaser_code,
        })
            .then((result) => {
                if (result.success !== true) {
                    actionOnError(result.error)
                    NotificationError('Error', result.error)
                } else {
                    NotificationSuccess('Created')
                    setOpen(false)
                    fetchData()
                }
                setLoading(false)
            }, (error) => {
                NotificationError('Error', error.message)
                setLoading(false)
            })
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };


    const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                open={open}
                title="Create category split"
                okText="Create"
                cancelText="Cancel"
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            onCreate(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Form.Item
                        name="brand"
                        label="Brand"
                    >
                        <Select
                            options={brands}
                            loading={brandsLoading}
                            fieldNames={{ label: "Name", value: "ID" }}
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="category"
                        label="Category"
                    >
                        <Select
                            options={categories}
                            loading={loadingCategories}
                            fieldNames={{ label: "Name", value: "ID" }}
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="purchaser_code"
                        label={<Popover
                            content="Code that will replace initial product's value"
                            title="Purchaser code" trigger="hover">
                            <span>Purchaser code <QuestionCircleOutlined /></span></Popover>}
                    >
                        <Select
                            options={udOnes}
                            loading={loading}
                            fieldNames={{ label: "Name", value: "ID" }}
                        >
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const start = () => {
        setLoading(true);

        let cats = []
        selectedRowKeys.forEach((element) => {
            cats.push(`${element}`)
        }
        );

        handleDelete(cats)
        setSelectedRowKeys([]);
        setLoading(false);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    return (
        <>
            <Helmet>
                <title>Purchase category split || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsSlim4PurchaseCategorySplit />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginBottom: "1em" }}>
                    {err !== "" ? <Col xs={24} xl={24}><ErrorBlock error={errorTranslation(err)} /></Col> : <></>}
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <Button type="primary" onClick={() => setOpenTour(true)}
                                style={{
                                    backgroundColor: "#1084cb",
                                }}
                            >
                                About this page <QuestionCircleOutlined />
                            </Button>
                            <Button
                                disabled={loading || !CanEditSlim4(props.grants)}
                                onClick={() => {
                                    setOpen(true);
                                }}
                                type="primary"
                            >
                                Add a row
                            </Button>
                            <Popconfirm
                                title="Delete rows"
                                description="Are you sure to delete these codes?"
                                onConfirm={() => start()}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{
                                            color: 'red',
                                        }}
                                    />
                                }
                            >
                                <Button
                                    disabled={!hasSelected || !CanEditSlim4(props.grants)}
                                    type="dashed"
                                    danger
                                >
                                    Delete selected
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1em" }}>
                    <Col xs={24} xl={24}>
                        {CanEditSlim4(props.token.Roles)
                            ?
                            <>
                                <Table
                                    dataSource={data}
                                    columns={columns}
                                    loading={loading}
                                    pagination={tableParams.pagination}
                                    rowKey={(record) => record.id}
                                    size={"small"}
                                    onChange={handleTableChange}
                                    rowClassName={() => 'editable-row'}
                                    components={components}
                                    showSizeChanger={true}
                                    rowSelection={rowSelection}
                                />
                                <CollectionCreateForm
                                    open={open}
                                    onCreate={onCreate}
                                    onCancel={() => {
                                        setOpen(false);
                                    }}
                                />
                            </>
                            :
                            <Table
                                dataSource={data}
                                columns={columnsSlim4PCSNotEditable}
                                loading={loading}
                                pagination={tableParams.pagination}
                                rowKey={(record) => record.id}
                                size={"small"}
                                onChange={handleTableChange}
                                showSizeChanger={true}
                            />
                        }

                    </Col>
                </Row>
                <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
            </div>
        </>
    );
};


const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}
                    prefix={<EditOutlined className="site-form-item-icon" />} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}

            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};