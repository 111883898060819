import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Tag } from 'antd';
import { GetRequest } from "../../../components/backend/getRequest";
import { actionOnError, errorTranslation, getBackendLink } from "../../../components/backend/backend";
import { ErrorBlock } from "../../../components/errorBlock";
import { NotificationError } from "../../../components/notifications";
import {
    BreadcrumbsProductsBrands
} from "../../../components/breadcrumbs/breadcrumbsProducts";
import { Helmet } from "react-helmet";

const columns = [
    {
        title: 'IMG',
        dataIndex: 'image',
        key: 'image',
        render: (_, record) => (
            record.image !== "" ? <img src={`https://www.bangerhead.se/bilder/ibutik-a/varumarken/logo_liten/${record.ID}.gif`} style={{ maxHeight: "50px" }} alt={""} /> : <></>
        ),
    },
    {
        title: 'ID',
        dataIndex: 'ID',
        key: 'ID',
    },
    {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
    },
    {
        title: 'Limit langs',
        dataIndex: 'LimitLang',
        key: 'LimitLang',
        render: (_, record) => (
            <>{record.LimitLang !== "" ? record.LimitLangArray.map((x, n) => (<>{x}{n < record.LimitLangArray.length - 1 ? <>, </> : <></>}</>)) : <></>}</>
        ),
    },
    {
        title: 'Visible',
        dataIndex: 'IsVisible',
        key: 'IsVisible',
        render: (_, record) => (
            <>{record.IsVisible === true ? <Tag color={'lime'}>YES</Tag> : <Tag color={'volcano'}>NO</Tag>}</>
        ),
    },
];

export const Brands = () => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
            position: ["topCenter", "bottomCenter"],
        },
    });
    const fetchData = () => {
        setLoading(true);
        GetRequest(getBackendLink() + `/api/v1/products/brands?page=${tableParams.pagination.current}&size=${tableParams.pagination.pageSize}&ids=''`)
            .then(
                (result) => {
                    if (result.success !== true) {
                        actionOnError(result.error)
                        setErr(result.error)
                    } else {
                        setErr("")
                        setData(result.result.Records);
                        setTableParams({
                            ...tableParams,
                            pagination: {
                                ...tableParams.pagination,
                                total: result.result.Total,
                            },
                        });
                    }
                    setLoading(false);
                },
                (error) => {
                    setLoading(false);
                    NotificationError('Error', error.message)
                }
            )
    };

    // eslint-disable-next-line
    useEffect(() => { fetchData(); }, [JSON.stringify(tableParams)]);


    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };
    return (
        <>
            <Helmet>
                <title>Brand list || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsProductsBrands />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row style={{ marginBottom: "1em" }}>
                    {err !== "" ? <Col xs={24} xl={24}><ErrorBlock error={errorTranslation(err)} /></Col> : <></>}
                    <Col xs={24} xl={24}>
                        <Table
                            columns={columns}
                            rowKey={(record) => record.product}
                            dataSource={data}
                            pagination={tableParams.pagination}
                            loading={loading}
                            size={"small"}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
