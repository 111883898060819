import React from "react";
import { Button, Col, Form, Row, Select, Space, Table, Tabs, Tag } from "antd";
import { BreadcrumbsDeveloperCostsAzure } from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { CloudDownloadOutlined, EuroOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { GetRequestInternalAPI } from "../../../components/backend/getRequest";
import { ErrorRow } from "../../../components/errorBlock";
import { ConvertMonthNumberToName, RoundDecimal } from "../../../components/formattingValues";
import { CartesianGrid, Legend, BarChart, Tooltip, XAxis, YAxis, Bar, Cell, ReferenceLine } from "recharts";
import { Helmet } from "react-helmet";
import { GetCurrentMonth, GetCurrentYear, GetYearAndMonth } from "../../../components/dateAndTime";
import { getBackendLink } from "../../../components/backend/backend";

export const DeveloperCostsClouds = () => {
    const [costsMetadata, setCostsMetadata] = useState({
        clouds: [],
        services: [],
        resources: [],
    });
    const [costsMetadataError, setCostsMetadataError] = useState("");
    const [costsMetadataFetching, setCostsMetadataFetching] = useState(true);

    const filterOptionClouds = (input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase());
    const filterOptionServices = (input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase());
    const filterOptionResources = (input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        GetRequestInternalAPI(
            `/api/v1/statistic/azure/costs/metadata`,
            setCostsMetadataFetching,
            setCostsMetadataError,
            setCostsMetadata
        ).then(
            (result) => {
                let newCostsMetadata = costsMetadata

                if (result.clouds !== null) {
                    let newCloudsFilter = []
                    result.clouds.map(cloud => newCloudsFilter.push({
                        id: cloud.id,
                        name: cloud.name,
                    }));
                    newCostsMetadata.clouds = newCloudsFilter;
                }

                if (result.services !== null) {
                    let newServicesFilter = []
                    result.services.map(service => newServicesFilter.push({
                        id: service.id,
                        name: service.name,
                    }));
                    newCostsMetadata.services = newServicesFilter
                }

                if (result.resources !== null) {
                    let newResourcesFilter = []
                    result.resources.map(resource => newResourcesFilter.push({
                        id: resource.id,
                        name: `${resource.service_name} => (${resource.name})`,
                    }));
                    newCostsMetadata.resources = newResourcesFilter
                }

                setCostsMetadata(newCostsMetadata)
            }
        )
        // eslint-disable-next-line
    }, []);


    const [cloudsFilterRequest, setCloudsFilterRequest] = useState("[]")
    const [servicesFilterRequest, setServicesFilterRequest] = useState("[]")
    const [resourcesFilterRequest, setResourcesFilterRequest] = useState("[]")
    const [yearFrom, setYearFrom] = useState(GetCurrentYear())
    const [monthFrom, setMonthFrom] = useState(1)
    const [yearTo, setYearTo] = useState(GetCurrentYear())
    const [monthTo, setMonthTo] = useState(GetCurrentMonth())

    const [cloudsTableParams, setCloudsTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [servicesTableParams, setServicesTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [resourcesTableParams, setResourcesTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
            position: ["topCenter", "bottomCenter"],
        },
    });

    const [costsCloudsData, setCostsCloudsData] = useState([]);
    const [costsCloudsDataError, setCostsCloudsDataError] = useState("");
    const [costsCloudsDataFetching, setCostsCloudsDataFetching] = useState(true);

    const getCosts = () => {
        getCloudsCosts()
        getServicesCosts()
        getResourcesCosts()
    }

    const getCloudsCosts = () => {
        GetRequestInternalAPI(
            `/api/v1/statistic/azure/costs/clouds/list?from_year=${yearFrom}&from_month=${monthFrom}&to_year=${yearTo}&to_month=${monthTo}&clouds=${cloudsFilterRequest}&page=${cloudsTableParams.pagination.current}&size=${cloudsTableParams.pagination.pageSize}`,
            setCostsCloudsDataFetching,
            setCostsCloudsDataError,
            null
        ).then(
            (result) => {
                if (result.records !== null) {
                    setCostsCloudsData(result.records)

                    setCloudsTableParams({
                        ...cloudsTableParams,
                        pagination: {
                            ...cloudsTableParams.pagination,
                            total: result.total,
                        },
                    });
                }
            }
        )
    };

    useEffect(() => {
        getCloudsCosts()
        // eslint-disable-next-line
    }, [JSON.stringify(cloudsTableParams)]);

    const [costsServicesData, setCostsServicesData] = useState([]);
    const [costsServicesDataError, setCostsServicesDataError] = useState("");
    const [costsServicesDataFetching, setCostsServicesDataFetching] = useState(true);

    const getServicesCosts = () => {
        GetRequestInternalAPI(
            `/api/v1/statistic/azure/costs/services/list?from_year=${yearFrom}&from_month=${monthFrom}&to_year=${yearTo}&to_month=${monthTo}&clouds=${cloudsFilterRequest}&services=${servicesFilterRequest}&page=${servicesTableParams.pagination.current}&size=${servicesTableParams.pagination.pageSize}`,
            setCostsServicesDataFetching,
            setCostsServicesDataError,
            null
        ).then(
            (result) => {
                if (result.records !== null) {
                    setCostsServicesData(result.records)

                    setServicesTableParams({
                        ...servicesTableParams,
                        pagination: {
                            ...servicesTableParams.pagination,
                            total: result.total,
                        },
                    });
                }
            }
        )
    };

    useEffect(() => {
        getServicesCosts()
        // eslint-disable-next-line
    }, [JSON.stringify(servicesTableParams)]);

    const [costsResourcesData, setCostsResourcesData] = useState([]);
    const [costsResourcesDataError, setCostsResourcesDataError] = useState("");
    const [costsResourcesDataFetching, setCostsResourcesDataFetching] = useState(true);

    const getResourcesCosts = () => {
        GetRequestInternalAPI(
            `/api/v1/statistic/azure/costs/resources/list?from_year=${yearFrom}&from_month=${monthFrom}&to_year=${yearTo}&to_month=${monthTo}&clouds=${cloudsFilterRequest}&services=${servicesFilterRequest}&resources=${resourcesFilterRequest}&page=${resourcesTableParams.pagination.current}&size=${resourcesTableParams.pagination.pageSize}`,
            setCostsResourcesDataFetching,
            setCostsResourcesDataError,
            null
        ).then(
            (result) => {
                if (result.records !== null) {
                    setCostsResourcesData(result.records)

                    setResourcesTableParams({
                        ...resourcesTableParams,
                        pagination: {
                            ...resourcesTableParams.pagination,
                            total: result.total,
                        },
                    });
                }
            }
        )
    };

    useEffect(() => {
        getResourcesCosts()
        // eslint-disable-next-line
    }, [JSON.stringify(resourcesTableParams)]);



    const handleCloudsTableChange = (pagination, filters, sorter) => {
        setCloudsTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== cloudsTableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setCostsCloudsData([]);
        }
    }

    const handleServicesTableChange = (pagination, filters, sorter) => {
        setServicesTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== servicesTableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setCostsServicesData([]);
        }
    }

    const handleResourcesTableChange = (pagination, filters, sorter) => {
        setResourcesTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== resourcesTableParams.pagination?.pageSize) {
            // eslint-disable-next-line
            setCostsResourcesData([]);
        }
    }

    return (
        <>
            <Helmet>
                <title>Azure costs || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Space wrap>
                            <BreadcrumbsDeveloperCostsAzure />
                        </Space>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <ErrorRow err={costsMetadataError} prefix={"Metadata"} />
                <Row>
                    <Col xs={24} xl={6}>
                        <Form.Item name={`From`} label={`From`}  >
                            <GetYearAndMonth setYear={setYearFrom} setMonth={setMonthFrom} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Form.Item name={`To`} label={`To`}>
                            <GetYearAndMonth setYear={setYearTo} setMonth={setMonthTo} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Form.Item name={`Clouds`} label={`Clouds`} >
                            <Select
                                showSearch
                                allowClear
                                loading={costsMetadataFetching}
                                mode="multiple"
                                tokenSeparators={[',']}
                                filterOption={filterOptionClouds}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                                }
                                options={costsMetadata.clouds}
                                onChange={(keys) => { keys.length > 0 ? setCloudsFilterRequest(keys) : setCloudsFilterRequest("[]") }}
                                fieldNames={{ label: "name", value: "id" }}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Form.Item name={`Services`} label={`Services`}>
                            <Select
                                showSearch
                                allowClear
                                loading={costsMetadataFetching}
                                mode="multiple"
                                tokenSeparators={[',']}
                                filterOption={filterOptionServices}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                                }
                                options={costsMetadata.services}
                                onChange={(keys) => { keys.length > 0 ? setServicesFilterRequest(keys) : setServicesFilterRequest("[]") }}
                                fieldNames={{ label: "name", value: "id" }}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Form.Item name={`Resources`} label={`Resources`} >
                            <Select
                                showSearch
                                allowClear
                                loading={costsMetadataFetching}
                                mode="multiple"
                                tokenSeparators={[',']}
                                filterOption={filterOptionResources}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                                }
                                options={costsMetadata.resources}
                                onChange={(keys) => { keys.length > 0 ? setResourcesFilterRequest(keys) : setResourcesFilterRequest("[]") }}
                                fieldNames={{ label: "name", value: "id" }}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={3}>
                        <Button type="primary" onClick={() => { getCosts() }}>
                            Get <EuroOutlined />
                        </Button>
                    </Col>
                    <Col xs={24} xl={3}>
                        <Button type="primary" className={"btn-green"}
                            href={getBackendLink() + `/api/v1/statistic/azure/costs/clouds/list?from_year=${yearFrom}&from_month=${monthFrom}&to_year=${yearTo}&to_month=${monthTo}&clouds=${cloudsFilterRequest}&page=${cloudsTableParams.pagination.current}&size=${cloudsTableParams.pagination.pageSize}&format=csv`}
                        >
                            Clouds CSV <CloudDownloadOutlined />
                        </Button>
                    </Col>
                    <Col xs={24} xl={3}>
                        <Button type="primary" className={"btn-green"}
                            href={getBackendLink() + `/api/v1/statistic/azure/costs/services/list?from_year=${yearFrom}&from_month=${monthFrom}&to_year=${yearTo}&to_month=${monthTo}&clouds=${cloudsFilterRequest}&services=${servicesFilterRequest}&page=${servicesTableParams.pagination.current}&size=${servicesTableParams.pagination.pageSize}&format=csv`}
                        >
                            Services CSV <CloudDownloadOutlined />
                        </Button>
                    </Col>
                    <Col xs={24} xl={3}>
                        <Button type="primary" className={"btn-green"}
                            href={getBackendLink() + `/api/v1/statistic/azure/costs/resources/list?from_year=${yearFrom}&from_month=${monthFrom}&to_year=${yearTo}&to_month=${monthTo}&clouds=${cloudsFilterRequest}&services=${servicesFilterRequest}&resources=${resourcesFilterRequest}&page=${resourcesTableParams.pagination.current}&size=${resourcesTableParams.pagination.pageSize}&format=csv`}
                        >
                            Resources CSV <CloudDownloadOutlined />
                        </Button>
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Tabs
                            defaultActiveKey="months"
                            items={
                                [
                                    {
                                        label: 'Clouds',
                                        key: 'clouds',
                                        children:
                                            <>
                                                <ErrorRow err={costsCloudsDataError} prefix={"Clouds"} />
                                                <CloudsBarChart costsCloudsData={costsCloudsData} />
                                                <Table
                                                    columns={columnsCostsClouds}
                                                    rowKey={(record) => record.id}
                                                    dataSource={costsCloudsData}
                                                    pagination={cloudsTableParams.pagination}
                                                    loading={costsCloudsDataFetching}
                                                    size={"small"}
                                                    onChange={handleCloudsTableChange}
                                                />
                                            </>,
                                    },
                                    {
                                        label: 'Services',
                                        key: 'services',
                                        children:
                                            <>
                                                <ErrorRow err={costsServicesDataError} prefix={"Services"} />
                                                <Table
                                                    columns={columnsCostsServices}
                                                    rowKey={(record) => record.id}
                                                    dataSource={costsServicesData}
                                                    pagination={servicesTableParams.pagination}
                                                    loading={costsServicesDataFetching}
                                                    size={"small"}
                                                    onChange={handleServicesTableChange}
                                                />
                                            </>,
                                    },
                                    {
                                        label: 'Resource',
                                        key: 'resources',
                                        children:
                                            <>
                                                <ErrorRow err={costsResourcesDataError} prefix={"Resources"} />
                                                <Table
                                                    columns={columnsCostsResources}
                                                    rowKey={(record) => record.id}
                                                    dataSource={costsResourcesData}
                                                    pagination={resourcesTableParams.pagination}
                                                    loading={costsResourcesDataFetching}
                                                    size={"small"}
                                                    onChange={handleResourcesTableChange}
                                                />
                                            </>,
                                    },
                                ]
                            }
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

const columnsCostsClouds = [
    {
        title: 'Cloud',
        dataIndex: 'cloud_name',
        key: 'cloud_name',
    },
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
    },
    {
        title: 'Month',
        dataIndex: 'month',
        key: 'month',
        render: (_, record) => (
            <>{ConvertMonthNumberToName(record.month)}</>
        ),
    },
    {
        title: 'SEK',
        dataIndex: 'price_sek',
        key: 'price_sek',
        render: (_, record) => (
            <>{RoundDecimal(record.price_sek)} {record.price_sek_diff > 0 ?
                <Tag color={'volcano'}>{RoundDecimal(record.price_sek_diff)}</Tag> : record.price_sek_diff === 0 ?
                    <Tag color={'geekblue'}>{record.price_sek_diff}</Tag> :
                    <Tag color={'lime'}>{RoundDecimal(record.price_sek_diff)}</Tag>}</>
        ),
    },
    {
        title: 'EUR',
        dataIndex: 'price_eur',
        key: 'price_eur',
        render: (_, record) => (
            <>{RoundDecimal(record.price_eur)} {record.price_eur_diff > 0 ?
                <Tag color={'volcano'}>{RoundDecimal(record.price_eur_diff)}</Tag> : record.price_eur_diff === 0 ?
                    <Tag color={'geekblue'}>{record.price_eur_diff}</Tag> :
                    <Tag color={'lime'}>{RoundDecimal(record.price_eur_diff)}</Tag>}</>
        ),
    },
    {
        title: 'Comment',
        dataIndex: 'comments',
        key: 'comments',
    },
]

const columnsCostsServices = [
    {
        title: 'Cloud',
        dataIndex: 'cloud_name',
        key: 'cloud_name',
    }, {
        title: 'Service',
        dataIndex: 'service_name',
        key: 'service_name',
    },
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
    },
    {
        title: 'Month',
        dataIndex: 'month',
        key: 'month',
        render: (_, record) => (
            <>{ConvertMonthNumberToName(record.month)}</>
        ),
    },
    {
        title: 'SEK',
        dataIndex: 'price_sek',
        key: 'price_sek',
        render: (_, record) => (
            <>{RoundDecimal(record.price_sek)} {record.price_sek_diff > 0 ?
                <Tag color={'volcano'}>{RoundDecimal(record.price_sek_diff)}</Tag> : record.price_sek_diff === 0 ?
                    <Tag color={'geekblue'}>{record.price_sek_diff}</Tag> :
                    <Tag color={'lime'}>{RoundDecimal(record.price_sek_diff)}</Tag>}</>
        ),
    },
    {
        title: 'EUR',
        dataIndex: 'price_eur',
        key: 'price_eur',
        render: (_, record) => (
            <>{RoundDecimal(record.price_eur)} {record.price_eur_diff > 0 ?
                <Tag color={'volcano'}>{RoundDecimal(record.price_eur_diff)}</Tag> : record.price_eur_diff === 0 ?
                    <Tag color={'geekblue'}>{record.price_eur_diff}</Tag> :
                    <Tag color={'lime'}>{RoundDecimal(record.price_eur_diff)}</Tag>}</>
        ),
    },
    {
        title: 'Comment',
        dataIndex: 'comments',
        key: 'comments',
    },
]


const columnsCostsResources = [
    {
        title: 'Cloud',
        dataIndex: 'cloud_name',
        key: 'cloud_name',
    },
    {
        title: 'Service',
        dataIndex: 'service_name',
        key: 'service_name',
    },
    {
        title: 'Resource',
        dataIndex: 'resource_name',
        key: 'resource_name',
    },
    {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
    },
    {
        title: 'Month',
        dataIndex: 'month',
        key: 'month',
        render: (_, record) => (
            <>{ConvertMonthNumberToName(record.month)}</>
        ),
    },
    {
        title: 'SEK',
        dataIndex: 'price_sek',
        key: 'price_sek',
        render: (_, record) => (
            <>{RoundDecimal(record.price_sek)} {record.price_sek_diff > 0 ?
                <Tag color={'volcano'}>{RoundDecimal(record.price_sek_diff)}</Tag> : record.price_sek_diff === 0 ?
                    <Tag color={'geekblue'}>{record.price_sek_diff}</Tag> :
                    <Tag color={'lime'}>{RoundDecimal(record.price_sek_diff)}</Tag>}</>
        ),
    },
    {
        title: 'EUR',
        dataIndex: 'price_eur',
        key: 'price_eur',
        render: (_, record) => (
            <>{RoundDecimal(record.price_eur)} {record.price_eur_diff > 0 ?
                <Tag color={'volcano'}>{RoundDecimal(record.price_eur_diff)}</Tag> : record.price_eur_diff === 0 ?
                    <Tag color={'geekblue'}>{record.price_eur_diff}</Tag> :
                    <Tag color={'lime'}>{RoundDecimal(record.price_eur_diff)}</Tag>}</>
        ),
    },
    {
        title: 'Comment',
        dataIndex: 'comments',
        key: 'comments',
    },
]


export const CloudsBarChart = (props) => {
    if (props.costsCloudsData === null || props.costsCloudsData === undefined) {
        return <></>
    }

    const getColor = (value) => {
        if (value > 0) {
            return '#e31717';
        } else {
            return '#4CAF50';
        }
    };

    // Calculate the average value for price_sek.
    const total = props.costsCloudsData.reduce((sum, entry) => sum + entry.price_sek, 0);
    const average = total / props.costsCloudsData.length;

    return (
        <BarChart
            width={1000}
            height={300}
            data={props.costsCloudsData}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="cloud_name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar type="monotone" dataKey="price_sek" name="Price SEK" fill="#8884d8" />
            <Bar dataKey="price_sek_diff" name="Price SEK Difference" fill="#e31717">
                {props.costsCloudsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(entry.price_sek_diff)} />
                ))}
            </Bar>
            <ReferenceLine y={average} label="Avg" stroke="blue" strokeDasharray="3 3" />
        </BarChart>
    );
};