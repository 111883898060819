import { Typography } from "antd";

const { Paragraph } = Typography

export function CopyableText(props) {
    if (props.text === "" || props.text === undefined || props.text === null) {
        return <></>
    }

    return (
        <Paragraph copyable={{ text: props.text }}>
            {props.text}
        </Paragraph>
    )
}