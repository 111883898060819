import { useState, useEffect } from "react";
import { ErrorRow } from "../../../components/errorBlock";
import React from "react";
import { Col, Row, Tag } from "antd";
import {
    BreadcrumbsDeveloperServices
} from "../../../components/breadcrumbs/breadcrumbsDeveloper";
import { Spinner } from "../../../components/spinners";
import { Descriptions } from 'antd';
import {
    BarChartOutlined,
    BarcodeOutlined,
    BellOutlined,
    CheckCircleOutlined, CloseCircleOutlined,
    TeamOutlined
} from "@ant-design/icons";
import ReactTimeAgo from 'react-time-ago'
import { GetRequestInternalAPI } from "../../../components/backend/getRequest";
import { TimeFromSeconds } from "../../../components/dateAndTime";
import JSONPretty from 'react-json-pretty';
import { GetApplicationRunLink, GetServiceLink } from "../../../components/getPageLinks";
import {
    LogSourceAuthService, LogSourceLoggerService,
    LogSourceNotifyService,
    LogSourceProductsService,
    LogSourceEcosystemService
} from "../../../components/backend/services";
import { Helmet } from "react-helmet";

export const Services = () => {
    return (
        <>
            <Helmet>
                <title>Services || Bangerhead Admin</title>
            </Helmet>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsDeveloperServices />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <GetServiceStats name={<><TeamOutlined /> Auth service</>} url={"/api/v1/dev/services/status/auth"} serviceLink={GetServiceLink(LogSourceAuthService)} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <GetServiceStats name={<><BarChartOutlined /> Logger service</>} url={"/api/v1/dev/services/status/logger"} serviceLink={GetServiceLink(LogSourceLoggerService)} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <GetServiceStats name={<><BellOutlined /> Notify service</>} url={"/api/v1/dev/services/status/notifications"} serviceLink={GetServiceLink(LogSourceNotifyService)} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <GetServiceStats
                            name={<><BarcodeOutlined /> Product service</>} url={"/api/v1/dev/services/status/products"} serviceLink={GetServiceLink(LogSourceProductsService)} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <GetServiceStats
                            name={<><BarcodeOutlined /> Ecosystem service</>} url={"/api/v1/dev/services/status/ecosystem"} serviceLink={GetServiceLink(LogSourceEcosystemService)} />
                    </Col>
                </Row>
            </div>
        </>
    )
}
//

const GetServiceStats = (props) => {
    const [err, setErr] = useState("");
    const [data, setData] = useState([
        {
            key: '4',
            label: 'Status',
            children: <><Tag icon={<CloseCircleOutlined />} color="error">offline</Tag>

            </>,
        },
    ]);
    const [loading, setLoading] = useState(false);

    const getData = () => {
        GetRequestInternalAPI(
            props.url,
            setLoading,
            setErr,
            null,
        ).then(
            (result = { status: {} }) => {
                if (result.status !== null) {
                    let newData = [
                        {
                            key: '1',
                            label: 'RunID',
                            children: GetApplicationRunLink(result.status.RunID),
                        },
                        {
                            key: '2',
                            label: 'Started at',
                            children: <>
                                <TimeFromSeconds seconds={result.status.StartedAt.seconds * 1000} /> (<ReactTimeAgo date={result.status.StartedAt.seconds * 1000} locale="en-US" />)
                            </>
                            ,
                        },
                        {
                            key: '4',
                            label: 'Status',
                            children: <><Tag icon={<CheckCircleOutlined />} color="success">online</Tag>
                            </>,
                        },
                        {
                            key: '5',
                            label: 'Run meta',
                            children: <JSONPretty id="json-pretty" data={result.status.RunMeta} />,
                        },
                    ];
                    setData(newData)
                }

            }
        )
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, []);

    if (err !== "") {
        return <ErrorRow err={err} prefix={"Stats"} />
    }

    if (loading) {
        return (
            <>
                <Spinner />
            </>
        )
    }

    //
    // then there will be link to service stats where on service page will be request to logger service
    // for info about number of total logs, total errors, last error, last log, etc. in form of graph
    // and the link on RunID to the run ID where the same info exists in form of graph

    return (
        <>
            <Descriptions
                title={props.name}
                items={data}
                extra={<a href={props.serviceLink} type="primary">To service</a>}
            />
        </>
    )
}