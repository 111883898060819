import { useState } from "react";
import React, { useEffect } from "react";
import { Select } from "antd";
import { GetCountries } from "../../components/backend/getters";

export const filterOptionMarkets = (input, option) => (option?.ListName ?? '').toLowerCase().includes(input.toLowerCase());

export const CountriesSelect = (props) => {
    const [err, setErr] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        GetCountries(
            setLoading,
            setErr,
            setData,
        ).then(
            (result) => {
                let counts = []
                result.Records.map((x) => (
                    counts.push({
                        ID: x.ID,
                        LandISO: x.LandISO,
                        Name: `${x.LandISO} (${x.Name})`,
                    })
                ))

                setData(counts);
                if (props.setExternalData !== null && props.setExternalData !== undefined) {
                    props.setExternalData(counts);
                }
            }
        )
        // eslint-disable-next-line
    }, []);

    if (err !== "") {
        return <Select status="error" value={"Error: " + err} />
    }

    return (
        <>
            <Select
                showSearch
                allowClear
                loading={loading}
                mode={props.mode}
                onChange={(value) => {
                    if (props.onChange !== null && props.onChange !== undefined) {
                        props.onChange(value)
                    }
                }}
                tokenSeparators={[',']}
                filterOption={filterOptionMarkets}
                filterSort={(optionA, optionB) =>
                    (optionA?.Name ?? '').toLowerCase().localeCompare((optionB?.Name ?? '').toLowerCase())
                }
                options={data}
                fieldNames={{ label: "Name", value: props.idField !== undefined ? props.idField : "ID" }}
            >
            </Select>
        </>
    )
}